import React, { useEffect, useState, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useHistory } from 'react-router-dom';
import { getSingleAPI, postAPI, putAPI } from './Action';
import { BASE_IMG_URL2 } from '../../Utils/Service';
import { imageUpload } from '../../Utils/upload';
import RenderLoader from '../../PanelComponent/RenderLoader';
import AvatarProfile from '../Features/AvatarProfile'
import { slugify } from '../../Utils/Logic';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const CreateLeader = (props) => {
    const query = useQuery();
    const history = useHistory();
    const [editCase, setEditCase] = useState(false);
    const [name, setName] = useState('');
    const [designation, setDesignation] = useState('');
    const [message, setMessage] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [profile, setProfile] = useState('');
    const [loader, setLoader] = useState(false);
    const [isUpcoming, setIsUpcomg] = useState(false);
    const [order, setOrder] = useState(0);
    const [reset, setReset] = useState(false);
    const imageRef1 = useRef();
    const handleCreateWebinar = async (e) => {
        e.preventDefault();
        setLoader(true)
        if(name != '' && message != '')
        {
            const data = {
                "metaTitle": metaTitle,
                "metaDescription": metaDescription,
                "name": name,
                "photo": profile,
                "order": order,
                "isUpomming":isUpcoming,
                "designation": designation,
                "bio": message
            }
            if(editCase)
            {
               putAPI(query.get('id'), data).then(response=>{
                    if(response.success)
                    {
                        toast.success(response.message)
                        setEditCase(false)
                        setName('');
                        setDesignation('');
                        setMessage('');
                        setMetaDescription('');
                        setMetaTitle('');
                        setProfile('');
                        setReset(true);
                        setIsUpcomg(false)
                        setOrder(0)
                        if(imageRef1.current)
                        {
                            imageRef1.current.value = "";
                        }

                        history.push('/leader')
                        setLoader(false)
                    }else{
                        toast.error(response.message)
                        setLoader(false)
                    }
               });
            }else{
               postAPI(data).then(response=>{
                      if(response.success)
                      {
                        toast.success(response.message)
                        setName('');
                        setDesignation('');
                        setMessage('');
                        setMetaDescription('');
                        setMetaTitle('');
                        setIsUpcomg(false)
                        setProfile('');
                        setOrder(0)
                        setReset(true)
                        if(imageRef1.current)
                        {
                            imageRef1.current.value = "";
                        }
                        setLoader(false)
                      }else{
                        toast.error(response.message)
                        setLoader(false)
                      }
               });
            }
        }else{
            toast.error('Please fill all the required fields!');
            setLoader(false)
        }
    }

    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }

    useEffect(async () => {
        if (window.location.pathname === '/edit-leader' && query.get('id') != '') {
            setLoader(true)
            await getSingleAPI(query.get('id')).then(response => {
                if (response.success) {
                    const data = response?.data;
                    if(data) {
                        setEditCase(true);
                        setName(data?.name?data?.name:'');
                        setDesignation(data?.designation?data?.designation:'');
                        setMessage(data?.bio?data?.bio:'');
                        setMetaDescription(data?.metaDescription?data?.metaDescription:'');
                        setMetaTitle(data?.metaTitle?data?.metaTitle:'');
                        setIsUpcomg(data?.isUpomming?true:false);
                        setProfile(data?.photo?data?.photo:'');
                        setOrder(data?.order? data?.order : 0)
                        setLoader(false)
                    }else {
                        history.push('/404');
                    }
                } else {
                     history.push('/404');
                }
            });
        }
    }, [])
    return (
        <>
            <div className="nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-lg">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">{editCase ? 'Edit Leader' : 'Add Leader'}</h4>
                                                </div>
                                            </div>
                                            {
                                                loader?
                                                  <RenderLoader trans={true}/>
                                                :null
                                            }
                                            <div className="nk-block">
                                            <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Normal Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Name*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={name} onChange={(e) => setName(e.target.value)} name='name' placeholder='Name' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Designation*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={designation} onChange={(e) => setDesignation(e.target.value)} name='name' placeholder='Designation' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Order*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="number" value={order} onChange={(e) => setOrder(e.target.value)} name='name' placeholder='Designation' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">isUpcoming</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <select  value={isUpcoming} onChange={(e) => setIsUpcomg(e.target.value)} name='isUpcoming' required className="form-control form-control-lg" style={{ width: '100%' }}>
                                                                    <option value={true}>Yes</option>
                                                                    <option value={false}>No</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Bio*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" name='description' value={message} onChange={(e) => setMessage(e.target.value)} placeholder='Bio...' required className="form-control form-control-lg" style={{ width: '100%' }}>

                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Meta Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Meta Title*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" name='metaTitle' value={metaTitle} onChange={(e) => setMetaTitle(e.target.value)} placeholder='Meta Title' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Meta Description*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" name='metaDescription' value={metaDescription} onChange={(e) => setMetaDescription(e.target.value)} placeholder='Meta Description' required className="form-control form-control-lg" style={{ width: '100%' }}>

                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Upload Image</h6>
                                                    </div>
                                                        <div className="data-item" style={{display:'flex',flexDirection:'column'}}>
                                                            <div className="data-col" style={{width:'100%'}}>
                                                                <span className="data-label">Profile Pic*</span>
                                                            </div>
                                                            <div className="data-col" style={{width:'100%'}}>
                                                                <AvatarProfile previousImage={editCase || !editCase && profile ?BASE_IMG_URL2+profile:''} reset={reset} onProfileChange={async (e, type)=>{
                                                                    
                                                                   
                                                                    //let generateFile =  new File([e], slugify(name?name:'avatar')+'.jpg', { type: type, lastModified: new Date().getTime()})
                                                                    let generateFile = dataURLtoFile(e, slugify(name?name:'avatar')+(type === 'image/png'?'.png':'.jpg'))
                                                                    var file = generateFile;
                                                                    var formData = new FormData();
                                                                    formData.append('path', 'leaders');
                                                                    formData.append('file', file, file.name);
                                                                    setLoader(true)
                                                                    await imageUpload(formData).then(response=>{
                                                                        if(response?.success)
                                                                        {
                                                                            setProfile(response?.aws?.key)
                                                                            setLoader(false)
                                                                            //toast.success(response.message)
                                                                        }else{
                                                                            toast.error(response.message)
                                                                            setLoader(false)
                                                                        }
                                                                    })
                                                                }} />
                                                                {/* {
                                                                    editCase?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                    <img src={BASE_IMG_URL2+profile} className='img-fluid' style={{margin:'15x',padding:'15px'}}/>
                                                                    <input type="file" ref={imageRef1} name='bannerVideo' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                    onChange={async (event)=>{
                                                                        var file = event.target.files[0];
                                                                        var formData = new FormData();
                                                                        formData.append('path', 'leaders');
                                                                        formData.append('file', file, file.name);
                                                                        setLoader(true)
                                                                        await imageUpload(formData).then(response=>{
                                                                            if(response?.success)
                                                                            {
                                                                                setProfile(response?.aws?.key)
                                                                                setLoader(false)
                                                                                //toast.success(response.message)
                                                                            }else{
                                                                                toast.error(response.message)
                                                                                setLoader(false)
                                                                            }
                                                                        })
                                                                }} />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                    <input type="file" ref={imageRef1} name='bannerVideo' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                    onChange={async (event)=>{
                                                                        var file = event.target.files[0];
                                                                        var formData = new FormData();
                                                                        formData.append('path', 'leaders');
                                                                        formData.append('file', file, file.name);
                                                                        setLoader(true)
                                                                        await imageUpload(formData).then(response=>{
                                                                            if(response?.success)
                                                                            {
                                                                                setProfile(response?.aws?.key)
                                                                                setLoader(false)
                                                                                //toast.success(response.message)
                                                                            }else{
                                                                                toast.error(response.message)
                                                                                setLoader(false)
                                                                            }
                                                                        })
                                                                }} />
                                                                    </div>
                                                                } */}
                                                            </div>
                                                        </div> 
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <div className="form-control-wrap" style={{ width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                                                                <button className="btn btn-lg btn-primary btn-block" onClick={(e) => handleCreateWebinar(e)}>{editCase ? 'Save' : 'Create'}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default CreateLeader;
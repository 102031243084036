import React, { useEffect, useState, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useHistory } from 'react-router-dom';
import { getSingleAPI, postAPI, putAPI } from './Action';
import { BASE_IMG_URL2 } from '../../Utils/Service';
import { imageUpload } from '../../Utils/upload';
import { slugify } from '../../Utils/Logic';
import RenderLoader from '../../PanelComponent/RenderLoader';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const InnovationDetails = (props) => {
    const query = useQuery();
    const history = useHistory();
    const [editCase, setEditCase] = useState(false);
    const [names, setName] = useState('');
    const [description, setDescription] = useState('');
    const [slug, setSlug] = useState('');
    const [isCerified, setCertified] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [bannerImage, setBannerImage] = useState('');
    const [bannerVideo, setBannerVideo] = useState('');
    const [depthCapability, setDepethCapality] = useState('');
    const [industry, setIndustry] = useState('');
    const [uniqueValueProposition, setUniqueValueProposition] = useState('');
    const [businessBenefits, setBusinessBenefits] = useState('');

    const [loader, setLoader] = useState(false);
    const [bannerDesktopImage, setBannerDesktopImage] = useState('');
    const [bannerMobileImage, setBannerMobileImage] = useState('');

    const imageRef1 = useRef();
    const imageRef2 = useRef();
    const imageRef3 = useRef();
    const imageRef4 = useRef();

    const handleCreateWebinar = async (e) => {
        e.preventDefault();
        setLoader(true)
        if (names != '' && description != '' && metaTitle != '' && metaDescription != '' && slug != '' && bannerImage != '' && bannerDesktopImage != '' && bannerMobileImage != '' && bannerVideo != '' && depthCapability != '' && uniqueValueProposition != '' && businessBenefits != '') {
            const data = {
                "banner": bannerImage,
                "metaTitle": metaTitle,
                "metaDecription": metaDescription,
                "slug": slug,
                "title": names,
                // "industry":industry,
                "shortDescription": description,
                "certified": isCerified ? isCerified : false,
                "features": depthCapability,
                "featuresImage": bannerVideo,
                "whatMakesInnovative": uniqueValueProposition,
                "benefits": businessBenefits,
                "pageBannerDesktop": bannerDesktopImage,
                "pageBannerMobile": bannerMobileImage
            }
            if (editCase) {
                putAPI(query.get('id'), data).then(response => {
                    if (response.success) {
                        toast.success(response.message)
                        setEditCase(false)
                        setName('');
                        setDescription('');
                        setMetaTitle('');
                        setMetaDescription('');
                        setDepethCapality('');
                        setBusinessBenefits('');
                        setSlug('');
                        setCertified('');
                        setIndustry('');
                        setBannerImage('');
                        setBannerVideo('');
                        setUniqueValueProposition('');
                        setBannerDesktopImage('');
                        setBannerMobileImage('');

                        if (imageRef1.current) {
                            imageRef1.current.value = "";
                        }
                        if (imageRef2.current) {
                            imageRef2.current.value = "";
                        }
                        if (imageRef3.current) {
                            imageRef3.current.value = "";
                        }
                        if (imageRef4.current) {
                            imageRef4.current.value = "";
                        }
                        history.push('/innovation')
                        setLoader(false)
                    } else {
                        toast.error(response.message)
                        setLoader(false)
                    }
                });
            } else {
                postAPI(data).then(response => {
                    if (response.success) {
                        toast.success(response.message)
                        setName('');
                        setDescription('');
                        setMetaTitle('');
                        setMetaDescription('');
                        setDepethCapality('');
                        setBusinessBenefits('');
                        setIndustry('')
                        setCertified('')
                        setUniqueValueProposition('')
                        setSlug('');
                        setBannerImage('');
                        setBannerVideo('');
                        setBannerDesktopImage('');
                        setBannerMobileImage('');

                        imageRef1.current.value = "";
                        imageRef2.current.value = "";
                        imageRef3.current.value = "";
                        imageRef4.current.value = "";
                        setLoader(false)
                    } else {
                        toast.error(response.message)
                        setLoader(false)
                    }
                });
            }
        } else {
            toast.error('Please fill all required fields!');
            setLoader(false)
        }
    }
    useEffect(async () => {
        if (window.location.pathname === '/edit-innovation' && query.get('id') != '') {
            setLoader(true)
            await getSingleAPI(query.get('id')).then(response => {
                if (response.success) {
                    const data = response?.data;
                    if (data) {
                        setEditCase(true);
                        setName(data?.title ? data?.title : '');
                        setDescription(data?.shortDescription ? data?.shortDescription : '');
                        setMetaTitle(data?.metaTitle ? data?.metaTitle : '');
                        setMetaDescription(data?.metaDecription ? data?.metaDecription : '');
                        setDepethCapality(data?.features ? data?.features : '');
                        setIndustry(data?.industry ? data?.industry : '')
                        //setDepthCapabilityImage(data?.inDepthCapabilityImage?data?.inDepthCapabilityImage:'');
                        setBusinessBenefits(data?.benefits ? data?.benefits : '');
                        setCertified(data?.certified ? data?.certified : false)
                        setSlug(data?.slug ? data?.slug : '');
                        setUniqueValueProposition(data?.whatMakesInnovative ? data?.whatMakesInnovative : '')
                        setBannerImage(data?.banner ? data?.banner : '');
                        setBannerVideo(data?.featuresImage ? data?.featuresImage : '');
                        setBannerDesktopImage(data?.pageBannerDesktop ? data?.pageBannerDesktop : '');
                        setBannerMobileImage(data?.pageBannerMobile ? data?.pageBannerMobile : '')
                        setLoader(false)
                    } else {
                        history.push('/404');
                    }
                } else {
                    history.push('/404');
                }
            });
        }
    }, [])
    return (
        <>
            <div className="nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-lg">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">{editCase ? 'Edit Innovation Pages Details' : 'Create Innovation Pages'}</h4>
                                                </div>
                                            </div>
                                            {
                                                loader ?
                                                    <RenderLoader trans={true} />
                                                    : null
                                            }
                                            <div className="nk-block">
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Normal Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Title*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={names} onChange={(e) => { setName(e.target.value); setSlug(slugify(e.target.value)) }} name='name' placeholder='Name' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Short Description*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" name='description' value={description} onChange={(e) => setDescription(e.target.value)} placeholder='Description' required className="form-control form-control-lg" style={{ width: '100%' }}>

                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Slug*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={slug} onChange={(e) => setSlug(e.target.value)} name='slug' placeholder='Slug' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Industry*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={industry} onChange={(e) => setIndustry(e.target.value)} name='slug' placeholder='Add Industry with comma "," ' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Certified*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <select value={isCerified} onChange={(e) => setCertified(e.target.value)} className="form-control form-control-lg">
                                                                    <option value=''>Select</option>
                                                                    <option value='true'>Yes</option>
                                                                    <option value='false'>No</option>
                                                                </select>
                                                                {/* <input type="text" value={slug} onChange={(e) => setSlug(e.target.value)} name='slug' placeholder='Slug' required className="form-control form-control-lg" style={{ width: '100%' }} /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Meta Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Meta Title*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" name='metaTitle' value={metaTitle} onChange={(e) => setMetaTitle(e.target.value)} placeholder='Meta Title' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Meta Description*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" name='metaDescription' value={metaDescription} onChange={(e) => setMetaDescription(e.target.value)} placeholder='Meta Description' required className="form-control form-control-lg" style={{ width: '100%' }}>

                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Upload Image/Videos</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Banner Image*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <img src={BASE_IMG_URL2 + bannerImage} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                        <input type="file" ref={imageRef1} name='bannerImage' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'innovation-app');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setLoader(false)
                                                                                        setBannerImage(response?.aws?.key)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        setLoader(false)
                                                                                        toast.error(response.message)
                                                                                    }
                                                                                })
                                                                            }} />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" ref={imageRef1} name='bannerImage' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'innovation-app');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setBannerImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }} />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Banner Desktop Image*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <img src={BASE_IMG_URL2 + bannerDesktopImage} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                        <input type="file" ref={imageRef2} name='bannerImage' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'innovation-app');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setBannerDesktopImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }} />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" ref={imageRef2} name='bannerImage' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'innovation-app');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setBannerDesktopImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }} />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Banner Mobile Image*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <img src={BASE_IMG_URL2 + bannerMobileImage} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                        <input type="file" ref={imageRef3} name='bannerDesktopImage' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'innovation-app');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setBannerMobileImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }} />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" ref={imageRef3} name='bannerMobileImage' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'innovation-app');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setBannerMobileImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }} />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Features Images*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <img src={BASE_IMG_URL2 + bannerVideo} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                        <input type="file" ref={imageRef4} name='bannerVideo' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'innovation-app');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setBannerVideo(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }} />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" ref={imageRef4} name='bannerVideo' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'innovation-app');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setBannerVideo(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }} />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">More Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Features</span>
                                                            <div className="form-control-wrap" style={{ width: '450px', height: 'auto' }}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={depthCapability}
                                                                    config={{
                                                                        toolbar: ['bold', 'italic', 'blockQuote', 'link', 'bulletedList']
                                                                    }}
                                                                    onReady={editor => {
                                                                        // console.log('editor ready!')
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        setDepethCapality(editor.getData())
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">What makes it truly innovative?</span>
                                                            <div className="form-control-wrap" style={{ width: '450px', height: 'auto' }}>
                                                                <textarea placeholder='Write...' required className="form-control form-control-lg" style={{ width: '100%' }} value={uniqueValueProposition} onChange={(e) => { setUniqueValueProposition(e.target.value) }}>

                                                                </textarea>
                                                                {/* <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={uniqueValueProposition}
                                                                    config={{
                                                                        toolbar: ['bold', 'italic', 'blockQuote', 'link', 'bulletedList']
                                                                    }}
                                                                    onReady={editor => {
                                                                        // console.log('editor ready!')
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        setUniqueValueProposition(editor.getData())
                                                                    }}
                                                                /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Benefits</span>
                                                            <div className="form-control-wrap" style={{ width: '450px', height: 'auto' }}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={businessBenefits}
                                                                    config={{
                                                                        toolbar: ['bold', 'italic', 'blockQuote', 'link', 'bulletedList']
                                                                    }}
                                                                    onReady={editor => {
                                                                        // console.log('editor ready!')
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        setBusinessBenefits(editor.getData())
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <div className="form-control-wrap" style={{ width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                                                                <button className="btn btn-lg btn-primary btn-block" onClick={(e) => handleCreateWebinar(e)}>{editCase ? 'Save' : 'Create'}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default InnovationDetails;
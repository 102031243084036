


export const categorizeEvents = (endDate, endTime) => {
    // Get today's date and time
    const today = new Date();
    const todayDate = today.toISOString().split('T')[0];
    const todayTime = today.toTimeString().split(' ')[0];
    // Combine today's date and provided end date to create date objects
    const todayDateTime = new Date(todayDate + 'T' + todayTime);
    const endDateTime = new Date(endDate + 'T' + endTime);
    // Compare dates to categorize events
    if (endDateTime < todayDateTime) {

        //completed
        return true;
    } else {

        //upcoming
        return false;
    }

}
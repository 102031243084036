import React, { useEffect, useState, useRef } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useHistory } from 'react-router-dom';
import { postWhitePaperAPI, putWhitePaperAPI, getSingleWhitePaperAPI } from './Action';
import RenderLoader from '../../PanelComponent/RenderLoader';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const CreateServiceTabs = (props) => {
    const query = useQuery();
    const history = useHistory();
    const [editCase, setEditCase] = useState(false);
    const [title, setTitle] = useState('');
    const [shortDescription, setshortDescription] = useState('');
    const [slug, setSlug] = useState('');
    const [loader, setLoader] = useState(false)
    const handleCreateWhitePaper = async (e) => {
        e.preventDefault();
        setLoader(true)
        if (title != '' && shortDescription != '' && slug != '') {
            const data = {
                "name": title,
                "tabsId": slug,
                "description": shortDescription
            }
            if (editCase) {
                await putWhitePaperAPI(query.get('subId'), data).then(response => {
                    if (response.success) {
                        toast.success(response.message);
                        setEditCase(false)
                        setTitle('');
                        //setSlug('')
                        setshortDescription('');
                        history.push('/service-tabs?id='+query.get('id'));
                        setLoader(false)
                    } else {
                        toast.error(response.message)
                        setLoader(false)
                    }
                });
            } else {
                await postWhitePaperAPI(data).then(response => {
                    if (response.success) {
                        toast.success(response.message);
                        setTitle('');
                       // setSlug('')
                        setshortDescription('');
                        setLoader(false)
                    } else {
                        toast.error(response.message)
                        setLoader(false)
                    }
                });
            }
        } else {
            toast.error('Please fill all required fields!')
            setLoader(false)
        }
    }
    useEffect(async () => {
        if (window.location.pathname === '/edit-service-tabs' && query.get('id') != '' && query.get('subId') != '') {
            setLoader(true)
            await getSingleWhitePaperAPI(query.get('subId')).then(response => {
                if (response.success) {
                    const data = response.data;
                    if (data) {
                        setTitle(data?.name ? data?.name : '');
                        setshortDescription(data?.description ? data?.description : '');
                        setSlug(data?.tabsId?data?.tabsId:'')
                        setEditCase(true);
                        setLoader(false)
                    } else {
                        history.push('/404')
                    }
                } else {
                    history.push('/404')
                }
            });
        }else{
            setSlug(query.get('id'))
        }
    }, [])
    return (
        <>
            <div className="nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-lg">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">{editCase ? 'Edit Service Tab' : 'Create Service Tab'}</h4>
                                                </div>
                                            </div>
                                            {
                                                loader ?
                                                    <RenderLoader trans={true} />
                                                    : null
                                            }
                                            <div className="nk-block">
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Normal Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Tab Name*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} name='mainTitle' placeholder='Title' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Description*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" name='shortDescription' value={shortDescription} onChange={(e) => setshortDescription(e.target.value)} placeholder='Short Description' required className="form-control form-control-lg" style={{ width: '100%' }}>

                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <div className="form-control-wrap" style={{ width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                                                                <button className="btn btn-lg btn-primary btn-block" onClick={(e) => handleCreateWhitePaper(e)}>{editCase ? 'Save' : 'Create'}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default CreateServiceTabs;
import React, { useEffect, useState, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useHistory } from 'react-router-dom';
import { getSingleWebinarAPI, postWebinarAPI, putWebinarAPI } from './Action';
import { convertDateFomrat, slugify } from '../../Utils/Logic';
import { BASE_IMG_URL2 } from '../../Utils/Service';
import { imageUpload, webinarVideoUpload } from '../../Utils/upload';
import RenderLoader from '../../PanelComponent/RenderLoader';
import ServicesSlugList from '../../ExternalComponent/ServicesSlugList';
import { TiDelete } from 'react-icons/ti';
import { MdOutlineAdd } from 'react-icons/md';
import { categorizeEvents } from '../../Utils/filter';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const CreateWebinar = (props) => {
    const query = useQuery();
    const history = useHistory();
    const [editCase, setEditCase] = useState(false);

    const [mainTitle, setMainTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [slug, setSlug] = useState('');
    const [date, setDate] = useState('');
    // console.log("date", date)
    const [day, setDay] = useState('');
    const [fromTime, setfromTime] = useState('');
    const [toTime, settoTime] = useState('');
    // console.log("totime", toTime)
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [bannerImage, setBannerImage] = useState('');
    const [featureImage, setFeatureImage] = useState('');
    const [registerImage, setRegisterImage] = useState('');
    const [speakerProfile, setSpeakerProfile] = useState('');
    const [webinarThumbnail, setWebinarThumbnail] = useState('');
    const [webinarVideo, setWebinarVideo] = useState('');
    const [about, setAbout] = useState('')
    const [heighlight, setHeighlight] = useState('');
    const [speaker, setSpeaker] = useState('');
    const [speakerBio, setSpeakerBio] = useState('');
    const [speakerDesignation, setSpeakerDesignation] = useState('');
    const [loader, setLoader] = useState(false)
    const [editID, setEditID] = useState('');
    const [service, setService] = useState("");
    const imageRef1 = useRef();
    const imageRef2 = useRef();
    const imageRef3 = useRef();
    const imageRef4 = useRef();
    const imageRef5 = useRef();
    const webinarVideoRef = useRef();

    // attendee testimonial 
    const initArrayData = ({
        message: "",

    })
    const [attendeeContents, setAttendeeContents] = useState([])
    // console.log("attendeeContents", attendeeContents)

    const handleCreateWebinar = async (e) => {
        e.preventDefault();
        setLoader(true)
        if (mainTitle != '' && subTitle != '' && slug != '' && date != '' && day != '' && fromTime != '' && toTime != '' && metaTitle != '' && metaDescription != '' && bannerImage != '' && featureImage != '' && registerImage != '' && speakerProfile != '' && about != '' && heighlight != '' && speakerBio != '' && speakerDesignation != '') {
            let data = {
                "mainTitle": mainTitle,
                "subTitle": subTitle,
                "slug": slug,
                "metaTitle": metaTitle,
                "metaDescription": metaDescription,
                "date": date,
                "day": day,
                "fromTime": fromTime,
                "toTime": toTime,
                "about": about,
                "highlights": heighlight,
                "speaker": speaker,
                "speakerpic": speakerProfile,
                "speakerBio": speakerBio,
                "speakerDesignation": speakerDesignation,
                "banner": bannerImage,
                "featureImage": featureImage,
                "registerImage": registerImage,
                "service": service,
                "attendeeTestimonial": attendeeContents?.length ? attendeeContents : "",

            }

            if (webinarVideo) {
                data = Object.assign(data, { "webinarVideo": webinarVideo });
            }


            if (webinarThumbnail) {
                data = Object.assign(data, { "webinarThumbnail": webinarThumbnail });
            }

            if (editCase) {
                await putWebinarAPI(editID, data).then(response => {
                    if (response.success) {
                        setMainTitle('');
                        setSubTitle('');
                        setSlug('');
                        setDate('');
                        setDay('');
                        setfromTime('');
                        settoTime('');
                        setMetaTitle('');
                        setMetaDescription('');
                        setBannerImage('');
                        setFeatureImage('');
                        setRegisterImage('');
                        setAbout('');
                        setService("")
                        setHeighlight('');
                        setSpeaker('');
                        setSpeakerBio('');
                        setSpeakerDesignation('');
                        setSpeakerProfile('');
                        setWebinarThumbnail("")
                        setWebinarVideo("")
                        toast.success(response.message);
                        if (imageRef1.current) {
                            imageRef1.current.value = "";
                        }
                        if (imageRef2.current) {
                            imageRef2.current.value = "";
                        }
                        if (imageRef3.current) {
                            imageRef3.current.value = "";
                        }
                        if (imageRef4.current) {
                            imageRef4.current.value = "";
                        }
                        if (imageRef5.current) {
                            imageRef5.current.value = "";
                        }
                        if (webinarVideoRef.current) {
                            webinarVideoRef.current.value = "";
                        }
                        history.push('/webinar');
                        setLoader(false)
                    } else {
                        toast.error(response.message)
                        setLoader(false)
                    }
                });
            } else {
                await postWebinarAPI(data).then(response => {
                    if (response.success) {
                        setMainTitle('');
                        setSubTitle('');
                        setSlug('');
                        setDate('');
                        setDay('');
                        setfromTime('');
                        settoTime('');
                        setMetaTitle('');
                        setMetaDescription('');
                        setBannerImage('');
                        setFeatureImage('');
                        setRegisterImage('');
                        setAbout('');
                        setService("")
                        setHeighlight('');
                        setSpeaker('');
                        setSpeakerBio('');
                        setSpeakerDesignation('');
                        setSpeakerProfile('');
                        setWebinarThumbnail('')
                        setWebinarVideo('')
                        if (imageRef1.current) {
                            imageRef1.current.value = "";
                        }
                        if (imageRef2.current) {
                            imageRef2.current.value = "";
                        }
                        if (imageRef3.current) {
                            imageRef3.current.value = "";
                        }
                        if (imageRef4.current) {
                            imageRef4.current.value = "";
                        }
                        if (imageRef5.current) {
                            imageRef5.current.value = "";
                        }
                        if (webinarVideoRef.current) {
                            webinarVideoRef.current.value = "";
                        }
                        toast.success(response.message);
                        history.push('/webinar');
                        setLoader(false)
                    } else {
                        toast.error(response.message)
                        setLoader(false)
                    }
                });
            }
        } else {
            toast.error('Please fill all required fields!')
            setLoader(false)
        }
    }
    useEffect(async () => {
        if (window.location.pathname === '/edit-webinar' && query.get('id') != '') {
            setLoader(true)
            setAttendeeContents([]);
            await getSingleWebinarAPI(query.get('id')).then(response => {
                if (response.success) {
                    const data = response?.data;
                    if (data) {
                        setEditCase(true)
                        setEditID(data?._id ? data?._id : '')
                        setMainTitle(data?.mainTitle ? data?.mainTitle : '');
                        setSubTitle(data?.subTitle ? data?.subTitle : '');
                        setSlug(data?.slug ? data?.slug : '');
                        setDate(data?.date ? data?.date : '');
                        setDay(data?.day ? data?.day : '');
                        setfromTime(data?.fromTime ? data?.fromTime : '');
                        settoTime(data?.toTime ? data?.toTime : '');
                        setMetaTitle(data?.metaTitle ? data?.metaTitle : '');
                        setMetaDescription(data?.metaDescription ? data?.metaDescription : '');
                        setBannerImage(data?.banner ? data?.banner : '');
                        setFeatureImage(data?.featureImage ? data?.featureImage : '');
                        setRegisterImage(data?.registerImage ? data?.registerImage : '');
                        setAbout(data?.about ? data?.about : '');
                        setHeighlight(data?.highlights ? data?.highlights : '');
                        setSpeaker(data?.speaker ? data?.speaker : '')
                        setSpeakerBio(data?.speakerBio ? data?.speakerBio : '');
                        setSpeakerDesignation(data?.speakerDesignation ? data?.speakerDesignation : '');
                        setSpeakerProfile(data?.speakerpic ? data?.speakerpic : '');
                        setWebinarThumbnail(data?.webinarThumbnail ? data?.webinarThumbnail : '');
                        setWebinarVideo(data?.webinarVideo ? data?.webinarVideo : '');
                        setService(data?.service ? data?.service : "")
                        setAttendeeContents(data?.attendeeTestimonial ? data?.attendeeTestimonial : [])
                        setLoader(false)
                    } else {
                        history.push('/404');
                    }
                } else {
                    history.push('/404');
                }
            });
        }
    }, [])


    return (
        <>
            <div className="nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-lg">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">{editCase ? 'Edit Webinar Details' : 'Create Webinar'}</h4>
                                                </div>
                                            </div>
                                            {
                                                loader ?
                                                    <RenderLoader trans={true} />
                                                    : null
                                            }
                                            <div className="nk-block">
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Normal Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Main Title*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={mainTitle} onChange={(e) => { setMainTitle(e.target.value); setSlug(slugify(e.target.value)) }} name='mainTitle' placeholder='Title' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Sub Title*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={subTitle} onChange={(e) => setSubTitle(e.target.value)} name='subTitle' placeholder='Sub Title' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Slug*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" name='slug' value={slug} onChange={(e) => setSlug(e.target.value)} placeholder='Slug' className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Date*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="date" name='date' value={date ? convertDateFomrat(new Date(date)) : ''} onChange={(e) => {
                                                                    setDate(new Date(e.target.value).toISOString())
                                                                }} placeholder='Date' className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <ServicesSlugList label="Select Service" value={service} onChange={(e) => setService(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Day*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <select name='day' value={day} onChange={(e) => setDay(e.target.value)} className="form-control form-control-lg" style={{ width: '100%' }}>
                                                                    <option value=''>Select</option>
                                                                    <option value='Sunday'>Sunday</option>
                                                                    <option value='Monday'>Monday</option>
                                                                    <option value='Tuesday'>Tuesday</option>
                                                                    <option value='Wednesday'>Wednesday</option>
                                                                    <option value='Thursday'>Thursday</option>
                                                                    <option value='Friday'>Friday</option>
                                                                    <option value='Saturday'>Saturday</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">From Time*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="time" name='fromTime' value={fromTime} onChange={(e) => setfromTime(e.target.value)} placeholder='From Time' className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">To Time*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="time" name='toTime' value={toTime} onChange={(e) => settoTime(e.target.value)} placeholder='To Time' className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Meta Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Meta Title*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" name='metaTitle' value={metaTitle} onChange={(e) => setMetaTitle(e.target.value)} placeholder='Meta Title' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Meta Description*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" name='metaDescription' value={metaDescription} onChange={(e) => setMetaDescription(e.target.value)} placeholder='Meta Description' required className="form-control form-control-lg" style={{ width: '100%' }}>

                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Upload Image/Video</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Banner Image*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <img src={BASE_IMG_URL2 + bannerImage} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                        <input type="file" name='bannerImage' ref={imageRef1} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'webinars');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setBannerImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" name='bannerImage' ref={imageRef1} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'webinars');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setBannerImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Feature Image*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <img src={BASE_IMG_URL2 + featureImage} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                        <input type="file" name='featureImage' ref={imageRef2} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'webinars');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setFeatureImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" name='featureImage' ref={imageRef2} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'webinars');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setFeatureImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Register Image*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <img src={BASE_IMG_URL2 + registerImage} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                        <input type="file" name='registerImage' ref={imageRef3} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'webinars');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setRegisterImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" name='registerImage' ref={imageRef3} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'webinars');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setRegisterImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Speaker Profile*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <img src={BASE_IMG_URL2 + speakerProfile} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                        <input type="file" name='speakerProfile' ref={imageRef4} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'webinars');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setSpeakerProfile(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" name='speakerProfile' ref={imageRef4} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'webinars');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setSpeakerProfile(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    {/* webinar thumbnail */}

                                                    {
                                                        date && toTime && categorizeEvents(date, toTime) && (
                                                            <div className="data-item">
                                                                <div className="data-col">
                                                                    <span className="data-label">Webinar Video Thumbnail</span>
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        {

                                                                            editCase && webinarThumbnail && (

                                                                                <img src={BASE_IMG_URL2 + webinarThumbnail} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />)
                                                                        }
                                                                        <input type="file" name='webinarThumbnail' ref={imageRef5} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'webinars');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setWebinarThumbnail(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>


                                                                </div>
                                                            </div>

                                                        )
                                                    }
                                                    {/* webinar video */}
                                                    {
                                                        date && toTime && categorizeEvents(date, toTime) && (
                                                            <div className="data-item">
                                                                <div className="data-col">
                                                                    <span className="data-label">Webinar Video </span>
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        {

                                                                            editCase && (
                                                                                !webinarVideo ? (
                                                                                    null

                                                                                )
                                                                                    : (


                                                                                        <video key={webinarVideo} controls className='img-fluid'>
                                                                                            <source src={BASE_IMG_URL2 + webinarVideo} type="video/mp4" />Sorry, your browser doesn't support embedded videos.
                                                                                        </video>




                                                                                    )

                                                                            )
                                                                        }
                                                                        <input type="file" name='webinarVideo' ref={webinarVideoRef} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'webinars');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await webinarVideoUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setWebinarVideo(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>


                                                                </div>
                                                            </div>

                                                        )
                                                    }


                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">More Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">About*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                {/* <textarea type="text" value={about} onChange={(e) => setAbout(e.target.value)} name='about' placeholder='About..' className="form-control form-control-lg" style={{ width: '100%' }}>

                                                                </textarea> */}
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={about}
                                                                    config={{
                                                                        toolbar: ['bold', 'italic', 'blockQuote', 'link']
                                                                    }}
                                                                    onReady={editor => {
                                                                        // console.log('editor ready!')
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        setAbout(editor.getData())
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Highlight</span>
                                                            <div className="form-control-wrap" style={{ width: '450px', height: 'auto' }}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={heighlight}
                                                                    config={{
                                                                        toolbar: ['bold', 'italic', 'blockQuote', 'link', 'bulletedList']
                                                                    }}
                                                                    onReady={editor => {
                                                                        // console.log('editor ready!')
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        setHeighlight(editor.getData())
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Speaker*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" name='speaker' value={speaker} onChange={(e) => setSpeaker(e.target.value)} placeholder='Speaker' className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Speaker Designation*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" name='speakerDesignation' value={speakerDesignation} onChange={(e) => setSpeakerDesignation(e.target.value)} placeholder='Speaker Designation' className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Speaker Bio*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" value={speakerBio} onChange={(e) => setSpeakerBio(e.target.value)} name='speakerBio' placeholder='Speaker Bio...' className="form-control form-control-lg" style={{ width: '100%' }}>

                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Attendee testimonial */}
                                                {
                                                    date && toTime && categorizeEvents(date, toTime) && (
                                                        <div className="nk-data data-list">
                                                            <div className="data-head">
                                                                <h6 className="overline-title">Webinar Highlights CONTENT</h6>
                                                            </div>

                                                            {
                                                                attendeeContents?.length ?
                                                                    attendeeContents?.map((n, index) => {
                                                                        return (
                                                                            <div className="nk-data data-list" style={{ padding: '0px 0px', border: 'solid #f6f6f6 1px', background: '#f6f6f6' }} key={index}>
                                                                                <div className="data-item" style={{ padding: '5px 15px', background: '#f9f9f9', border: 'solid #f9f9f9 1px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                                    <span className="data-label" style={{ fontSize: '16px', width: 'auto', fontWeight: '550', color: '#6576ff' }}>{index + 1}: Pointer</span>
                                                                                    <span>
                                                                                        <button onClick={() => {
                                                                                            setAttendeeContents(attendeeContents?.filter((n, ind) => ind != index))
                                                                                        }} className='btn btn-lg btn-primary' style={{ width: '35px', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0px' }}>
                                                                                            <TiDelete style={{ color: '#fff', width: '35px', height: '35px' }} />
                                                                                        </button>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="data-item">
                                                                                    <div className="data-col">
                                                                                        <span className="data-label">Webinar Highlight</span>
                                                                                        <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                                            <input type="text" name='message' value={n?.message ? n?.message : ""} onChange={(e) =>
                                                                                                setAttendeeContents(attendeeContents?.map((n, ind) => ind === index ? { ...n, message: (e.target.value) } : n))
                                                                                            } placeholder='Webinar Highlight' className="form-control form-control-lg" style={{ width: '100%' }} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        )
                                                                    }
                                                                    ) : null

                                                            }
                                                            <div className="data-head" style={{ padding: '15px', background: '#ececec', border: 'solid #ececec 1px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px 0px' }}>
                                                                <span>
                                                                    <button onClick={() => {

                                                                        if (attendeeContents?.length < 3) {

                                                                            setAttendeeContents([...attendeeContents, initArrayData]);
                                                                        } else {
                                                                            toast.error('You can add only three pointer')
                                                                        }
                                                                    }} className='btn btn-lg btn-primary' style={{ width: 'auto', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0px 15px' }}>
                                                                        <MdOutlineAdd style={{ color: '#fff', width: '35px', height: '35px' }} /> Create New Pointer
                                                                    </button>
                                                                </span>
                                                            </div>

                                                        </div>

                                                    )
                                                }
                                                <div className="nk-data data-list">
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <div className="form-control-wrap" style={{ width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                                                                <button className="btn btn-lg btn-primary btn-block" onClick={(e) => handleCreateWebinar(e)}>{editCase ? 'Save' : 'Create'}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default CreateWebinar;
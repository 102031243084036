import React, { useEffect, useState, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useHistory } from 'react-router-dom';
import { getSingleAPI, postAPI, putAPI } from './Action';
import { imageUpload, videoUpload } from '../../Utils/upload';
import { BASE_IMG_URL2 } from '../../Utils/Service';
import { slugify } from '../../Utils/Logic';
import RenderLoader from '../../PanelComponent/RenderLoader';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const CreateWebinar = (props) => {
    const query = useQuery();
    const history = useHistory();
    const [editCase, setEditCase] = useState(false);
    const [names, setName] = useState('');
    const [description, setDescription] = useState('');
    const [slug, setSlug] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [inDepthCapabilityImage, setDepthCapabilityImage] = useState('');
    const [bannerImage, setBannerImage] = useState('');
    const [bannerVideo, setBannerVideo] = useState('');
    const [mobbannerImage, setMobBannerImage] = useState('');
    const [mobbannerVideo, setMobBannerVideo] = useState('');
    const [depthCapability, setDepethCapality] = useState('');
    const [uniqueValueProposition, setUniqueValueProposition] = useState('');
    const [businessBenefits, setBusinessBenefits] = useState('');
    const [internal, setInternal] = useState('/insights/');
    const [smallIcons, setSmallIcons] = useState('');
    const [loader, setLoader] = useState(false)

    const imageRef1 = useRef();
    const imageRef2 = useRef();
    const imageRef3 = useRef();
    const imageRef4 = useRef();
    const imageRef5 = useRef();
    const imageRef6 = useRef();
    const handleCreateWebinar = async (e) => {
        e.preventDefault();
        setLoader(true);
        if (names != '' && description != '' && slug != '' && metaTitle != '' && metaDescription != '' && inDepthCapabilityImage != '' && depthCapability != '' && uniqueValueProposition != '' && businessBenefits != '') {
            var data = {
                name: names,
                description: description,
                metaTitle: metaTitle,
                metaDecription: metaDescription,
                inDepthCapability: depthCapability,
                inDepthCapabilityImage: inDepthCapabilityImage,
                uniqueValueProposition: uniqueValueProposition,
                businessBenefits: businessBenefits,
                slug: slug,
                internal:internal,
                smallIcons:smallIcons
            }
            if (bannerVideo) {
                data = Object.assign(data, {
                    bannerVideo: bannerVideo
                })
            }
            if (bannerImage) {
                data = Object.assign(data, {
                    bannerImage: bannerImage
                })
            }
            if (mobbannerImage) {
                data = Object.assign(data, {
                    mobbannerImage: mobbannerImage
                })
            }
            if (mobbannerVideo) {
                data = Object.assign(data, {
                    mobbannerVideo: mobbannerVideo
                })
            }

            if (editCase) {
                putAPI(query.get('id'), data).then(response => {
                    if (response.success) {
                        toast.success(response.message)
                        setEditCase(false)
                        setName('');
                        setDescription('');
                        setMetaTitle('');
                        setMetaDescription('');
                        setDepethCapality('');
                        setDepthCapabilityImage('');
                        setBusinessBenefits('');
                        setSlug('');
                        setBannerImage('');
                        setBannerVideo('');
                        setMobBannerImage('');
                        setMobBannerVideo('');
                        setUniqueValueProposition('')
                        setInternal('/insights/')

                        if(imageRef1.current)
                        {
                            imageRef1.current.value = "";
                        }
                        if(imageRef2.current)
                        {
                            imageRef2.current.value = "";
                        }
                        if(imageRef3.current)
                        {
                            imageRef3.current.value = "";
                        }
                        if(imageRef4.current)
                        {
                            imageRef4.current.value = "";
                        }
                        if(imageRef5.current)
                        {
                            imageRef5.current.value = "";
                        }
                        if(imageRef6.current)
                        {
                            imageRef6.current.value = "";
                        }


                        history.push('/services')
                        setLoader(false)
                    } else {
                        setLoader(false)
                        toast.error(response.message)
                    }
                });
            } else {
                postAPI(data).then(response => {
                    if (response.success) {
                        toast.success(response.message)
                        setName('');
                        setDescription('');
                        setMetaTitle('');
                        setMetaDescription('');
                        setDepethCapality('');
                        setDepthCapabilityImage('');
                        setBusinessBenefits('');
                        setSlug('');
                        setUniqueValueProposition('')
                        setBannerImage('');
                        setBannerVideo('');
                        setMobBannerImage('');
                        setMobBannerVideo('');
                        setInternal('/insights/')
                        imageRef1.current.value = "";
                        imageRef2.current.value = "";
                        imageRef3.current.value = "";
                        imageRef4.current.value = "";
                        imageRef5.current.value = "";
                        if(imageRef6.current)
                        {
                            imageRef6.current.value = "";
                        }
                        setLoader(false)
                    } else {
                        setLoader(false)
                        toast.error(response.message)
                    }
                });
            }
        } else {
            toast.error('Please fill all required fields!');
            setLoader(false)
        }
    }
    useEffect(async () => {
        if (window.location.pathname === '/edit-services' && query.get('id') != '') {
            setLoader(true)
            await getSingleAPI(query.get('id')).then(response => {
                if (response.success) {
                    const data = response?.data;
                    if (data) {
                        setEditCase(true);
                        setName(data?.name ? data?.name : '');
                        setDescription(data?.description ? data?.description : '');
                        setMetaTitle(data?.metaTitle ? data?.metaTitle : '');
                        setMetaDescription(data ? data?.metaDecription : '');
                        setDepethCapality(data?.inDepthCapability ? data?.inDepthCapability : '');
                        setDepthCapabilityImage(data?.inDepthCapabilityImage ? data?.inDepthCapabilityImage : '');
                        setBusinessBenefits(data?.businessBenefits ? data?.businessBenefits : '');
                        setSlug(data?.slug ? data?.slug : '');
                        setUniqueValueProposition(data?.uniqueValueProposition ? data?.uniqueValueProposition : '')
                        setBannerImage(data?.bannerImage ? data?.bannerImage : '');
                        setBannerVideo(data?.bannerVideo ? data?.bannerVideo : '');
                        setMobBannerImage(data?.mobbannerImage ? data?.mobbannerImage : '');
                        setMobBannerVideo(data?.mobbannerVideo ? data?.mobbannerVideo : '');
                        setInternal(data?.internal?data?.internal:'/insights/');
                        setSmallIcons(data?.smallIcons?data?.smallIcons:'')
                        setLoader(false)
                    } else {
                        history.push('/404');
                    }
                } else {
                    history.push('/404');
                }
            });
        }
    }, [])
    return (
        <>
            <div className="nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-lg">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">{editCase ? 'Edit Services Pages Details' : 'Create Services Pages'}</h4>
                                                </div>
                                            </div>
                                            {
                                                loader ?
                                                    <RenderLoader trans={true} />
                                                    : null
                                            }
                                            <div className="nk-block">
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Normal Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Name*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={names} onChange={(e) => { setName(e.target.value); setSlug(slugify(e.target.value)) }} name='name' placeholder='Name' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Description*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" name='description' value={description} onChange={(e) => setDescription(e.target.value)} placeholder='Description' required className="form-control form-control-lg" style={{ width: '100%' }}>

                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Slug*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={slug} onChange={(e) => {setSlug(e.target.value);setInternal("/insights/"+e.target.value)}} name='slug' placeholder='Slug' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Insights Link</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={internal} onChange={(e) => setInternal(e.target.value)} name='internal' placeholder='Internal Page Link' className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Meta Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Meta Title*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" name='metaTitle' value={metaTitle} onChange={(e) => setMetaTitle(e.target.value)} placeholder='Meta Title' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Meta Description*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" name='metaDescription' value={metaDescription} onChange={(e) => setMetaDescription(e.target.value)} placeholder='Meta Description' required className="form-control form-control-lg" style={{ width: '100%' }}>

                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Upload Image/Videos</h6>
                                                    </div>


                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Insight Icons</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        {
                                                                            smallIcons ?
                                                                                <img src={BASE_IMG_URL2 + smallIcons} className='img-fluid' style={{ margin: '15x', padding: '15px',background:'#000' }} />
                                                                                : null
                                                                        }
                                                                        <input type="file" ref={imageRef6} name='smallIcons' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'services-consultants/icons');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setSmallIcons(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" ref={imageRef6} name='smallIcons' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'services-consultants/icons');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setSmallIcons(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>


















                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Depth-Capability Image*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        {
                                                                            inDepthCapabilityImage ?
                                                                                <img src={BASE_IMG_URL2 + inDepthCapabilityImage} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                                : null
                                                                        }
                                                                        <input type="file" ref={imageRef1} name='inDepthCapabilityImage' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'services-consultants');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setDepthCapabilityImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" ref={imageRef1} name='inDepthCapabilityImage' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'services-consultants');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setDepthCapabilityImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Banner Image*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        {
                                                                            bannerImage ?
                                                                                <img src={BASE_IMG_URL2 + bannerImage} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                                : null
                                                                        }
                                                                        <input type="file" ref={imageRef2} name='bannerImage' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'services-consultants');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setBannerImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" ref={imageRef2} name='bannerImage' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'services-consultants');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setBannerImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Banner Video*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <video controls className='img-fluid'>
                                                                            <source src={BASE_IMG_URL2 + bannerVideo} type="video/mp4" />Sorry, your browser doesn't support embedded videos.
                                                                        </video>
                                                                        <input type="file" ref={imageRef3} name='bannerVideo' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'services-consultants');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await videoUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setBannerVideo(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" ref={imageRef3} name='bannerVideo' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'services-consultants');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await videoUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setBannerVideo(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Mobile Banner Image*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        {
                                                                            mobbannerImage != '' ?
                                                                                <img src={BASE_IMG_URL2 + mobbannerImage} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                                : null
                                                                        }
                                                                        <input type="file" ref={imageRef4} name='bannerImage' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'services-consultants');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setMobBannerImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" ref={imageRef4} name='bannerImage' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'services-consultants');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setMobBannerImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Mobile Banner Video*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <video controls className='img-fluid'>
                                                                            <source src={BASE_IMG_URL2 + mobbannerVideo} type="video/mp4" />Sorry, your browser doesn't support embedded videos.
                                                                        </video>
                                                                        <input type="file" ref={imageRef5} name='bannerVideo' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'services-consultants');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await videoUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setMobBannerVideo(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" ref={imageRef5} name='bannerVideo' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'services-consultants');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await videoUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setMobBannerVideo(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">More Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Depth Capability</span>
                                                            <div className="form-control-wrap" style={{ width: '450px', height: 'auto' }}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={depthCapability}
                                                                    config={{
                                                                        toolbar: ['bold', 'italic', 'blockQuote', 'link', 'bulletedList']
                                                                    }}
                                                                    onReady={editor => {
                                                                        // console.log('editor ready!')
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        setDepethCapality(editor.getData())
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Unique Value Proposition</span>
                                                            <div className="form-control-wrap" style={{ width: '450px', height: 'auto' }}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={uniqueValueProposition}
                                                                    config={{
                                                                        toolbar: ['bold', 'italic', 'blockQuote', 'link', 'bulletedList']
                                                                    }}
                                                                    onReady={editor => {
                                                                        // console.log('editor ready!')
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        setUniqueValueProposition(editor.getData())
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Business Benefits</span>
                                                            <div className="form-control-wrap" style={{ width: '450px', height: 'auto' }}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={businessBenefits}
                                                                    config={{
                                                                        toolbar: ['bold', 'italic', 'blockQuote', 'link', 'bulletedList']
                                                                    }}
                                                                    onReady={editor => {
                                                                        // console.log('editor ready!')
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        setBusinessBenefits(editor.getData())
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <div className="form-control-wrap" style={{ width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                                                                <button className="btn btn-lg btn-primary btn-block" onClick={(e) => handleCreateWebinar(e)}>{editCase ? 'Save' : 'Create'}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default CreateWebinar;
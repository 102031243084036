import React, { useEffect, useState, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useHistory } from 'react-router-dom';
import { getSingleAPI, postAPI, putAPI } from './Action';
import { imageUpload, videoUpload } from '../../Utils/upload';
import { BASE_IMG_URL2 } from '../../Utils/Service';
import { slugify } from '../../Utils/Logic';
import RenderLoader from '../../PanelComponent/RenderLoader';
import { TiDelete } from 'react-icons/ti';
import { MdOutlineAdd } from 'react-icons/md';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const CreateTabsSubCategory = (props) => {
    const query = useQuery();
    const history = useHistory();
    const [editCase, setEditCase] = useState(false);
    const [names, setName] = useState('');
    const [description, setDescription] = useState('');
    const [slug, setSlug] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [inDepthCapabilityImage, setDepthCapabilityImage] = useState('');
    const [bannerImage, setBannerImage] = useState('');
    const [bannerVideo, setBannerVideo] = useState('');
    const [outComeFocusedImage, setOutComeFocusedImage] = useState(' ');
    const [mobbannerVideo, setMobBannerVideo] = useState(' ');
    const [depthCapability, setDepethCapality] = useState('');
    const [uniqueValueProposition, setUniqueValueProposition] = useState('');
    const [loader, setLoader] = useState(false)
    const [tabsId, setTabsId] = useState('');
    const [keyCapabilitiesHeading, setKeyCapabilitiesHeading] = useState("");
    const [workflow, setWorkflow] = useState({
        workflowHeading: "",
        workflowImage: "",
        workflowPointer: ""
    })

    const initArrayData = ({
        pointerHeading: "",
        pointerParagraph: "",

    })

    const [bussinessBenefit, setbussinessBenefit] = useState({
        bussinessBenefitHeading: "",
        bussinessBenefitPointer: []
    })
    const [outComeFocusedHeading, setOutComeFocusedHeading] = useState("")
    const imageRef1 = useRef();
    const imageRef2 = useRef();
    const imageRef3 = useRef();
    const imageRef4 = useRef();
    const imageRef5 = useRef();
    const workflowImageRef = useRef();
    const showSectionSlug = ["it-service-management"];
    const showSection = showSectionSlug.includes(slug);
    const handleCreateWebinar = async (e) => {
        e.preventDefault();
        setLoader(true);
        if (names != '' && description != '' && slug != '' && metaTitle != '' && metaDescription != '' && inDepthCapabilityImage != '' && depthCapability != '' && uniqueValueProposition != '') {
            var data = {
                tabid: tabsId,
                name: names,
                description: description,
                slug: slug,
                metaTitle: metaTitle,
                metaDecription: metaDescription,
                backImage: bannerImage,
                backImageMobile: bannerVideo,
                keyCapabilitiesImage: inDepthCapabilityImage,
                outcomeFocusedDeploymentImage: outComeFocusedImage ? outComeFocusedImage : " ",
                outcomeFocusedDeploymentVideo: ' ',
                keyCapabilities: depthCapability,
                outcomeFocusedDeployment: uniqueValueProposition,

            }

            if (showSection) {
                data = Object.assign(data, { "keyCapabilitiesHeading": keyCapabilitiesHeading });
                data = Object.assign(data, { "workflowHeading": workflow?.workflowHeading });
                data = Object.assign(data, { "workflowImage": workflow?.workflowImage });
                data = Object.assign(data, { "workflowPointer": workflow?.workflowPointer });
                data = Object.assign(data, { "outcomeFocusedDeploymentHeading": outComeFocusedHeading });
                data = Object.assign(data, { "bussinessBenefitHeading": bussinessBenefit?.bussinessBenefitHeading });
                data = Object.assign(data, { "bussinessBenefitPointer": bussinessBenefit?.bussinessBenefitPointer });
            }

            if (editCase) {
                putAPI(query.get('id'), query.get('subId'), data).then(response => {
                    if (response.success) {
                        toast.success(response.message)
                        setEditCase(false)
                        setName('');
                        setDescription('');
                        setMetaTitle('');
                        setMetaDescription('');
                        setDepethCapality('');
                        setDepthCapabilityImage('');
                        setSlug('');
                        setBannerImage('');
                        setBannerVideo('');
                        setOutComeFocusedImage(' ');
                        setMobBannerVideo(' ');
                        setUniqueValueProposition('')
                        setKeyCapabilitiesHeading("")
                        setWorkflow({
                            ...workflow,
                            workflowHeading: "",
                            workflowImage: "",
                            workflowPointer: ""
                        })
                        setbussinessBenefit({
                            ...bussinessBenefit,
                            bussinessBenefitHeading: "",
                            bussinessBenefitHeading: []
                        })
                        setOutComeFocusedHeading("")
                        if (imageRef1.current) {
                            imageRef1.current.value = "";
                        }
                        if (imageRef2.current) {
                            imageRef2.current.value = "";
                        }
                        if (imageRef3.current) {
                            imageRef3.current.value = "";
                        }
                        if (imageRef4.current) {
                            imageRef4.current.value = "";
                        }
                        if (imageRef5.current) {
                            imageRef5.current.value = "";
                        }
                        if (workflowImageRef.current) {
                            workflowImageRef.current.value = "";
                        }


                        history.push('service-tabs-subcategory-list?id=' + query.get('id'))
                        setLoader(false)
                    } else {
                        setLoader(false)
                        toast.error(response.message)
                    }
                });
            } else {
                postAPI(data, data?.tabid).then(response => {
                    if (response.success) {
                        toast.success(response.message)
                        setName('');
                        setDescription('');
                        setMetaTitle('');
                        setMetaDescription('');
                        setDepethCapality('');
                        setDepthCapabilityImage('');
                        setSlug('');
                        setUniqueValueProposition('')
                        setBannerImage('');
                        setBannerVideo('');
                        setOutComeFocusedImage(' ');
                        setMobBannerVideo(' ');
                        setKeyCapabilitiesHeading("")
                        setWorkflow({
                            ...workflow,
                            workflowHeading: "",
                            workflowImage: "",
                            workflowPointer: ""
                        })
                        setbussinessBenefit({
                            ...bussinessBenefit,
                            bussinessBenefitHeading: "",
                            bussinessBenefitHeading: []
                        })
                        setOutComeFocusedHeading("")
                        if (imageRef1.current) {
                            imageRef1.current.value = "";
                        }
                        if (imageRef2.current) {
                            imageRef2.current.value = "";
                        }
                        if (imageRef3.current) {
                            imageRef3.current.value = "";
                        }
                        if (imageRef4.current) {
                            imageRef4.current.value = "";
                        }
                        if (imageRef5.current) {
                            imageRef5.current.value = "";
                        }
                        if (workflowImageRef.current) {
                            workflowImageRef.current.value = "";
                        }
                        setLoader(false)
                    } else {
                        setLoader(false)
                        toast.error(response.message)
                    }
                });
            }
        } else {
            toast.error('Please fill all required fields!');
            setLoader(false)
        }
    }
    useEffect(async () => {
        if (window.location.pathname === '/edit-service-tabs-subcategory' && query.get('id') != '' && query.get('subId') != '') {
            setLoader(true)
            await getSingleAPI(query.get('id'), query.get('subId')).then(response => {
                if (response.success) {
                    const data = response?.data;
                    if (data) {
                        setTabsId(query.get('id'))
                        setEditCase(true);
                        setName(data?.name ? data?.name : '');
                        setDescription(data?.description ? data?.description : '');

                        setMetaTitle(data?.metaTitle ? data?.metaTitle : '');
                        setMetaDescription(data ? data?.metaDecription : '');
                        setDepethCapality(data?.keyCapabilities ? data?.keyCapabilities : '');
                        setUniqueValueProposition(data?.outcomeFocusedDeployment ? data?.outcomeFocusedDeployment : '')
                        setDepthCapabilityImage(data?.keyCapabilitiesImage ? data?.keyCapabilitiesImage : '');
                        setBannerImage(data?.backImage ? data?.backImage : '');
                        setBannerVideo(data?.backImageMobile ? data?.backImageMobile : '');
                        setOutComeFocusedImage(data?.outcomeFocusedDeploymentImage ? data?.outcomeFocusedDeploymentImage : ' ');
                        setMobBannerVideo(data?.outcomeFocusedDeploymentVideo ? data?.outcomeFocusedDeploymentVideo : ' ');
                        setKeyCapabilitiesHeading(data?.keyCapabilitiesHeading ? data?.keyCapabilitiesHeading : '');
                        setWorkflow({
                            ...workflow,
                            workflowHeading: data?.workflowHeading ? data?.workflowHeading : '',
                            workflowImage: data?.workflowImage ? data?.workflowImage : '',
                            workflowPointer: data?.workflowPointer ? data?.workflowPointer : ''
                        });
                        setbussinessBenefit(
                            {
                                ...bussinessBenefit,
                                bussinessBenefitHeading: data?.bussinessBenefitHeading ? data?.bussinessBenefitHeading : '',
                                bussinessBenefitPointer: data?.bussinessBenefitPointer ? data?.bussinessBenefitPointer : '',
                            }
                        );
                        setOutComeFocusedHeading(data?.outcomeFocusedDeploymentHeading ? data?.outcomeFocusedDeploymentHeading : '');
                        setSlug(data?.slug ? data?.slug : '');
                        setLoader(false)
                    } else {
                        history.push('/404');
                    }
                } else {
                    history.push('/404');
                }
            });
        } else {
            setTabsId(query.get('id'))
        }
    }, [])


    return (
        <>
            <div className="nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-lg">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">{editCase ? 'Edit Service Subcatgeory' : 'Create Service Subcatgeory'}</h4>
                                                </div>
                                            </div>
                                            {
                                                loader ?
                                                    <RenderLoader trans={true} />
                                                    : null
                                            }
                                            <div className="nk-block">
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Normal Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Name*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={names} onChange={(e) => { setName(e.target.value); setSlug(slugify(e.target.value)) }} name='name' placeholder='Name' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Description*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" name='description' value={description} onChange={(e) => setDescription(e.target.value)} placeholder='Description' required className="form-control form-control-lg" style={{ width: '100%' }}>

                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Slug*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={slug} onChange={(e) => setSlug(e.target.value)} name='slug' placeholder='Slug' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Meta Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Meta Title*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" name='metaTitle' value={metaTitle} onChange={(e) => setMetaTitle(e.target.value)} placeholder='Meta Title' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Meta Description*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" name='metaDescription' value={metaDescription} onChange={(e) => setMetaDescription(e.target.value)} placeholder='Meta Description' required className="form-control form-control-lg" style={{ width: '100%' }}>

                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Upload Image/Videos</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Banner Image*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        {
                                                                            bannerImage ?
                                                                                <img src={BASE_IMG_URL2 + bannerImage} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                                : null
                                                                        }
                                                                        <input type="file" ref={imageRef2} name='bannerImage' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'services-consultants-subcategory');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setBannerImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" ref={imageRef2} name='bannerImage' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'services-consultants-subcategory');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setBannerImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Banner Mobile Image*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        {
                                                                            bannerVideo ?
                                                                                <img src={BASE_IMG_URL2 + bannerVideo} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                                : null
                                                                        }

                                                                        <input type="file" ref={imageRef3} name='bannerVideo' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'services-consultants-subcategory');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await videoUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setBannerVideo(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" ref={imageRef3} name='bannerVideo' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'services-consultants-subcategory');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await videoUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setBannerVideo(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Depth-Capability Image*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        {
                                                                            inDepthCapabilityImage ?
                                                                                <img src={BASE_IMG_URL2 + inDepthCapabilityImage} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                                : null
                                                                        }
                                                                        <input type="file" ref={imageRef1} name='inDepthCapabilityImage' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'services-consultants-subcategory');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setDepthCapabilityImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" ref={imageRef1} name='inDepthCapabilityImage' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'services-consultants-subcategory');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setDepthCapabilityImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        showSection ? (
                                                            <div className="data-item">
                                                                <div className="data-col">
                                                                    <span className="data-label">Outcome Focused Image*</span>
                                                                    {
                                                                        editCase ?
                                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                                {
                                                                                    outComeFocusedImage != '' ?
                                                                                        <img src={BASE_IMG_URL2 + outComeFocusedImage} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                                        : null
                                                                                }
                                                                                <input type="file" ref={imageRef4} name='bannerImage' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                                    onChange={async (event) => {
                                                                                        var file = event.target.files[0];
                                                                                        var formData = new FormData();
                                                                                        formData.append('path', 'services-consultants-subcategory');
                                                                                        formData.append('file', file, file.name);
                                                                                        setLoader(true)
                                                                                        await imageUpload(formData).then(response => {
                                                                                            if (response?.success) {
                                                                                                setOutComeFocusedImage(response?.aws?.key)
                                                                                                setLoader(false)
                                                                                                //toast.success(response.message)
                                                                                            } else {
                                                                                                toast.error(response.message)
                                                                                                setLoader(false)
                                                                                            }
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            :
                                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                                <input type="file" ref={imageRef4} name='bannerImage' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                                    onChange={async (event) => {
                                                                                        var file = event.target.files[0];
                                                                                        var formData = new FormData();
                                                                                        formData.append('path', 'services-consultants-subcategory');
                                                                                        formData.append('file', file, file.name);
                                                                                        setLoader(true)
                                                                                        await imageUpload(formData).then(response => {
                                                                                            if (response?.success) {
                                                                                                setOutComeFocusedImage(response?.aws?.key)
                                                                                                setLoader(false)
                                                                                                //toast.success(response.message)
                                                                                            } else {
                                                                                                toast.error(response.message)
                                                                                                setLoader(false)
                                                                                            }
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                    }
                                                                </div>
                                                            </div>

                                                        ) : null
                                                    }

                                                    {
                                                        showSection ? (
                                                            <div className="data-item">
                                                                <div className="data-col">
                                                                    <span className="data-label">Workflow Image</span>
                                                                    {
                                                                        editCase ?
                                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                                {
                                                                                    workflow?.workflowImage?.length ?
                                                                                        <img src={BASE_IMG_URL2 + workflow?.workflowImage} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                                        : null
                                                                                }
                                                                                <input type="file" ref={workflowImageRef} name='bannerImage' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                                    onChange={async (event) => {
                                                                                        var file = event.target.files[0];
                                                                                        var formData = new FormData();
                                                                                        formData.append('path', 'services-consultants-subcategory');
                                                                                        formData.append('file', file, file.name);
                                                                                        setLoader(true)
                                                                                        await imageUpload(formData).then(response => {
                                                                                            if (response?.success) {
                                                                                                setWorkflow({ ...workflow, workflowImage: response?.aws?.key })
                                                                                                setLoader(false)
                                                                                                //toast.success(response.message)
                                                                                            } else {
                                                                                                toast.error(response.message)
                                                                                                setLoader(false)
                                                                                            }
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            :
                                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                                <input type="file" ref={workflowImageRef} name='bannerImage' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                                    onChange={async (event) => {
                                                                                        var file = event.target.files[0];
                                                                                        var formData = new FormData();
                                                                                        formData.append('path', 'services-consultants-subcategory');
                                                                                        formData.append('file', file, file.name);
                                                                                        setLoader(true)
                                                                                        await imageUpload(formData).then(response => {
                                                                                            if (response?.success) {
                                                                                                setWorkflow({ ...workflow, workflowImage: response?.aws?.key })
                                                                                                setLoader(false)
                                                                                                //toast.success(response.message)
                                                                                            } else {
                                                                                                toast.error(response.message)
                                                                                                setLoader(false)
                                                                                            }
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                    }
                                                                </div>
                                                            </div>

                                                        ) : null
                                                    }

                                                    {/* <div className="data-item">
                                                    <div className="data-col">
                                                            <span className="data-label">Outcome Focused Video*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <video controls className='img-fluid'>
                                                                            <source src={BASE_IMG_URL2 + mobbannerVideo} type="video/mp4" />Sorry, your browser doesn't support embedded videos.
                                                                        </video>
                                                                        <input type="file" ref={imageRef5} name='bannerVideo' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'services-consultants-subcategory');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await videoUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setMobBannerVideo(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" ref={imageRef5} name='bannerVideo' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'services-consultants-subcategory');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await videoUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setMobBannerVideo(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div> */}
                                                </div>



                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">More Details</h6>
                                                    </div>

                                                    {
                                                        showSection ? (
                                                            <div className="data-item">
                                                                <div className="data-col">
                                                                    <span className="data-label">Key Capabilities Heading</span>
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="text" value={keyCapabilitiesHeading} onChange={(e) => { setKeyCapabilitiesHeading(e.target.value); }} name='keyCapabilitiesHeading' placeholder='Key Capabilities Heading' className="form-control form-control-lg" style={{ width: '100%' }} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        ) : null
                                                    }
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Key Capabilities</span>
                                                            <div className="form-control-wrap" style={{ width: '450px', height: 'auto' }}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={depthCapability}
                                                                    config={{
                                                                        toolbar: ['bold', 'italic', 'blockQuote', 'link', 'bulletedList']
                                                                    }}
                                                                    onReady={editor => {
                                                                        // console.log('editor ready!')
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        setDepethCapality(editor.getData())
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        showSection ? (
                                                            <div className="data-item">
                                                                <div className="data-col">
                                                                    <span className="data-label">Outcome Focused Deployment Heading</span>
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="text" value={outComeFocusedHeading} onChange={(e) => { setOutComeFocusedHeading(e.target.value); }} name='outComeFocusedHeading' placeholder='Outcome Focused Deployment Heading' className="form-control form-control-lg" style={{ width: '100%' }} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        ) : null
                                                    }
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Outcome Focused Deployment</span>
                                                            <div className="form-control-wrap" style={{ width: '450px', height: 'auto' }}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={uniqueValueProposition}
                                                                    config={{
                                                                        toolbar: ['bold', 'italic', 'blockQuote', 'link', 'bulletedList']
                                                                    }}
                                                                    onReady={editor => {
                                                                        // console.log('editor ready!')
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        setUniqueValueProposition(editor.getData())
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {
                                                        showSection ? (
                                                            <div className="data-item">
                                                                <div className="data-col">
                                                                    <span className="data-label">Workflow Heading</span>
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="text" value={workflow?.workflowHeading} onChange={(e) => {
                                                                            setWorkflow({
                                                                                ...workflow,
                                                                                workflowHeading: e.target.value
                                                                            })
                                                                        }} name='workflowHeading' placeholder='Workflow Heading' className="form-control form-control-lg" style={{ width: '100%' }} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        ) : null
                                                    }
                                                    {
                                                        showSection ? (
                                                            <div className="data-item">
                                                                <div className="data-col">
                                                                    <span className="data-label">Workflow Pointer</span>
                                                                    <div className="form-control-wrap" style={{ width: '450px', height: 'auto' }}>
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            data={workflow?.workflowPointer}
                                                                            config={{
                                                                                toolbar: ['bold', 'italic', 'blockQuote', 'link', 'bulletedList']
                                                                            }}
                                                                            onReady={editor => {
                                                                                // console.log('editor ready!')
                                                                            }}
                                                                            onChange={(event, editor) => {

                                                                                setWorkflow({
                                                                                    ...workflow,
                                                                                    workflowPointer: editor.getData()
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        ) : null
                                                    }


                                                </div>

                                                {/* bussinessBenefit testimonial */}

                                                {
                                                    showSection ? (
                                                        <div className="nk-data data-list">
                                                            <div className="data-head">
                                                                <h6 className="overline-title">Bussiness Benefit</h6>
                                                            </div>

                                                            <div className="data-item">
                                                                <div className="data-col">
                                                                    <span className="data-label">Bussiness Benefit Heading</span>
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="text" value={bussinessBenefit?.bussinessBenefitHeading} onChange={(e) => {
                                                                            setbussinessBenefit({
                                                                                ...bussinessBenefit,
                                                                                bussinessBenefitHeading: e.target.value
                                                                            })
                                                                        }} name='bussinessBenefitHeading' placeholder='Bussiness Benefit Heading' className="form-control form-control-lg" style={{ width: '100%' }} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {
                                                                bussinessBenefit?.bussinessBenefitPointer?.length ?
                                                                    bussinessBenefit?.bussinessBenefitPointer?.map((n, index) => {
                                                                        return (
                                                                            <div className="nk-data data-list" style={{ padding: '0px 0px', border: 'solid #f6f6f6 1px', background: '#f6f6f6' }} key={index}>
                                                                                <div className="data-item" style={{ padding: '5px 15px', background: '#f9f9f9', border: 'solid #f9f9f9 1px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                                    <span className="data-label" style={{ fontSize: '16px', width: 'auto', fontWeight: '550', color: '#6576ff' }}>{index + 1}: Pointer</span>
                                                                                    <span>
                                                                                        <button onClick={() => {
                                                                                            setbussinessBenefit({
                                                                                                ...bussinessBenefit,
                                                                                                bussinessBenefitPointer: bussinessBenefit?.bussinessBenefitPointer?.filter((n, ind) => ind != index)

                                                                                            }
                                                                                            )
                                                                                        }} className='btn btn-lg btn-primary' style={{ width: '35px', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0px' }}>
                                                                                            <TiDelete style={{ color: '#fff', width: '35px', height: '35px' }} />
                                                                                        </button>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="data-item">
                                                                                    <div className="data-col">
                                                                                        <span className="data-label">Benefit Heading</span>
                                                                                        <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                                            <input type="text" name='pointerHeading' value={n?.pointerHeading ? n?.pointerHeading : ""} onChange={(e) =>
                                                                                                setbussinessBenefit(
                                                                                                    {
                                                                                                        ...bussinessBenefit,

                                                                                                        bussinessBenefitPointer: bussinessBenefit?.bussinessBenefitPointer?.map((n, ind) => ind === index ? { ...n, pointerHeading: (e.target.value) } : n)
                                                                                                    }
                                                                                                )
                                                                                            } placeholder='Benefit Heading' className="form-control form-control-lg" style={{ width: '100%' }} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="data-item">
                                                                                    <div className="data-col">
                                                                                        <span className="data-label">Benefit Paragraph</span>
                                                                                        <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                                            <input type="text" name='pointerParagraph' value={n?.pointerParagraph ? n?.pointerParagraph : ""} onChange={(e) =>
                                                                                                setbussinessBenefit(
                                                                                                    {
                                                                                                        ...bussinessBenefit,

                                                                                                        bussinessBenefitPointer: bussinessBenefit?.bussinessBenefitPointer?.map((n, ind) => ind === index ? { ...n, pointerParagraph: (e.target.value) } : n)
                                                                                                    }
                                                                                                )
                                                                                            } placeholder='Benefit Paragraph' className="form-control form-control-lg" style={{ width: '100%' }} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        )
                                                                    }
                                                                    ) : null

                                                            }
                                                            <div className="data-head" style={{ padding: '15px', background: '#ececec', border: 'solid #ececec 1px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px 0px' }}>
                                                                <span>
                                                                    <button onClick={() => {
                                                                        setbussinessBenefit(
                                                                            {
                                                                                ...bussinessBenefit,
                                                                                bussinessBenefitPointer: [...bussinessBenefit?.bussinessBenefitPointer, initArrayData]

                                                                            }
                                                                        );
                                                                    }} className='btn btn-lg btn-primary' style={{ width: 'auto', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0px 15px' }}>
                                                                        <MdOutlineAdd style={{ color: '#fff', width: '35px', height: '35px' }} /> Create New Pointer
                                                                    </button>
                                                                </span>
                                                            </div>

                                                        </div>

                                                    ) : null
                                                }



                                                <div className="nk-data data-list">
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <div className="form-control-wrap" style={{ width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                                                                <button className="btn btn-lg btn-primary btn-block" onClick={(e) => handleCreateWebinar(e)}>{editCase ? 'Save' : 'Create'}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default CreateTabsSubCategory;
import React, { useEffect, useState, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import toast, { Toaster } from 'react-hot-toast';
import { getAllAPI, getSingleCaseStudyListAPI, postAPI, postCreateCaseStudyAPI, putAPI, putEditCaseStudyAPI } from './Action';
import { useLocation, useHistory } from 'react-router-dom';
import { BASE_IMG_URL2 } from '../../../Utils/Service';
import { imageUpload } from '../../../Utils/upload';
import { slugify } from '../../../Utils/Logic';
import RenderLoader from '../../../PanelComponent/RenderLoader';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const WhoWeAre = (props) => {
    const query = useQuery();
    const history = useHistory();

    const [editid, setEditId] = useState('');
    const [editCase, setEditCase] = useState(false);
    const [loader, setLoader] = useState(true)

    const imageRef1 = useRef();
    const imageRef1dv = useRef();
    const imageRef1mv = useRef();

    const imageRef2 = useRef();
    const imageRef2m = useRef();


    const imageRef3 = useRef();
    const imageRef3m = useRef();


    const imageRef4 = useRef();
    const imageRef4m = useRef();

    const imageRef5 = useRef();
    const imageRef5m = useRef();


    const [section1, setSection1] = useState('');
    const [sectionImage1, setSectionImage1] = useState('');
    const [sectionImage1dv, setSectionImage1dv] = useState('');
    const [sectionImage1mv, setSectionImage1mv] = useState('');


    const [section2, setSection2] = useState('');
    const [sectionImage2, setSectionImage2] = useState('');
    const [sectionImage2m, setSectionImage2m] = useState('');


    const [section3, setSection3] = useState('');
    const [sectionImage3, setSectionImage3] = useState('');
    const [sectionImage3m, setSectionImage3m] = useState('');

    const [section4, setSection4] = useState('');
    const [sectionImage4, setSectionImage4] = useState('');
    const [sectionImage4m, setSectionImage4m] = useState('');


    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');


    const [themeTitle, setThemeTitle] = useState('');
    const [themeSubTitle, setThemeSubTitle] = useState('');
    const [themeDescription, setThemeDescription] = useState('');
    const [themeDpBanner, setThemeDpBanner] = useState('');
    const [themeMbBanner, setThemeMbBanner] = useState('');

    const [totalEmployees, setTotalEmployees] = useState(0);
    const [totalClients, setTotalClients] = useState(0);
    const [totalProjects, setTotalProjects] = useState(0);
    const [totalProducts, setTotalProducts] = useState(0);
     
    const getAPICALL=async()=>{
        await getAllAPI(0, 5).then(response=>{
            if(response.success){
                if(response.data?.length){
                     const data=  response.data[0];
                     setSection1(data.whoWeAre)
                     setSectionImage1(data.whoweareBanner)
                     setSectionImage1dv(data.whoDesktopVideo)
                     setSectionImage1mv(data.whoMobileVideo)
                     setMetaTitle(data.metaTitle)
                     setMetaDescription(data.metaDescription)
                     setSection2(data.purpose)
                     setSectionImage2(data.purposeBanner)
                     setSectionImage2m(data.purposeBannerMb)
                     setSection3(data.vision)
                     setSectionImage3(data.visionBanner)
                     setSectionImage3m(data.visionBannerMobile)
                     setSection4(data.mission)
                     setSectionImage4(data.missionBanner)
                     setSectionImage4m(data.missionBannerMobile)
                     setThemeTitle(data.themeTitle)
                     setThemeSubTitle(data.themeSubTitle)
                     setThemeDescription(data.themedescription)
                     setThemeDpBanner(data.themeBanner)
                     setThemeMbBanner(data.themeMobileBanner)
                     setTotalEmployees(data.totalEmployees)
                     setTotalClients(data.totalClients)
                     setTotalProjects(data.totalProjects)
                     setTotalProducts(data.totalProducts)
                     setEditId(data?._id?data?._id:false)
                     setEditCase(true)
                }
                 setLoader(false)
            }else{
                setLoader(false)
            }
        })
    }
    const handleCreateCase=async(e)=>{
        e.preventDefault();
        setLoader(true);

        if(editCase)
        {
            const editdata = {
                whoWeAre: section1,
                whoweareBanner: sectionImage1,


                whoDesktopVideo: sectionImage1dv, //new
                whoMobileVideo: sectionImage1mv, //new

                metaTitle: metaTitle,
                metaDescription: metaDescription,

                purpose: section2,
                purposeBanner: sectionImage2,
                purposeBannerMb: sectionImage2m,  //new

                vision: section3,
                visionBanner: sectionImage3,
                visionBannerMobile: sectionImage3m, // new

                mission: section4,
                missionBanner: sectionImage4,
                missionBannerMobile: sectionImage4m,  //new

                themeTitle: themeTitle,
                themeSubTitle: themeSubTitle,
                themedescription: themeDescription,
                themeBanner: themeDpBanner,
                themeMobileBanner:themeMbBanner,  //new

                totalEmployees:totalEmployees,  //new
                totalClients:totalClients, //new
                totalProjects:totalProjects, //new
                totalProducts:totalProducts // new

            }
            await putAPI(editid, editdata).then(async response=>{
                if(response.success)
                {
                   toast.success(response.message);
                   if(imageRef1.current)
                   {
                       imageRef1.current.value = "";
                   }
                   if(imageRef1dv.current)
                   {
                       imageRef1dv.current.value = "";
                   }
                   if(imageRef1mv.current)
                   {
                       imageRef1mv.current.value = "";
                   }

                   if(imageRef2.current)
                   {
                       imageRef2.current.value = "";
                   }
                   if(imageRef2m.current)
                   {
                       imageRef2m.current.value = "";
                   }


                   if(imageRef3.current)
                   {
                       imageRef3.current.value = "";
                   }
                   if(imageRef3m.current)
                   {
                       imageRef3m.current.value = "";
                   }


                   if(imageRef4.current)
                   {
                       imageRef4.current.value = "";
                   }
                   if(imageRef4m.current)
                   {
                       imageRef4m.current.value = "";
                   }


                   if(imageRef5.current)
                   {
                       imageRef5.current.value = "";
                   }
                   if(imageRef5m.current)
                   {
                       imageRef5m.current.value = "";
                   }

                   await getAPICALL()
                }else{
                   toast.error(response.message);
                   setLoader(false)
                }
            })
        }else{
        if( 
            section1 != '' && sectionImage1 != '' && sectionImage1dv !='' && sectionImage1mv !='' && 
            section2 != '' && sectionImage2 != ''  && sectionImage2m &&
            section3 != '' && sectionImage3 != '' && sectionImage3m &&
            section4 != '' && sectionImage4 != '' && sectionImage4m &&
            metaTitle != '' && metaDescription !='' && 
            themeTitle != '' && themeSubTitle != '' && themeDescription != '' && themeDpBanner != '', themeMbBanner
        ){
            const data = {
                whoWeAre: section1,
                whoweareBanner: sectionImage1,


                whoDesktopVideo: sectionImage1dv, //new
                whoMobileVideo: sectionImage1mv, //new

                metaTitle: metaTitle,
                metaDescription: metaDescription,

                purpose: section2,
                purposeBanner: sectionImage2,
                purposeBannerMb: sectionImage2m,  //new

                vision: section3,
                visionBanner: sectionImage3,
                visionBannerMobile: sectionImage3m, // new

                mission: section4,
                missionBanner: sectionImage4,
                missionBannerMobile: sectionImage4m,  //new

                themeTitle: themeTitle,
                themeSubTitle: themeSubTitle,
                themedescription: themeDescription,
                themeBanner: themeDpBanner,
                themeMobileBanner:themeMbBanner,  //new

                totalEmployees:totalEmployees,  //new
                totalClients:totalClients, //new
                totalProjects:totalProjects, //new
                totalProducts:totalProducts // new

            }
            await postAPI(data).then(response=>{
                 if(response.success)
                 {
                    toast.success(response.message);
                    if(imageRef1.current)
                    {
                        imageRef1.current.value = "";
                    }
                    if(imageRef1dv.current)
                    {
                        imageRef1dv.current.value = "";
                    }
                    if(imageRef1mv.current)
                    {
                        imageRef1mv.current.value = "";
                    }

                    if(imageRef2.current)
                    {
                        imageRef2.current.value = "";
                    }
                    if(imageRef2m.current)
                    {
                        imageRef2m.current.value = "";
                    }


                    if(imageRef3.current)
                    {
                        imageRef3.current.value = "";
                    }
                    if(imageRef3m.current)
                    {
                        imageRef3m.current.value = "";
                    }


                    if(imageRef4.current)
                    {
                        imageRef4.current.value = "";
                    }
                    if(imageRef4m.current)
                    {
                        imageRef4m.current.value = "";
                    }


                    if(imageRef5.current)
                    {
                        imageRef5.current.value = "";
                    }
                    if(imageRef5m.current)
                    {
                        imageRef5m.current.value = "";
                    }
                    setLoader(false)
                 }else{
                    toast.error(response.message);
                    setLoader(false)
                 }
            })
        }else{
            toast.error('Please fill the required fields!');
            setLoader(false)
        }}
    }
    useEffect(async () => {
      getAPICALL();
    }, [])
    return (
        <>
            <div className="nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-lg">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">Who We Are Page</h4>
                                                </div>
                                            </div>
                                            {
                                                loader ?
                                                    <RenderLoader trans={true} />
                                                    : null
                                            }
                                            <div className="nk-block">
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Who We Are</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Description*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" value={section1} onChange={(e) => setSection1(e.target.value)} name='whodescription' placeholder='Description' required className="form-control form-control-lg" style={{ width: '100%' }}>
                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Banner Image*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <img src={BASE_IMG_URL2 + sectionImage1} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                        <input type="file" name='image' ref={imageRef1} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'whoWeAre');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setSectionImage1(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" name='image' ref={imageRef1} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'whoWeAre');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setSectionImage1(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Banner Desktop Video*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <video controls className='img-fluid'>
                                                                            <source src={BASE_IMG_URL2 + sectionImage1dv} type="video/mp4" />Sorry, your browser doesn't support embedded videos.
                                                                        </video>
                                                                        <input type="file" name='image' ref={imageRef1dv} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'whoWeAre');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setSectionImage1dv(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" name='image' ref={imageRef1dv} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'whoWeAre');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setSectionImage1dv(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Banner Mobile Video*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <video controls className='img-fluid'>
                                                                            <source src={BASE_IMG_URL2 + sectionImage1mv} type="video/mp4" />Sorry, your browser doesn't support embedded videos.
                                                                        </video>
                                                                        <input type="file" name='image' ref={imageRef1mv} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'whoWeAre');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setSectionImage1mv(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" name='image' ref={imageRef1mv} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'whoWeAre');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setSectionImage1mv(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Meta Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Meta Title*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" name='metaTitle' value={metaTitle} onChange={(e) => setMetaTitle(e.target.value)} placeholder='Meta Title' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Meta Description*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" name='metaDescription' value={metaDescription} onChange={(e) => setMetaDescription(e.target.value)} placeholder='Meta Description' required className="form-control form-control-lg" style={{ width: '100%' }}>

                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Our Purpose</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Description*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" value={section2} onChange={(e) => setSection2(e.target.value)} name='purpose' placeholder='Our Purpose Description' required className="form-control form-control-lg" style={{ width: '100%' }}>
                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Banner Desktop*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <img src={BASE_IMG_URL2 + sectionImage2} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                        <input type="file" name='image' ref={imageRef2} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'whoWeAre');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setSectionImage2(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" name='image' ref={imageRef2} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'whoWeAre');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setSectionImage2(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Banner Mobile*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <img src={BASE_IMG_URL2 + sectionImage2m} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                        <input type="file" name='image' ref={imageRef2m} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'whoWeAre');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setSectionImage2m(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" name='image' ref={imageRef2m} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'whoWeAre');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setSectionImage2m(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Vision</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Description*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" value={section3} onChange={(e) => setSection3(e.target.value)} name='vision' placeholder='Vision Description' required className="form-control form-control-lg" style={{ width: '100%' }}>
                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Banner Desktop*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <img src={BASE_IMG_URL2 + sectionImage3} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                        <input type="file" name='image' ref={imageRef3} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'whoWeAre');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setSectionImage3(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" name='image' ref={imageRef3} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'whoWeAre');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setSectionImage3(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Banner Mobile*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <img src={BASE_IMG_URL2 + sectionImage3m} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                        <input type="file" name='image' ref={imageRef3m} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'whoWeAre');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setSectionImage3m(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" name='image' ref={imageRef3m} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'whoWeAre');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setSectionImage3m(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Mission</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Description*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" value={section4} onChange={(e) => setSection4(e.target.value)} name='mission' placeholder='Mission Description' required className="form-control form-control-lg" style={{ width: '100%' }}>
                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Banner Desktop*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <img src={BASE_IMG_URL2 + sectionImage4} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                        <input type="file" name='image' ref={imageRef4} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'whoWeAre');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setSectionImage4(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" name='image' ref={imageRef4} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'whoWeAre');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setSectionImage4(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Banner Mobile*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <img src={BASE_IMG_URL2 + sectionImage4m} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                        <input type="file" name='image' ref={imageRef4m} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'whoWeAre');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setSectionImage4m(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" name='image' ref={imageRef4m} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'whoWeAre');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setSectionImage4m(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Theme</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Theme Title*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" value={themeTitle} onChange={(e) => setThemeTitle(e.target.value)} name='themeTitle' placeholder='Theme Title' required className="form-control form-control-lg" style={{ width: '100%' }}>
                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Theme Sub-Title*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" value={themeSubTitle} onChange={(e) => setThemeSubTitle(e.target.value)} name='themeSubTitle' placeholder='Theme Sub-title' required className="form-control form-control-lg" style={{ width: '100%' }}>
                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Theme Description*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" value={themeDescription} onChange={(e) => setThemeDescription(e.target.value)} name='themedescription' placeholder='Description' required className="form-control form-control-lg" style={{ width: '100%' }}>
                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Theme Desktop Banner*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <img src={BASE_IMG_URL2 + themeDpBanner} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                        <input type="file" name='image' ref={imageRef5} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'whoWeAre');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setThemeDpBanner(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" name='image' ref={imageRef5} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'whoWeAre');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setThemeDpBanner(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Theme Mobile Banner*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <img src={BASE_IMG_URL2 + themeMbBanner} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                        <input type="file" name='image' ref={imageRef5m} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'whoWeAre');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setThemeMbBanner(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" name='image' ref={imageRef5m} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'whoWeAre');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setThemeMbBanner(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>


                                                </div>

                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">More Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Total Employees*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="number" name='employess' value={totalEmployees} onChange={(e) => setTotalEmployees(e.target.value)} placeholder='0' className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Total Clients*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                            <input type="number" name='clients' value={totalClients} onChange={(e) => setTotalClients(e.target.value)} placeholder='0' className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Total Projects*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                            <input type="number" name='projects' value={totalProjects} onChange={(e) => setTotalProjects(e.target.value)} placeholder='0' className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Total Innovative Products*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                            <input type="number" name='prodcuts' value={totalProducts} onChange={(e) => setTotalProducts(e.target.value)} placeholder='0' className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>


                                                <div className="nk-data data-list">
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <div className="form-control-wrap" style={{ width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                                                                <button className="btn btn-lg btn-primary btn-block" onClick={(e) => handleCreateCase(e)}>{editCase ? 'Save' : 'Create'}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default WhoWeAre;
import React, { useEffect, useState, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useHistory } from 'react-router-dom';
import { getSingleAPI, postAPI, putAPI } from './Action';
import { imageUpload } from '../../Utils/upload';
import { BASE_IMG_URL2 } from '../../Utils/Service';
import RenderLoader from '../../PanelComponent/RenderLoader';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const AddAboutAwards = (props) => {
    const query = useQuery();
    const history = useHistory();
    const [editCase, setEditCase] = useState(false);


    const [name, setName] = useState('');   //title
    const [message, setMessage] = useState('');   //short description
    const [profile, setProfile] = useState(''); // image
    const [profile2, setProfile2] = useState(''); // image
    const [profile3, setProfile3] = useState(''); //
    const [color, setColor] = useState(' ');   //fileUrl
    const [rowReverse, setRowReverse] = useState(false);
    const [colReverse, setColReverse] = useState(false);
    const [loader, setLoader] = useState(false);
    const imageRef1 = useRef();
    const imageRef12 = useRef();
    const imageRef13 = useRef();
    const handleCreateWebinar = async (e) => {
        e.preventDefault();
        setLoader(true)
        if(name != '' && message != '' && profile != '')
        {
            var data = {
                "title": name,
                "shortDescription": message,
                "image": profile,
                "fileUrl": color,
                "mbimage":profile2,
                "featuredImage":profile3,
                "rowReverse":rowReverse,
                "columnReverse":colReverse
            }
        //     // if(color)
        //     // {
        //         data = Object.assign(data, {fileUrl: color})
        //    // }
            if(editCase)
            {
               putAPI(query.get('id'), data).then(response=>{
                    if(response.success)
                    {
                        toast.success(response.message)
                        setEditCase(false)
                        setName('');
                        setColor('')
                        setMessage('');
                        setProfile('');
                        setProfile2('');
                        setColReverse(false)
                        setRowReverse(false)
                        history.push('/awards')
                        if(imageRef1.current)
                        {
                            imageRef1.current.value = "";
                        }
                        if(imageRef12.current)
                        {
                            imageRef12.current.value = "";
                        }
                        if(imageRef13.current)
                        {
                            imageRef13.current.value = "";
                        }
                        setLoader(false)
                    }else{
                        toast.error(response.message)
                        setLoader(false)
                    }
               });
            }else{
               postAPI(data).then(response=>{
                      if(response.success)
                      {
                        toast.success(response.message)
                        setName('');
                        setMessage('');
                        setColor('')
                        setProfile('');
                        setProfile2('');
                        setColReverse(false)
                        setRowReverse(false)
                        if(imageRef1.current)
                        {
                            imageRef1.current.value = "";
                        }
                        if(imageRef12.current)
                        {
                            imageRef12.current.value = "";
                        }
                        if(imageRef13.current)
                        {
                            imageRef13.current.value = "";
                        }
                        setLoader(false)
                      }else{
                        toast.error(response.message)
                        setLoader(false)
                      }
               });
            }
        }else{
            toast.error('Please fill all the required fields!');
            setLoader(false)
        }
    }
    useEffect(async () => {
        if (window.location.pathname === '/edit-awards' && query.get('id') != '') {
            setLoader(true)
            await getSingleAPI(query.get('id')).then(response => {
                if (response.success) {
                    const data = response?.data;
                    if(data) {
                        setEditCase(true);
                        setName(data?.title?data?.title:'');
                        setMessage(data?.shortDescription?data?.shortDescription:'');
                        setProfile(data?.image?data?.image:'');
                        setProfile2(data?.mbimage?data?.mbimage:'');
                        setColor(data?.fileUrl?data?.fileUrl:'')
                        setProfile3(data?.featuredImage?data?.featuredImage:'');
                        setColReverse(data?.columnReverse?true:false)
                        setRowReverse(data?.rowReverse?true:false)
                        setLoader(false)
                    }else {
                        history.push('/404');
                    }
                } else {
                     history.push('/404');
                }
            });
        }
    }, [])
    return (
        <>
            <div className="nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-lg">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">{editCase ? 'Edit About Award & Newsroom' : 'Add About Award & Newsroom'}</h4>
                                                </div>
                                            </div>
                                            {
                                                loader?
                                                   <RenderLoader trans={true}/>
                                                :null
                                            }
                                            <div className="nk-block">
                                            <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Normal Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Title*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                            <div className="form-control-wrap" style={{ width: '450px', height: 'auto' }}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={name}
                                                                    config={{
                                                                        toolbar: ['bold', 'italic', 'blockQuote', 'link', 'bulletedList'],
                                                                    }}
                                                                    
                                                                    onReady={editor => {
                                                                        // console.log('editor ready!')
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        setName(editor.getData())
                                                                    }}
                                                                />
                                                            </div>
                                                                {/* <input type="text" value={name} onChange={(e) => setName(e.target.value)} name='name' placeholder='Title' required className="form-control form-control-lg" style={{ width: '100%' }} /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">fileUrl*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={color} onChange={(e) => setColor(e.target.value)} name='name' placeholder='FileUrl' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Description*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={message}
                                                                    config={{
                                                                        toolbar: ['bold', 'italic', 'blockQuote', 'link', 'bulletedList']
                                                                    }}
                                                                    onReady={editor => {
                                                                        // console.log('editor ready!')
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        setMessage(editor.getData())
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Row Reverse</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                               <select className="form-control form-control-lg" style={{ width: '100%' }} value={rowReverse} onChange={(e)=>setRowReverse(e.target.value)}>
                                                                   <option value='false'>False</option>
                                                                   <option value='true'>True</option>
                                                               </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Column Reverse</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                               <select className="form-control form-control-lg" style={{ width: '100%' }} value={colReverse} onChange={(e)=>setColReverse(e.target.value)}>
                                                                   <option value='false'>False</option>
                                                                   <option value='true'>True</option>
                                                               </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Upload Image</h6>
                                                    </div>
                                                        <div className="data-item">
                                                            <div className="data-col">
                                                                <span className="data-label">Desktop Banner Image*</span>
                                                                {
                                                                    editCase?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                    <img src={BASE_IMG_URL2+profile} className='img-fluid' style={{margin:'15x',padding:'15px'}}/>
                                                                    <input type="file" ref={imageRef1} name='bannerVideo' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                    onChange={async (event)=>{
                                                                        var file = event.target.files[0];
                                                                        var formData = new FormData();
                                                                        formData.append('path', 'about-awards');
                                                                        formData.append('file', file, file.name);
                                                                        setLoader(true)
                                                                        await imageUpload(formData).then(response=>{
                                                                            if(response?.success)
                                                                            {
                                                                                setProfile(response?.aws?.key)
                                                                                setLoader(false)
                                                                                //toast.success(response.message)
                                                                            }else{
                                                                                toast.error(response.message)
                                                                                setLoader(false)
                                                                            }
                                                                        })
                                                                }} />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                    <input type="file" ref={imageRef1} name='bannerVideo' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                    onChange={async (event)=>{
                                                                        var file = event.target.files[0];
                                                                        var formData = new FormData();
                                                                        formData.append('path', 'about-awards');
                                                                        formData.append('file', file, file.name);
                                                                        setLoader(true)
                                                                        await imageUpload(formData).then(response=>{
                                                                            if(response?.success)
                                                                            {
                                                                                setProfile(response?.aws?.key)
                                                                                setLoader(false)
                                                                                //toast.success(response.message)
                                                                            }else{
                                                                                toast.error(response.message)
                                                                                setLoader(false)
                                                                            }
                                                                        })
                                                                }} />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div> 
                                                        <div className="data-item">
                                                            <div className="data-col">
                                                                <span className="data-label">Mobile Banner Image*</span>
                                                                {
                                                                    editCase?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                    <img src={BASE_IMG_URL2+profile2} className='img-fluid' style={{margin:'15x',padding:'15px'}}/>
                                                                    <input type="file" ref={imageRef12} name='bannerVideo' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                    onChange={async (event)=>{
                                                                        var file = event.target.files[0];
                                                                        var formData = new FormData();
                                                                        formData.append('path', 'about-awards');
                                                                        formData.append('file', file, file.name);
                                                                        setLoader(true)
                                                                        await imageUpload(formData).then(response=>{
                                                                            if(response?.success)
                                                                            {
                                                                                setProfile2(response?.aws?.key)
                                                                                setLoader(false)
                                                                                //toast.success(response.message)
                                                                            }else{
                                                                                toast.error(response.message)
                                                                                setLoader(false)
                                                                            }
                                                                        })
                                                                }} />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                    <input type="file" ref={imageRef12} name='bannerVideo' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                    onChange={async (event)=>{
                                                                        var file = event.target.files[0];
                                                                        var formData = new FormData();
                                                                        formData.append('path', 'about-awards');
                                                                        formData.append('file', file, file.name);
                                                                        setLoader(true)
                                                                        await imageUpload(formData).then(response=>{
                                                                            if(response?.success)
                                                                            {
                                                                                setProfile2(response?.aws?.key)
                                                                                setLoader(false)
                                                                                //toast.success(response.message)
                                                                            }else{
                                                                                toast.error(response.message)
                                                                                setLoader(false)
                                                                            }
                                                                        })
                                                                }} />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div> 
                                                        <div className="data-item">
                                                            <div className="data-col">
                                                                <span className="data-label">Featured Image*</span>
                                                                {
                                                                    editCase?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                    <img src={BASE_IMG_URL2+profile3} className='img-fluid' style={{margin:'15x',padding:'15px'}}/>
                                                                    <input type="file" ref={imageRef13} name='bannerVideo' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                    onChange={async (event)=>{
                                                                        var file = event.target.files[0];
                                                                        var formData = new FormData();
                                                                        formData.append('path', 'about-awards');
                                                                        formData.append('file', file, file.name);
                                                                        setLoader(true)
                                                                        await imageUpload(formData).then(response=>{
                                                                            if(response?.success)
                                                                            {
                                                                                setProfile3(response?.aws?.key)
                                                                                setLoader(false)
                                                                                //toast.success(response.message)
                                                                            }else{
                                                                                toast.error(response.message)
                                                                                setLoader(false)
                                                                            }
                                                                        })
                                                                }} />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                    <input type="file" ref={imageRef13} name='bannerVideo' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                    onChange={async (event)=>{
                                                                        var file = event.target.files[0];
                                                                        var formData = new FormData();
                                                                        formData.append('path', 'about-awards');
                                                                        formData.append('file', file, file.name);
                                                                        setLoader(true)
                                                                        await imageUpload(formData).then(response=>{
                                                                            if(response?.success)
                                                                            {
                                                                                setProfile3(response?.aws?.key)
                                                                                setLoader(false)
                                                                                //toast.success(response.message)
                                                                            }else{
                                                                                toast.error(response.message)
                                                                                setLoader(false)
                                                                            }
                                                                        })
                                                                }} />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div> 
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <div className="form-control-wrap" style={{ width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                                                                <button className="btn btn-lg btn-primary btn-block" onClick={(e) => handleCreateWebinar(e)}>{editCase ? 'Save' : 'Create'}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default AddAboutAwards;
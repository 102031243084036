import React, { useEffect, useState, useRef } from 'react';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import toast, { Toaster } from 'react-hot-toast';
import { getSingleCaseStudyListAPI, postCreateCaseStudyAPI, putEditCaseStudyAPI } from './Action';
import { useLocation, useHistory } from 'react-router-dom';
import { BASE_IMG_URL2 } from '../../Utils/Service';
import { imageUpload } from '../../Utils/upload';
import { slugify } from '../../Utils/Logic';
import RenderLoader from '../../PanelComponent/RenderLoader';
import ServicesSlugList from '../../ExternalComponent/ServicesSlugList';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const CreateCaseStudy = (props) => {
    const query = useQuery();
    const history = useHistory();
    const [editCase, setEditCase] = useState(false);
    const [title, setTitle] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    // const [description, setDescription] = useState('');
    const [slug, setSlug] = useState('');
    const [featured, setFeatured] = useState(false);
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [images, setImages] = useState('');
    // const [clientLogo, setClientLogo] = useState('');
    // const [bannerImage, setBannerImage] = useState('');
    // const [background, setBackground] = useState('');
    // const [challenges, setChallenges] = useState('');
    // const [benefits, setBenefits] = useState('');
    // const [solutions, setSolutions] = useState('');
    // const [client, setClients] = useState('');
    // const [location, setLocation] = useState('');
    // const [industries, setIndustries] = useState('');
    // const [employess, setEmployess] = useState('');
    // const [products, setProducts] = useState('');
    const [loader, setLoader] = useState(false)
    const [service, setService] = useState("");
    const [pdfDoc, setPdfDoc] = useState("");
    const imageRef1 = useRef();
    const imageRef2 = useRef();
    // const imageRef3 = useRef();

    const handleCreateCase = async (e) => {
        e.preventDefault();
        setLoader(true)
        if (title != '' && shortDescription != '' && slug != '' && images != '')
        // && clientLogo != '' && bannerImage != '' && background != ''
        //) 
        {
            const data =
            {
                title: title,
                shortDescription: shortDescription,
                image: images,
                metaTitle: metaTitle,
                metaDescription: metaDescription,
                // description: description,
                // challenges: challenges,
                // background: background,
                featured: ['true', true]?.includes(featured) ? true : false,
                // solutions: solutions,
                // benefits: benefits,
                // client: client,
                // clientLogo: clientLogo,
                // location: location,
                // industries: industries,
                // employees: employess,
                // products: products,
                // bannerImage: bannerImage,
                slug: slug,
                service: service, //(service?.split(","))?.length? ((service)?.split(","))?.filter((n)=>n) :[]:[]
                download: pdfDoc ? pdfDoc : ""
            };
            if (editCase) {
                await putEditCaseStudyAPI(query.get('id'), data).then(response => {
                    if (response.success === true || response.status === true) {
                        toast.success(response.message);
                        setTitle('');
                        setShortDescription('');
                        setImages('');
                        setMetaTitle('');
                        setMetaDescription('')
                        // setDescription('');
                        // setChallenges('');
                        // setBackground('');
                        setFeatured(false);
                        // setSolutions('');
                        // setBenefits('');
                        // setClients('');
                        // setClientLogo('');
                        setService('')
                        setPdfDoc("")
                        // setLocation('');
                        // setIndustries('');
                        // setEmployess('');
                        // setProducts('');
                        // setBannerImage('');
                        setSlug('');
                        history.push('/case-study')
                        if (imageRef1.current) {
                            imageRef1.current.value = "";
                        }
                        if (imageRef2.current) {
                            imageRef2.current.value = "";
                        }
                        // if (imageRef3.current) {
                        //     imageRef3.current.value = "";
                        // }
                        setLoader(false)
                    } else {
                        toast.error(response.message);
                        setLoader(false)
                    }
                })
            } else {
                await postCreateCaseStudyAPI(data).then(response => {
                    if (response.success) {
                        toast.success(response.message);
                        setTitle('');
                        setShortDescription('');
                        setImages('');
                        setMetaTitle('');
                        setMetaDescription('')
                        // setDescription('');
                        // setChallenges('');
                        // setBackground('');
                        setFeatured(false);
                        setPdfDoc("")
                        // setSolutions('');
                        // setBenefits('');
                        // setClients('');
                        // setClientLogo('');
                        // setLocation('');
                        // setIndustries('');
                        // setEmployess('');
                        setService('')
                        // setProducts('');
                        // setBannerImage('');
                        setSlug('');
                        if (imageRef1.current) {
                            imageRef1.current.value = "";
                        }
                        if (imageRef2.current) {
                            imageRef2.current.value = "";
                        }
                        // imageRef2.current.value = "";
                        // imageRef3.current.value = "";
                        setLoader(false)
                    } else {
                        toast.error(response.message);
                        setLoader(false)
                    }
                })
            }
        } else {
            toast.error('Please fill the required fields!');
            setLoader(false)
        }
    }
    useEffect(async () => {
        if (window.location.pathname === '/edit-case-study' && query.get('id') != '') {
            setLoader(true)
            await getSingleCaseStudyListAPI(query.get('id')).then(response => {
                if (response.success) {
                    const data = response?.data;
                    if (data) {
                        setEditCase(true);
                        setTitle(data?.title ? data?.title : '');
                        setShortDescription(data?.shortDescription ? data?.shortDescription : '');
                        setImages(data?.image ? data?.image : '');
                        setMetaTitle(data?.metaTitle ? data?.metaTitle : '');
                        setMetaDescription(data?.metaDescription ? data?.metaDescription : '')
                        // setDescription(data?.description ? data?.description : '');
                        // setChallenges(data?.challenges ? data?.challenges : '');
                        // setBackground(data?.background ? data?.background : '');
                        setFeatured(data?.featured ? data?.featured : '');
                        // setSolutions(data?.solutions ? data?.solutions : '');
                        // setBenefits(data?.benefits ? data?.benefits : '');
                        // setClients(data?.client ? data?.client : '');
                        // setClientLogo(data?.clientLogo ? data?.clientLogo : '');
                        // setLocation(data?.location ? data?.location : '');
                        // setIndustries(data?.industries ? data?.industries : '');
                        // setEmployess(data?.employees ? data?.employees : '');
                        // setProducts(data?.products ? data?.products : '');
                        // setBannerImage(data?.bannerImage ? data?.bannerImage : '');
                        setSlug(data?.slug ? data?.slug : '');
                        setService(data?.service ? data?.service : "")
                        setPdfDoc(data?.download ? data?.download : "")
                        setLoader(false)
                    } else {
                        history.push('/404');
                    }
                } else {
                    history.push('/404')
                }
            });
        }
    }, [])
    return (
        <>
            <div className="nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-lg">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">{editCase ? 'Edit Case Study' : 'Create Case Study'}</h4>
                                                </div>
                                            </div>
                                            {
                                                loader ?
                                                    <RenderLoader trans={true} />
                                                    : null
                                            }
                                            <div className="nk-block">
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Normal Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Heading/Title*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={title} onChange={(e) => { setTitle(e.target.value); setSlug(slugify(e.target.value)) }} name='title' placeholder='Heading/Title' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Short Description*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" value={shortDescription} onChange={(e) => setShortDescription(e.target.value)} name='shortDescription' placeholder='Short Description' required className="form-control form-control-lg" style={{ width: '100%' }}>

                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Description*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" value={description} onChange={(e) => setDescription(e.target.value)} name='description' placeholder='Description' required className="form-control form-control-lg" style={{ width: '100%' }}>

                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Slug*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" name='slug' value={slug} onChange={(e) => setSlug(e.target.value)} placeholder='Slug' className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Featured</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <select className="form-control form-control-lg" value={featured} onChange={(e) => setFeatured(e.target.value)} defaultValue='false' name='featured' style={{ width: '100%' }}>
                                                                    <option value='false'>False</option>
                                                                    <option value='true'>True</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <ServicesSlugList label="Select Service" value={service} onChange={(e) => setService(e)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    !pdfDoc && (
                                                        <div className="nk-data data-list">
                                                            <div className="data-head">
                                                                <h6 className="overline-title">Meta Details</h6>
                                                            </div>
                                                            <div className="data-item">
                                                                <div className="data-col">
                                                                    <span className="data-label">Meta Title</span>
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="text" name='metaTitle' value={metaTitle} onChange={(e) => setMetaTitle(e.target.value)} placeholder='Meta Title' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="data-item">
                                                                <div className="data-col">
                                                                    <span className="data-label">Meta Description</span>
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <textarea type="text" name='metaDescription' value={metaDescription} onChange={(e) => setMetaDescription(e.target.value)} placeholder='Meta Description' required className="form-control form-control-lg" style={{ width: '100%' }}>

                                                                        </textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )
                                                }
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Upload Image</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Image*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <img src={BASE_IMG_URL2 + images} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                        <input type="file" name='image' ref={imageRef1} accept="image/*" className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'caseStudy');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setImages(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" name='image' ref={imageRef1} accept="image/*" className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'caseStudy');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setImages(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Upload PDF*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                                                {
                                                                    pdfDoc ?
                                                                        <a href={BASE_IMG_URL2 + pdfDoc} className='img-fluid' style={{ margin: '15x', padding: '15px' }} target='_blank' >View PDF</a>
                                                                        : null
                                                                }
                                                                <br />
                                                                {
                                                                    editCase ?
                                                                        <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                            <input type="file" name='pdfDoc' ref={imageRef2} accept=".pdf" className="form-control form-control-lg" style={{ width: '100%' }}
                                                                                onChange={async (event) => {
                                                                                    var file = event.target.files[0];
                                                                                    var formData = new FormData();
                                                                                    formData.append('path', 'caseStudy');
                                                                                    formData.append('file', file, file.name);
                                                                                    setLoader(true)
                                                                                    await imageUpload(formData).then(response => {
                                                                                        if (response?.success) {
                                                                                            setPdfDoc(response?.aws?.key)
                                                                                            setLoader(false)
                                                                                            //toast.success(response.message)
                                                                                        } else {
                                                                                            toast.error(response.message)
                                                                                            setLoader(false)
                                                                                        }
                                                                                    })
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        :
                                                                        <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                            <input type="file" name='pdfDoc' ref={imageRef2} accept=".pdf" className="form-control form-control-lg" style={{ width: '100%' }}
                                                                                onChange={async (event) => {
                                                                                    var file = event.target.files[0];
                                                                                    var formData = new FormData();
                                                                                    formData.append('path', 'caseStudy');
                                                                                    formData.append('file', file, file.name);
                                                                                    setLoader(true)
                                                                                    await imageUpload(formData).then(response => {
                                                                                        if (response?.success) {
                                                                                            setPdfDoc(response?.aws?.key)
                                                                                            setLoader(false)
                                                                                            //toast.success(response.message)
                                                                                        } else {
                                                                                            toast.error(response.message)
                                                                                            setLoader(false)
                                                                                        }
                                                                                    })
                                                                                }}
                                                                            />
                                                                        </div>
                                                                }
                                                            </div>




                                                        </div>
                                                    </div>
                                                    {/* <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Banner Image*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <img src={BASE_IMG_URL2 + bannerImage} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                        <input type="file" name='bannerImage' ref={imageRef3} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'caseStudy');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setBannerImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" name='bannerImage' ref={imageRef3} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'caseStudy');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setBannerImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div> */}
                                                </div>
                                                {/* <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">More Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Background*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" value={background} onChange={(e) => setBackground(e.target.value)} name='background' placeholder='Background' className="form-control form-control-lg" style={{ width: '100%' }}>

                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Challenges</span>
                                                            <div className="form-control-wrap" style={{ width: '450px', height: 'auto' }}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={challenges}
                                                                    config={{
                                                                        toolbar: ['bold', 'italic', 'blockQuote', 'link', 'bulletedList']
                                                                    }}
                                                                    onReady={editor => {
                                                                        // console.log('editor ready!')
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        setChallenges(editor.getData())
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Benefits</span>
                                                            <div className="form-control-wrap" style={{ width: '450px', height: 'auto' }}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={benefits}
                                                                    config={{
                                                                        toolbar: ['bold', 'italic', 'blockQuote', 'link', 'numberedList']
                                                                    }}
                                                                    onReady={editor => {
                                                                        //console.log('editor ready!')
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        setBenefits(editor.getData())
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Solutions</span>
                                                            <div className="form-control-wrap" style={{ width: '450px', height: 'auto' }}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={solutions}
                                                                    config={{
                                                                        toolbar: ['bold', 'italic', 'blockQuote', 'link', 'numberedList']
                                                                    }}
                                                                    onReady={editor => {
                                                                        //console.log('editor ready!')
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        setSolutions(editor.getData())
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Client</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" name='client' value={client} onChange={(e) => setClients(e.target.value)} placeholder='Client' className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Location</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" name='location' value={location} onChange={(e) => setLocation(e.target.value)} placeholder='Location' className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Industries</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" name='industries' value={industries} onChange={(e) => setIndustries(e.target.value)} placeholder='Industries' className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Employees</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" name='employess' value={employess} onChange={(e) => setEmployess(e.target.value)} placeholder='Employess' className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Product</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" name='product' value={products} onChange={(e) => setProducts(e.target.value)} placeholder='Product' className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="nk-data data-list">
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <div className="form-control-wrap" style={{ width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                                                                <button className="btn btn-lg btn-primary btn-block" onClick={(e) => handleCreateCase(e)}>{editCase ? 'Save' : 'Create'}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default CreateCaseStudy;
import React, { useEffect, useState, useRef } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useHistory } from 'react-router-dom';
import { BASE_IMG_URL2, getMethod, postMethod, putMethod } from '../../Utils/Service';
import { imageUpload } from '../../Utils/upload';
import RenderLoader from '../../PanelComponent/RenderLoader';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {slugify} from '../../Utils/Logic';
function useQuery() {

    return new URLSearchParams(useLocation().search);
}
const CreateInmorphianContent = (props) => {
    const query = useQuery();
    const history = useHistory();
    const [editCase, setEditCase] = useState(false);
    const [loader, setLoader] = useState(false)

    const [title, setTitle] = useState('');
    const [date, setDate] = useState('');
    const [timeFrom, setTimeFrom] = useState('');
    const [timeTo, setTimeTo] = useState('');
    const [description, setDescription] =useState('');
    const [url, setUrl] = useState('');
    const [order, setOrder] = useState(0);
    const [color, setColor] = useState('');
    const [type, setType] = useState('');
    

    const handleCreateWhitePaper = async (e) => {
        e.preventDefault();
        setLoader(true)
        if (title != '' && type != '') {
            const data = {
                title:title,
                date:date,
                timeFrom:timeFrom,
                timeTo:timeTo,
                description:description,
                url:url,
                order:order,
                color:color,
                type:type?type:'pointer',
            }
            if (editCase) {
                  await putMethod('/api/inmorphian-content/'+query.get('id')+'/edit', data).then((response)=>{
                    if(response?.success)
                    {
                        setTitle('');
                        setDate('');
                        setTimeFrom('');
                        setTimeTo('');
                        setDescription('');
                        setUrl('');
                        setOrder(0);
                        setColor('');
                        setType('');
                      
                        toast.success(response.message);
                        history.push('/inmorphian-content')
                    }else{
                        toast.error(response.message)
                        setLoader(false)
                    }
                  })
            } else {
                await postMethod('/api/inmorphian-content/add', data).then(response => {
                    if (response.success) {
                        toast.success(response.message);
                        setTitle('');
                        setDate('');
                        setTimeFrom('');
                        setTimeTo('');
                        setDescription('');
                        setUrl('');
                        setOrder(0);
                        setColor('');
                        setType('');
                        setLoader(false)
                    } else {
                        toast.error(response.message)
                        setLoader(false)
                    }
                });
            }
        } else {
            toast.error('Please fill all required fields!')
            setLoader(false)
        }
    }
    useEffect(async () => {
        if (window.location.pathname === '/edit-inmorphian-content' && query.get('id') != '') {
            setLoader(true)
            await getMethod('/api/inmorphian-content/'+query.get('id')+'/single').then(response => {
                
            if (response.success) {
                      const data = response.data;
                      if (data) {
                        setTitle(data?.title?data?.title:'');
                        setDate(data?.date?data?.date:'')
                        setTimeFrom(data?.timeFrom?data?.timeFrom:'');
                        setTimeTo(data?.timeTo?data?.timeTo:'');
                        setDescription(data?.description?data?.description:'');
                        setUrl(data?.url?data?.url:'');
                        setOrder(data?.order?data?.order:0);
                        setColor(data?.color?data?.color:'');
                        setType(data?.type?data?.type:'')
                        setEditCase(true);
                        setLoader(false)
             } else {
                         history.push('/404')
                     }
            } else {
                     history.push('/404')
                 }
            });
        }
    }, [])
    return (
        <>
            <div className="nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-lg">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">{editCase ? 'Edit Inmorphian Other Section' : 'Create Inmorphian Other Section'}</h4>
                                                </div>
                                            </div>
                                            {
                                                loader ?
                                                    <RenderLoader trans={true} />
                                                    : null
                                            }
                                            <div className="nk-block">
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Normal Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Title*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                            <input type="text" value={title} onChange={(e) => {setTitle(e.target.value);}} name='title' placeholder='Title' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Date</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                            <input type="date" value={date} onChange={(e) => setDate(e.target.value)} name='date' placeholder='Date' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Time From</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                            <input type="time" value={timeFrom} onChange={(e) => setTimeFrom(e.target.value)} name='timeFrom' placeholder='Time From' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Time To</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                            <input type="time" value={timeTo} onChange={(e) => setTimeTo(e.target.value)} name='timeTo' placeholder='Time To' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Description</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={description}
                                                                    config={{
                                                                        toolbar: ['bold', 'italic', 'blockQuote', 'link', 'bulletedList']
                                                                    }}
                                                                    onReady={editor => {
                                                                        // console.log('editor ready!')
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        setDescription(editor.getData())
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Link</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                            <input type="text" value={url} onChange={(e) => setUrl(e.target.value)} name='url' placeholder='Link' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Order</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                            <input type="number" value={order} onChange={(e) => setOrder(e.target.value)} name='order' placeholder='Order' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Color</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                            <input type="text" value={color} onChange={(e) => setColor(e.target.value)} name='color' placeholder='Color' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Employe Type*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <select className="form-control form-control-lg" value={type} onChange={(e)=>setType(e.target.value)}>
                                                                       <option value=''>Select</option>
                                                                       <option value='events'>Events</option>   
                                                                       <option value='news'>News</option>   
                                                                       <option value='different'>Different</option>   
                                                                       <option value='pointer'>Pointer</option>   
                                                                </select>   
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="nk-data data-list">
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <div className="form-control-wrap" style={{ width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                                                                <button className="btn btn-lg btn-primary btn-block" onClick={(e) => handleCreateWhitePaper(e)}>{editCase ? 'Save' : 'Create'}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default CreateInmorphianContent;
import { postMethod } from './Service';
import { webinarUploadMethod } from './WebinarUploadService';
const imageUpload = async (data) => {
    const dataFlag = postMethod('api/aws/imageUpload', data);
    return dataFlag;
}
const imageUpload1 = async (data) => {
    const dataFlag = postMethod('api/aws/imageUpload1', data);
    return dataFlag;
}
const videoUpload = async (data) => {
    const dataFlag = postMethod('api/aws/videoUpload', data);
    return dataFlag;
}

const webinarVideoUpload = async (data) => {
    const dataFlag = webinarUploadMethod('api/aws/imageUpload', data);
    return dataFlag;
}
export { imageUpload, videoUpload, imageUpload1, webinarVideoUpload };
import { postMethod, putMethod, deleteMethod, getMethod} from '../../Utils/Service';
const postAPI=async(data)=>{
      const returnData = await postMethod('api/insightsnewsletters/add', data);
      return returnData;
}
const putAPI=async(id, data)=>{
    const returnData = await putMethod('api/insightsnewsletters/'+id+'/edit', data);
    return returnData;
}
const deleteAPI=async(id)=>{
    const returnData = await deleteMethod('api/insightsnewsletters/'+id+'/delete');
    return returnData;
}
const getAllApi=async(page, limit)=>{
    const returnData = await getMethod('api/insightsnewsletters/all?page='+page+'&limit='+limit);
    return returnData;
}
const getSingleAPI=async(id)=>{
    const returnData = await getMethod(`api/insightsnewsletters/${id}/single`);
    return returnData;
}

export {getSingleAPI, postAPI, putAPI, getAllApi, deleteAPI};
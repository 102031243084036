import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router';
import { dateFormatConverter } from '../../Utils/Logic';
import { deleteWhitePaperAPI, getAllWhitePaperAPI } from './Action';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import toast, { Toaster } from 'react-hot-toast';
import { BASE_IMG_URL2 } from '../../Utils/Service';
import RenderLoader from '../../PanelComponent/RenderLoader';
const Interview = (props) => {
    const history = useHistory();
    const [totalWhitePaper, setTotalWhitePaper] = useState(0);
    const [whitePaperList, setWhitePaperList] = useState([]);
    const [loader, setLoader] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
   const getListAPI = async (limit, page) => {
        await getAllWhitePaperAPI(page, limit).then(response => {
            if(response?.success)
            {
                setWhitePaperList(response.data);
                setTotalWhitePaper(response.total)
                setLoader(false)
            }else{
               setLoader(false)
            }
        })
    };
    const confirmAction = (id, index) => {
        confirmAlert({
            title: 'Confirm Action',
            message: 'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        setLoader(true)
                        await deleteWhitePaperAPI(id).then(response => {
                            if (response?.success === true || response?.status === true) {
                                getListAPI(5,currentIndex)
                                toast.success(response.message);
                                setLoader(false)
                            } else {
                                setLoader(false)
                                toast.error(response.message)
                            }
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }
    useEffect(() => {
        getListAPI(5, 0);
    }, []);
    return (
        <>
            <div className="sideBarUL nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-lg">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">Interview</h4>
                                                    <div className="nk-block-des text-soft">
                                                        {
                                                            totalWhitePaper ?
                                                                <p>You have total {totalWhitePaper === 1 ? totalWhitePaper + ' interview' : totalWhitePaper + ' interviews'}</p>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                loader?
                                                  <RenderLoader trans={true}/>
                                                :null
                                            }
                                            <div style={{ width: '100%' }}>
                                                <div className="nk-block">
                                                    <div className="card card-bordered card-stretch">
                                                        <div className="card-inner-group">
                                                            <div className="card-inner p-0">
                                                                <div className="nk-tb-list nk-tb-ulist">
                                                                    <div className="nk-tb-item nk-tb-head">
                                                                        <div className="nk-tb-col nk-tb-col-check">
                                                                            <span className="sub-text text-center">Banner</span>
                                                                        </div>
                                                                        <div className="nk-tb-col text-center"><span className="sub-text">Title</span></div>
                                                                        <div className="nk-tb-col tb-col-lg"><span className="sub-text text-center">Description</span></div>
                                                                        <div className="nk-tb-col tb-col-md"><span className="sub-text text-center">Created At</span></div>
                                                                        <div className="nk-tb-col nk-tb-col-tools text-right">
                                                                            <span className="sub-text">More</span>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        whitePaperList?.length ?
                                                                            whitePaperList?.map((data, index) =>
                                                                                <div className="nk-tb-item" key={index}>
                                                                                    <div className="nk-tb-col nk-tb-col-check">
                                                                                        <span className="sub-text text-center"><img src={BASE_IMG_URL2+data?.image} className='img-fluid'/></span>
                                                                                    </div>
                                                                                    <div className="nk-tb-col">
                                                                                        <span className="sub-text text-center">{data?.title}</span>
                                                                                    </div>
                                                                                    <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="sub-text text-center">{data?.description}</span>
                                                                                    </div>
                                                                                    <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="sub-text text-center">{dateFormatConverter(data?.createdAt)}</span>
                                                                                    </div>
                                                                                    <div className="nk-tb-col nk-tb-col-tools">
                                                                                        <ul className="nk-tb-actions gx-1">
                                                                                            <li>
                                                                                                <div className="drodown">
                                                                                                    <a href="#" className="dropdown-toggle btn btn-icon btn-trigger" data-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
                                                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                                                        <ul className="link-list-opt no-bdr">
                                                                                                            <li><a onClick={(e) => { history.push('/edit-interview?id='+data?._id) }}><em className="icon ni ni-pen"></em><span>Edit</span></a></li>
                                                                                                            <li><a onClick={(e) => confirmAction(data?._id, index)}><em className="icon ni ni-trash"></em><span>Delete</span></a></li>
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                            : null
                                                                    }
                                                                </div>
                                                                {
                                                                    whitePaperList?.length ?
                                                                        null
                                                                        :
                                                                        <div className='row justify-content-center fullDiv' style={{ marginLeft: '0px' }}>
                                                                            <p className='tb-status text-success sub-text text-center' style={{ fontSize: '16px', fontWeight: '600', lineHeight: '36px' }}>
                                                                                Not Found!
                                                                            </p>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='' style={{ padding: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                {
                                                    Math.round((((Math.round(totalWhitePaper % 5)>0)?1:0)+(parseInt(totalWhitePaper/5))))>1?
                                                        <ReactPaginate
                                                            previousLabel={"Prev"}
                                                            nextLabel={"Next"}
                                                            breakLabel={"..."}
                                                            breakClassName={"break-me"}
                                                            pageCount={Math.round((((Math.round(totalWhitePaper % 5)>0)?1:0)+(parseInt(totalWhitePaper/5))))}
                                                            marginPagesDisplayed={2}
                                                            pageRangeDisplayed={3}
                                                            onPageChange={async (e) => {setLoader(true);getListAPI(5, e.selected); setCurrentIndex(e.selected)}}
                                                            containerClassName={"pagination"}
                                                            subContainerClassName={"pages pagination"}
                                                            activeClassName={"active"} />
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default Interview;

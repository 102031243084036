import React, { useEffect, useState, useRef} from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useHistory } from 'react-router-dom';
import { getSingleAPI, postAPI, putAPI } from './Action';
import { imageUpload } from '../../Utils/upload';
import { BASE_IMG_URL2 } from '../../Utils/Service';
import RenderLoader from '../../PanelComponent/RenderLoader';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const AddAboutColor = (props) => {
    const query = useQuery();
    const history = useHistory();
    const [editCase, setEditCase] = useState(false);
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [profile, setProfile] = useState('');
    const [color, setColor] = useState('');
    const [loader, setLoader] = useState(false)
    const imageRef1 = useRef();
    const handleCreateWebinar = async (e) => {
        e.preventDefault();
        setLoader(true)
        if(name != '' && message != '' && profile != '')
        {
            const data = {
                "title": name,
                "description": message,
                "backgroundImage": profile,
                "color":color,
            }
            if(editCase)
            {
               putAPI(query.get('id'), data).then(response=>{
                    if(response.success)
                    {
                        toast.success(response.message)
                        setEditCase(false)
                        setName('');
                        setColor('')
                        setMessage('');
                        setProfile('');
                        if(imageRef1.current)
                        {
                            imageRef1.current.value = "";
                        }
                        history.push('/about-colors')
                        setLoader(false)
                    }else{
                        toast.error(response.message)
                        setLoader(false)
                    }
               });
            }else{
               postAPI(data).then(response=>{
                      if(response.success)
                      {
                        toast.success(response.message)
                        setName('');
                        setMessage('');
                        setColor('')
                        setProfile('');
                        imageRef1.current.value = "";
                        setLoader(false)
                      }else{
                        toast.error(response.message)
                        setLoader(false)
                      }
               });
            }
        }else{
            toast.error('Please fill all the required fields!');
            setLoader(false)
        }
    }
    useEffect(async () => {
        if (window.location.pathname === '/edit-inmorphis-color' && query.get('id') != '') {
            setLoader(true)
            await getSingleAPI(query.get('id')).then(response => {
                if (response.success) {
                    const data = response?.data;
                    if(data) {
                        setEditCase(true);
                        setName(data?.title?data?.title:'');
                        setMessage(data?.description?data?.description:'');
                        setProfile(data?.backgroundImage?data?.backgroundImage:'');
                        setColor(data?.color?data?.color:'')
                        setLoader(false)
                    }else {
                        history.push('/404');
                    }
                } else {
                     history.push('/404');
                }
            });
        }
    }, [])
    return (
        <>
            <div className="nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-lg">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">{editCase ? 'Edit About Inmorphis Color' : 'Add About Inmorphis Color'}</h4>
                                                </div>
                                            </div>
                                            {
                                                loader?
                                                  <RenderLoader trans={true}/>
                                                  :null
                                            }
                                            <div className="nk-block">
                                            <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Normal Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Title*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                            <div className="form-control-wrap" style={{ width: '450px', height: 'auto' }}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={name}
                                                                    config={{
                                                                        toolbar: ['bold', 'italic', 'blockQuote', 'link', 'bulletedList']
                                                                    }}
                                                                    onReady={editor => {
                                                                        // console.log('editor ready!')
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        setName(editor.getData())
                                                                    }}
                                                                />
                                                            </div>
                                                                {/* <input type="text" value={name} onChange={(e) => setName(e.target.value)} name='name' placeholder='Title' required className="form-control form-control-lg" style={{ width: '100%' }} /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Color*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={color} onChange={(e) => setColor(e.target.value)} name='name' placeholder='Title' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Description*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" name='description' value={message} onChange={(e) => setMessage(e.target.value)} placeholder='Description...' required className="form-control form-control-lg" style={{ width: '100%' }}>

                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Upload Image</h6>
                                                    </div>
                                                        <div className="data-item">
                                                            <div className="data-col">
                                                                <span className="data-label">Background Image*</span>
                                                                {
                                                                    editCase?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                    <img src={BASE_IMG_URL2+profile} className='img-fluid' style={{margin:'15x',padding:'15px'}}/>
                                                                    <input type="file" ref={imageRef1} name='bannerVideo' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                    onChange={async (event)=>{
                                                                        var file = event.target.files[0];
                                                                        var formData = new FormData();
                                                                        formData.append('path', 'about-colors');
                                                                        formData.append('file', file, file.name);
                                                                        setLoader(true)
                                                                        await imageUpload(formData).then(response=>{
                                                                            if(response?.success)
                                                                            {
                                                                                setProfile(response?.aws?.key)
                                                                                setLoader(false)
                                                                                //toast.success(response.message)
                                                                            }else{
                                                                                setLoader(false)
                                                                                toast.error(response.message)
                                                                            }
                                                                        })
                                                                }} />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                    <input type="file" ref={imageRef1} name='bannerVideo' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                    onChange={async (event)=>{
                                                                        var file = event.target.files[0];
                                                                        var formData = new FormData();
                                                                        formData.append('path', 'about-colors');
                                                                        formData.append('file', file, file.name);
                                                                        setLoader(true)
                                                                        await imageUpload(formData).then(response=>{
                                                                            if(response?.success)
                                                                            {
                                                                                setProfile(response?.aws?.key)
                                                                                setLoader(false)
                                                                                //toast.success(response.message)
                                                                            }else{
                                                                                toast.error(response.message)
                                                                                setLoader(false)
                                                                            }
                                                                        })
                                                                }} />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div> 
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <div className="form-control-wrap" style={{ width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                                                                <button className="btn btn-lg btn-primary btn-block" onClick={(e) => handleCreateWebinar(e)}>{editCase ? 'Save' : 'Create'}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default AddAboutColor;
import React from "react";
import { getCookies } from "../Utils/cookies"

const isAdminRole=()=>{
    let isActive = (getCookies('_inmp_admin_token')?true:false);
    if(isActive)
    {
         let isRole = 'admin';
         let finRole = (getCookies('_imp_admin_details')? JSON.parse(getCookies('_imp_admin_details')) :false);
         isRole = finRole?.role ? finRole?.role :false
         return isRole;
    }else{
        return false;
    }
}

export {isAdminRole}
import { postMethod, putMethod, deleteMethod, getMethod} from '../../Utils/Service';
const postWebinarAPI=async(data)=>{
      const returnData = await postMethod('api/insights/webinar/add', data);
      return returnData;
}
const putWebinarAPI=async(id, data)=>{
    const returnData = await putMethod('api/insights/webinar/'+id+'/edit', data);
    return returnData;
}
const deleteWebinarAPI=async(id)=>{
    const returnData = await deleteMethod('api/insights/webinar/'+id+'/delete');
    return returnData;
}
const deleteWebinarUSERAPI=async(id)=>{
    const returnData = await deleteMethod('api/insights/webinarreg/'+id+'/delete');
    return returnData;
}
const getAllWebinarAPI=async(page, limit)=>{
    const returnData = await getMethod('api/insights/webinar/all?page='+page+'&limit='+limit);
    return returnData;
}
const getSingleWebinarAPI=async(id)=>{
    const returnData = await getMethod('api/insights/webinar/'+id+'/single');
    return returnData;
}
const getUserRegWebinarAPI=async(page, limit, id)=>{
    const returnData = await getMethod('api/insights/webinar/'+id+'/get?page='+page+'&limit='+limit);
    return returnData;
}
export {postWebinarAPI, deleteWebinarUSERAPI, putWebinarAPI, deleteWebinarAPI, getAllWebinarAPI, getSingleWebinarAPI, getUserRegWebinarAPI};
import { postMethod, putMethod, deleteMethod, getMethod} from '../../Utils/Service';
const postIndustriesAPI=async(id, data)=>{
      const returnData = await postMethod('api/innovation/'+id+'/industry/add', data);
      return returnData;
}
const putIndustriesAPI=async(appid, id, data)=>{
    const returnData = await putMethod('api/innovation/'+appid+'/industry/'+id+'/edit', data);
    return returnData;
}
const deleteIndustriesAPI=async(appId, id)=>{
    const returnData = await deleteMethod('api/innovation/'+appId+'/industry/'+id+'/delete');
    return returnData;
}
const getAllIndustriesAPI=async(id)=>{
    const returnData = await getMethod('api/innovation/'+id+'/industry/all');
    return returnData;
}
const getSingleInduriesAPI=async(id, appid)=>{
    const returnData = await getMethod('api/innovation/'+appid+'/industry/'+id+'/single');
    return returnData;
}
export {postIndustriesAPI,putIndustriesAPI, getSingleInduriesAPI, deleteIndustriesAPI, getAllIndustriesAPI};
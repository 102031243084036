const dateFormatConverter = (arg) => {
    let date = new Date(arg);
    let datel = date.getUTCDate();
    let monthArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let returnDate = datel + ' ' + monthArray[(date.getUTCMonth())] + ' ' + date.getUTCFullYear()
    return returnDate;
}
function convertDateFomrat(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

function slugify(string) {
    return string
      .toString()
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "")
      .replace(/\-\-+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
  }
export {dateFormatConverter, convertDateFomrat, slugify};
import { postMethod, putMethod, deleteMethod, getMethod} from '../../Utils/Service';
const postAPI=async(data, id)=>{
      const returnData = await postMethod('api/enterpriseService/'+id+'/addSubCat', data);
      return returnData;
}
const putAPI=async(id,subId, data)=>{
    const returnData = await putMethod('api/enterpriseService/'+id+'/'+subId+'/edit', data);
    return returnData;
}
const deleteAPI=async(id, subId)=>{
        const returnData = await deleteMethod('api/enterpriseService/'+id+'/'+subId+'/delete');
        return returnData;
}
const getAllAPI=async(page, limit)=>{
        const returnData = await getMethod('api/enterpriseService?page='+page+'&limit='+limit);
        return returnData;
}
const getSingleAPI=async(id, subId)=>{
        const returnData = await getMethod('api/enterpriseService/'+id+'/'+subId+'/getSubCatInfo');
        return returnData;
}
// const getUserRegWebinarAPI=async(page, limit, id)=>{
//     const returnData = await getMethod('api/insights/webinar/'+id+'/get');
//     return returnData;
// }
export {postAPI, putAPI, deleteAPI, getAllAPI, getSingleAPI};
import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineHome } from 'react-icons/ai';
import { FaMedapps } from 'react-icons/fa';
import { SiConsul } from 'react-icons/si';
import { MdInsights, MdManageAccounts, MdOutlineDocumentScanner, MdOutlineUnsubscribe } from 'react-icons/md';
import {FaStarOfLife} from 'react-icons/fa';
import { CgDetailsMore } from 'react-icons/cg';
const Sidebar = (props) => {
    return (
        <div className="sideBarUL nk-sidebar nk-sidebar-fixed is-dark " data-content="sidebarMenu">
            <div className="nk-sidebar-element nk-sidebar-head" style={{ backgroundColor: '#fff' }}>
                <div className="nk-sidebar-brand" style={{ backgroundColor: '#fff' }}>
                    <Link to="/dashboard" className="logo-link nk-sidebar-logo">
                        <img className="logo-light logo-img" src={"https://inmorphis.sgp1.cdn.digitaloceanspaces.com/website/assets/images/headerlogo.svg"} srcSet={"https://inmorphis.sgp1.cdn.digitaloceanspaces.com/website/assets/images/headerlogo.svg"} alt="logo" />
                        <img className="logo-dark logo-img" src={"https://inmorphis.sgp1.cdn.digitaloceanspaces.com/website/assets/images/headerlogo.svg"} srcSet={"https://inmorphis.sgp1.cdn.digitaloceanspaces.com/website/assets/images/headerlogo.svg"} alt="logo-dark" />
                    </Link>
                </div>
                <div className="nk-menu-trigger mr-n2">
                    <a href="#" className="nk-nav-toggle nk-quick-nav-icon d-xl-none" data-target="sidebarMenu"><em className="icon ni ni-arrow-left"></em></a>
                </div>
            </div>
            <div className="nk-sidebar-element">
                <div className="nk-sidebar-content">
                    <div className="nk-sidebar-menu" data-simplebar>
                        <ul className="nk-menu">
                            <li className="nk-menu-item has-sub">
                                <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                    <span className="nk-menu-text"><AiOutlineHome />Home</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    <li className="nk-menu-item has-sub">
                                        <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                            <span className="nk-menu-text">Home Banners</span>
                                        </a>
                                        <ul className="nk-menu-sub">
                                            <li className="nk-menu-item">
                                                <Link to='/home-banners' className="nk-menu-link"><span className="nk-menu-text">Home Banners</span></Link>
                                            </li>
                                            <li className="nk-menu-item">
                                                <Link to='/create-home-banner' className="nk-menu-link"><span className="nk-menu-text">Create Home Banner</span></Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nk-menu-item has-sub">
                                        <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                            <span className="nk-menu-text">Client Admire</span>
                                        </a>
                                        <ul className="nk-menu-sub">
                                            <li className="nk-menu-item">
                                                <Link to='/client-admire' className="nk-menu-link"><span className="nk-menu-text">Client Admire</span></Link>
                                            </li>
                                            <li className="nk-menu-item">
                                                <Link to='/create-client-admire' className="nk-menu-link"><span className="nk-menu-text">Create Client Admire</span></Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nk-menu-item has-sub">
                                        <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>

                                            <span className="nk-menu-text">Happy Customer</span>
                                        </a>
                                        <ul className="nk-menu-sub">
                                            <li className="nk-menu-item">
                                                <Link to='/happy-customer' className="nk-menu-link"><span className="nk-menu-text">Happy Customers</span></Link>
                                            </li>
                                            <li className="nk-menu-item">
                                                <Link to='/create-happy-customer' className="nk-menu-link"><span className="nk-menu-text">Add Happy Customers</span></Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nk-menu-item has-sub">
                                        <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>

                                            <span className="nk-menu-text">Customer Review</span>
                                        </a>
                                        <ul className="nk-menu-sub">
                                            <li className="nk-menu-item">
                                                <Link to='/customer-review' className="nk-menu-link"><span className="nk-menu-text">Customer Review</span></Link>
                                            </li>
                                            <li className="nk-menu-item">
                                                <Link to='/create-customer-review' className="nk-menu-link"><span className="nk-menu-text">Add Customer Review</span></Link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li className="nk-menu-item has-sub">
                                <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                    <span className="nk-menu-text"><FaMedapps />Innovation App</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    <li className="nk-menu-item has-sub">
                                        <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                            <span className="nk-menu-text">Innovation</span>
                                        </a>
                                        <ul className="nk-menu-sub">
                                            <li className="nk-menu-item">
                                                <Link to='/innovation' className="nk-menu-link"><span className="nk-menu-text">Innovation</span></Link>
                                            </li>
                                            <li className="nk-menu-item">
                                                <Link to='/create-innovation' className="nk-menu-link"><span className="nk-menu-text">Create Innovation</span></Link>
                                            </li>
                                            {/* <li className="nk-menu-item">
                                                <Link to='/industries' className="nk-menu-link"><span className="nk-menu-text">Industries</span></Link>
                                            </li> */}
                                            {/* <li className="nk-menu-item">
                                                <Link to='/create-industries' className="nk-menu-link"><span className="nk-menu-text">Create Industries</span></Link>
                                            </li> */}
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li className="nk-menu-item has-sub">
                                <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>

                                    <span className="nk-menu-text"><SiConsul />Services & Consultant</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    <li className="nk-menu-item">
                                        <Link to='/services' className="nk-menu-link"><span className="nk-menu-text">Services</span></Link>
                                    </li>
                                    <li className="nk-menu-item">
                                        <Link to='/create-services' className="nk-menu-link"><span className="nk-menu-text">Create Services</span></Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nk-menu-item has-sub">
                                <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>

                                    <span className="nk-menu-text"><MdInsights />Insights</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    <li className="nk-menu-item has-sub">
                                        <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                            {/* */}
                                            <span className="nk-menu-text">Case Study</span>
                                        </a>
                                        <ul className="nk-menu-sub">
                                            <li className="nk-menu-item">
                                                <Link to='/case-study' className="nk-menu-link"><span className="nk-menu-text">Case Study</span></Link>
                                            </li>
                                            <li className="nk-menu-item">
                                                <Link to='/create-case-study' className="nk-menu-link"><span className="nk-menu-text">Create Case Study</span></Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nk-menu-item has-sub">
                                        <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                            {/* */}
                                            <span className="nk-menu-text">Webinar</span>
                                        </a>
                                        <ul className="nk-menu-sub">
                                            <li className="nk-menu-item">
                                                <Link to='/webinar' className="nk-menu-link"><span className="nk-menu-text">Webinar</span></Link>
                                            </li>
                                            <li className="nk-menu-item">
                                                <Link to='/create-webinar' className="nk-menu-link"><span className="nk-menu-text">Create Webinar</span></Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nk-menu-item has-sub">
                                        <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                            {/* */}
                                            <span className="nk-menu-text">White Paper</span>
                                        </a>
                                        <ul className="nk-menu-sub">
                                            <li className="nk-menu-item">
                                                <Link to='/whitepaper' className="nk-menu-link"><span className="nk-menu-text">White Paper</span></Link>
                                            </li>
                                            <li className="nk-menu-item">
                                                <Link to='/create-whitepaper' className="nk-menu-link"><span className="nk-menu-text">Create White Paper</span></Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nk-menu-item has-sub">
                                        <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                            {/* */}
                                            <span className="nk-menu-text">Blogs</span>
                                        </a>
                                        <ul className="nk-menu-sub">
                                            <li className="nk-menu-item">
                                                <Link to='/blogs' className="nk-menu-link"><span className="nk-menu-text">Blogs</span></Link>
                                            </li>
                                            <li className="nk-menu-item">
                                                <Link to='/create-blogs' className="nk-menu-link"><span className="nk-menu-text">Create Blogs</span></Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nk-menu-item has-sub">
                                        <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                            {/* */}
                                            <span className="nk-menu-text">Interview</span>
                                        </a>
                                        <ul className="nk-menu-sub">
                                            <li className="nk-menu-item">
                                                <Link to='/interview' className="nk-menu-link"><span className="nk-menu-text">Interview</span></Link>
                                            </li>
                                            <li className="nk-menu-item">
                                                <Link to='/create-interview' className="nk-menu-link"><span className="nk-menu-text">Create Interview</span></Link>
                                            </li>
                                        </ul>
                                    </li>


                                    <li className="nk-menu-item has-sub">
                                        <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                            <span className="nk-menu-text">Newsletter</span>
                                        </a>
                                        <ul className="nk-menu-sub">
                                            <li className="nk-menu-item">
                                                <Link to='/insight-newsletter' className="nk-menu-link"><span className="nk-menu-text">Newsletter</span></Link>
                                            </li>
                                            <li className="nk-menu-item">
                                                <Link to='/create-insight-newsletter' className="nk-menu-link"><span className="nk-menu-text">Create Newsletter</span></Link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>

                            <li className="nk-menu-item has-sub">
                                <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                    <span className="nk-menu-text"><FaStarOfLife />Life Of Inmorphian</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    <li className="nk-menu-item has-sub">
                                        <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                            {/* */}
                                            <span className="nk-menu-text">Testmonial/Stories</span>
                                        </a>
                                        <ul className="nk-menu-sub">
                                            <li className="nk-menu-item">
                                                <Link to='/inmorphian' className="nk-menu-link"><span className="nk-menu-text">Inmorphian List</span></Link>
                                            </li>
                                            <li className="nk-menu-item">
                                                <Link to='/create-inmorphian' className="nk-menu-link"><span className="nk-menu-text">Create Inmorphian</span></Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nk-menu-item has-sub">
                                        <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                            {/* */}
                                            <span className="nk-menu-text">Gallery</span>
                                        </a>
                                        <ul className="nk-menu-sub">
                                            <li className="nk-menu-item">
                                                <Link to='/gallery-list' className="nk-menu-link"><span className="nk-menu-text">Gallery List</span></Link>
                                            </li>
                                            <li className="nk-menu-item">
                                                <Link to='/create-gallery' className="nk-menu-link"><span className="nk-menu-text">Create Gallery</span></Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nk-menu-item has-sub">
                                        <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                            {/* */}
                                            <span className="nk-menu-text">Other Section</span>
                                        </a>
                                        <ul className="nk-menu-sub">
                                            <li className="nk-menu-item">
                                                <Link to='/inmorphian-content' className="nk-menu-link"><span className="nk-menu-text">List</span></Link>
                                            </li>
                                            <li className="nk-menu-item">
                                                <Link to='/create-inmorphian-content' className="nk-menu-link"><span className="nk-menu-text">Create</span></Link>
                                            </li>
                                        </ul>
                                    </li>
                                 
                                </ul>
                            </li>

                            
                            <li className="nk-menu-item has-sub">
                                <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                    <span className="nk-menu-text"><CgDetailsMore />About</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    <li className="nk-menu-item has-sub">
                                        <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                            <span className="nk-menu-text">Who We Are</span>
                                        </a>
                                        <ul className="nk-menu-sub">
                                            <li className="nk-menu-item">
                                                <Link to='/who-we-are' className="nk-menu-link"><span className="nk-menu-text">Who We Are</span></Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nk-menu-item has-sub">
                                        <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                            <span className="nk-menu-text">Core Value</span>
                                        </a>
                                        <ul className="nk-menu-sub">
                                            <li className="nk-menu-item">
                                                <Link to='/core-value' className="nk-menu-link"><span className="nk-menu-text">Core Value</span></Link>
                                            </li>
                                            <li className="nk-menu-item">
                                                <Link to='/create-core-value' className="nk-menu-link"><span className="nk-menu-text">Create Core Value</span></Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nk-menu-item has-sub">
                                        <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                            <span className="nk-menu-text">Leaders</span>
                                        </a>
                                        <ul className="nk-menu-sub">
                                            <li className="nk-menu-item">
                                                <Link to='/leader' className="nk-menu-link"><span className="nk-menu-text">Leaders</span></Link>
                                            </li>
                                            <li className="nk-menu-item">
                                                <Link to='/add-leader' className="nk-menu-link"><span className="nk-menu-text">Add Leader</span></Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nk-menu-item has-sub">
                                        <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                            <span className="nk-menu-text">About Inmorphis Colors</span>
                                        </a>
                                        <ul className="nk-menu-sub">
                                            <li className="nk-menu-item">
                                                <Link to='/about-colors' className="nk-menu-link"><span className="nk-menu-text">Inmorphis Colors</span></Link>
                                            </li>
                                            <li className="nk-menu-item">
                                                <Link to='/add-inmorphis-color' className="nk-menu-link"><span className="nk-menu-text">Add Inmorphis Color</span></Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nk-menu-item has-sub">
                                        <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                            <span className="nk-menu-text">Awards and Newsroom</span>
                                        </a>
                                        <ul className="nk-menu-sub">
                                            <li className="nk-menu-item">
                                                <Link to='/awards' className="nk-menu-link"><span className="nk-menu-text">Awards</span></Link>
                                            </li>
                                            <li className="nk-menu-item">
                                                <Link to='/add-awards' className="nk-menu-link"><span className="nk-menu-text">Add Awards</span></Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nk-menu-item has-sub">
                                        <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                            <span className="nk-menu-text">Social Impact</span>
                                        </a>
                                        <ul className="nk-menu-sub">
                                            <li className="nk-menu-item">
                                                <Link to='/csr' className="nk-menu-link"><span className="nk-menu-text">CSR LIST</span></Link>
                                            </li>
                                            <li className="nk-menu-item">
                                                <Link to='/create-csr' className="nk-menu-link"><span className="nk-menu-text">Add CSR</span></Link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li className="nk-menu-item has-sub">
                                <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                    <span className="nk-menu-text"><MdOutlineUnsubscribe />Subscribers</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    <li className="nk-menu-item has-sub">
                                        <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                            {/* */}
                                            <span className="nk-menu-text">Subscribers</span>
                                        </a>
                                        <ul className="nk-menu-sub">
                                            <li className="nk-menu-item">
                                                <Link to='/subscribers' className="nk-menu-link"><span className="nk-menu-text">List</span></Link>
                                            </li>
                                        </ul>
                                    </li>
                                   

                                </ul>
                            </li>
                            <li className="nk-menu-item has-sub">
                                <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                    <span className="nk-menu-text"><MdOutlineDocumentScanner />Documents</span>
                                </a>
                                <ul className="nk-menu-sub">
                                    <li className="nk-menu-item has-sub">
                                        <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                            {/* */}
                                            <span className="nk-menu-text">Documents</span>
                                        </a>
                                        <ul className="nk-menu-sub">
                                            <li className="nk-menu-item">
                                                <Link to='/documents' className="nk-menu-link"><span className="nk-menu-text">Documents</span></Link>
                                            </li>
                                            <li className="nk-menu-item">
                                                <Link to='/create-document' className="nk-menu-link"><span className="nk-menu-text">Create Document</span></Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nk-menu-item has-sub">
                                        <a href="#" className="nk-menu-link nk-menu-toggle" style={{ textDecoration: 'none' }}>
                                            {/* */}
                                            <span className="nk-menu-text">Document Category</span>
                                        </a>
                                        <ul className="nk-menu-sub">
                                            <li className="nk-menu-item">
                                                <Link to='/document-category' className="nk-menu-link"><span className="nk-menu-text">Document Category</span></Link>
                                            </li>
                                            <li className="nk-menu-item">
                                                <Link to='/create-document-category' className="nk-menu-link"><span className="nk-menu-text">Create Document Category</span></Link>
                                            </li>
                                        </ul>
                                    </li>

                                </ul>
                            </li>
                            <li className="nk-menu-item">
                                <Link to="/account-setting" className="nk-menu-link" style={{ textDecoration: 'none' }}>
                                    {/* <span className="nk-menu-icon"><em className="icon ni ni-user"></em></span> */}
                                    <span className="nk-menu-text"><MdManageAccounts />Account Setting</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Sidebar;
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { setCookies, getCookies } from "../../Utils/cookies";
const Topbar = (props) => {
    const history = useHistory();
    const [profileDetails, setProfileDetails] = useState({firstName:'I',lastName:'A',email:''});
    const handleLogout=async(e)=>{
        e.preventDefault();
        setCookies('_inmp_admin_token','');
        setCookies('_imp_admin_details','');
        history.push('/');
    }
    useEffect(()=>{
        if(getCookies('_imp_admin_details'))
        {
            setProfileDetails(JSON.parse(getCookies('_imp_admin_details')))   
        }
    },[])
    return (
        <div className="sideBarUL nk-header nk-header-fixed is-light">
            <div className="container-fluid">
                <div className="nk-header-wrap">
                    <div className="nk-menu-trigger d-xl-none ml-n1">
                        <a href="#" className="nk-nav-toggle nk-quick-nav-icon" data-target="sidebarMenu"><em className="icon ni ni-menu"></em></a>
                    </div>
                    <div className="nk-header-brand d-xl-none">
                        <Link to="/dashboard" className="logo-link">
                            <img className="logo-light logo-img" src={"https://inmorphis.sgp1.cdn.digitaloceanspaces.com/website/assets/images/headerlogo.svg"} srcSet={"https://inmorphis.sgp1.cdn.digitaloceanspaces.com/website/assets/images/headerlogo.svg"} alt="logo" />
                            <img className="logo-dark logo-img" src={"https://inmorphis.sgp1.cdn.digitaloceanspaces.com/website/assets/images/headerlogo.svg"} srcSet={"https://inmorphis.sgp1.cdn.digitaloceanspaces.com/website/assets/images/headerlogo.svg"} alt="logo-dark" />
                        </Link>
                    </div>
                    <div className="nk-header-tools">
                        <ul className="nk-quick-nav">
                            <li className="dropdown user-dropdown">
                                <a href="#" style={{textDecoration:'none'}} className="dropdown-toggle" data-toggle="dropdown">
                                    <div className="user-toggle">
                                        <div className="user-avatar sm">
                                            <span style={{textTransform:'uppercase'}}>{profileDetails?.firstName[0]+profileDetails?.lastName[0]}</span>
                                        </div>
                                        <div className="user-info d-none d-md-block">
                                            <div className="user-status" style={{textTransform:'capitalize'}}>{profileDetails?.role}</div>
                                            <div className="user-name dropdown-indicator">{profileDetails?.firstName+' '+profileDetails?.lastName}</div>
                                        </div>
                                    </div>
                                </a>
                                <div className="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1">
                                    <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                        <div className="user-card">
                                            <div className="user-avatar">
                                            <span style={{textTransform:'uppercase'}}>{profileDetails?.firstName[0]+profileDetails?.lastName[0]}</span>
                                            </div>
                                            <div className="user-info">
                                                <span className="lead-text">{profileDetails?.firstName+' '+profileDetails?.lastName}</span>
                                                <span className="sub-text">{profileDetails?.email}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dropdown-inner">
                                        <ul className="link-list">
                                            <li><Link to="/account-setting"><em className="icon ni ni-setting-alt"></em><span>Account Setting</span></Link></li>
                                        </ul>
                                    </div>
                                    <div className="dropdown-inner">
                                        <ul className="link-list">
                                            <li><a style={{cursor:'pointer'}} onClick={(e)=>handleLogout(e)}><em className="icon ni ni-signout"></em><span>Sign out</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Topbar;
import React, { useEffect, useState } from 'react';
import { getUserRegWebinarAPI, deleteWebinarUSERAPI } from './Action';
import { useLocation, useHistory } from 'react-router-dom';
import { dateFormatConverter } from '../../Utils/Logic';
import ReactPaginate from 'react-paginate';
import RenderLoader from '../../PanelComponent/RenderLoader';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import toast, { Toaster } from 'react-hot-toast';
import { ExportToExcel } from '../../Utils/ExportToExcel';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const RegisterUserWebinar = () => {
    const query = useQuery();
    const history = useHistory();
    const [totalWebinar, setTotalWebinar] = useState(0);
    const [webinarList, setWebinarList] = useState([]);
    const [loader, setLoader] = useState(true);
    const [paginationIndex, setPaginationIndex] = useState(0);
    const getListAPI = async (limit, page) => {
        await getUserRegWebinarAPI(page, limit, query.get('id')).then(response => {
            if (response.success) {
                setWebinarList(response.data);
                setTotalWebinar(response.total)
                setLoader(false)
            } else {
                setLoader(false)
            }
        })
    };
    const confirmAction = (id, index) => {
        confirmAlert({
            title: 'Confirm Action',
            message: 'Are you sure you want to delete',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        setLoader(true)
                        await deleteWebinarUSERAPI(id).then(response => {
                            if (response?.success === true || response?.status === true) {
                                getListAPI(5, paginationIndex)
                                toast.success(response.message);
                                setLoader(false)
                            } else {
                                toast.error(response.message)
                                setLoader(false)
                            }
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }
    useEffect(() => {
        getListAPI(5, 0);
    }, [])
    return (
        <>
            <div className="sideBarUL nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-sm">
                                                <div className="nk-block-between">
                                                    <div className="nk-block-head-content">
                                                        <h4 className="nk-block-title">Register User For Webinar</h4>
                                                        <div className="nk-block-des text-soft">
                                                            {
                                                                totalWebinar ?
                                                                    <p>You have total {totalWebinar === 1 ? totalWebinar + ' user register' : totalWebinar + ' users register'}</p>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="toggle-wrap nk-block-tools-toggle">
                                                        <a href="#" className="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em className="icon ni ni-menu-alt-r"></em></a>
                                                        <div className="toggle-expand-content" data-content="pageMenu">
                                                            <ul className="nk-block-tools g-3">
                                                                <li>
                                                                    {
                                                                        webinarList?.length ?
                                                                            <ExportToExcel apiData={webinarList} fileName={"inmorphis-register-user-" + (paginationIndex + 1)} />
                                                                            : null
                                                                    }
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                loader ?
                                                    <RenderLoader trans={true} />
                                                    : null
                                            }
                                            <div style={{ width: '100%' }}>
                                                <div className="nk-block">
                                                    <div className="card card-bordered card-stretch">
                                                        <div className="card-inner-group">
                                                            <div className="card-inner p-0">
                                                                <div className="nk-tb-list nk-tb-ulist">
                                                                    <div className="nk-tb-item nk-tb-head">
                                                                        <div className="nk-tb-col nk-tb-col-check">
                                                                            <span className="sub-text text-center">S.No</span>
                                                                        </div>
                                                                        <div className="nk-tb-col text-center"><span className="sub-text">Name</span></div>
                                                                        <div className="nk-tb-col tb-col-lg"><span className="sub-text text-center">Email</span></div>
                                                                        <div className="nk-tb-col tb-col-lg"><span className="sub-text text-center">Company</span></div>
                                                                        <div className="nk-tb-col tb-col-md"><span className="sub-text text-center">Created At</span></div>
                                                                        <div className="nk-tb-col nk-tb-col-tools text-right">
                                                                            <span className="sub-text">More</span>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        webinarList?.length ?
                                                                            webinarList?.map((data, index) =>
                                                                                <div className="nk-tb-item" key={index}>
                                                                                    <div className="nk-tb-col nk-tb-col-check">
                                                                                        <span className="sub-text text-center">{index + 1}</span>
                                                                                    </div>
                                                                                    <div className="nk-tb-col">
                                                                                        <span className="sub-text text-center">{data?.firstName + ' ' + data?.lastName}</span>
                                                                                    </div>
                                                                                    <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="sub-text text-center">{data?.businessEmail}</span>
                                                                                    </div>
                                                                                    <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="sub-text text-center">{data?.company}</span>
                                                                                    </div>
                                                                                    <div className="nk-tb-col tb-col-md">
                                                                                        <span className="tb-status sub-text text-center">{dateFormatConverter(data?.createdAt)}</span>
                                                                                    </div>
                                                                                    <div className="nk-tb-col nk-tb-col-tools">
                                                                                        <ul className="nk-tb-actions gx-1">
                                                                                            <li>
                                                                                                <div className="drodown">
                                                                                                    <a href="#" className="dropdown-toggle btn btn-icon btn-trigger" data-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
                                                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                                                        <ul className="link-list-opt no-bdr">
                                                                                                            <li><a onClick={(e) => confirmAction(data?._id, index)}><em className="icon ni ni-trash"></em><span>Delete</span></a></li>
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                            : null
                                                                    }
                                                                </div>
                                                                {
                                                                    webinarList?.length ?
                                                                        null
                                                                        :
                                                                        <div className='row justify-content-center fullDiv' style={{ marginLeft: '0px' }}>
                                                                            <p className='tb-status text-success sub-text text-center' style={{ fontSize: '16px', fontWeight: '600', lineHeight: '36px' }}>
                                                                                Not Found!
                                                                            </p>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='' style={{ padding: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                {
                                                    Math.round((((Math.round(totalWebinar % 5)>0)?1:0)+(parseInt(totalWebinar/5)))) > 1 ?
                                                        <ReactPaginate
                                                            previousLabel={"Prev"}
                                                            nextLabel={"Next"}
                                                            breakLabel={"..."}
                                                            breakClassName={"break-me"}
                                                            pageCount={Math.round((((Math.round(totalWebinar % 5)>0)?1:0)+(parseInt(totalWebinar/5))))}
                                                            marginPagesDisplayed={2}
                                                            pageRangeDisplayed={3}
                                                            onPageChange={async (e) => { setLoader(true); getListAPI(5, e.selected); setPaginationIndex(e.selected) }}
                                                            containerClassName={"pagination"}
                                                            subContainerClassName={"pages pagination"}
                                                            activeClassName={"active"} />
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default RegisterUserWebinar;
import React, { useState } from 'react';
import Avatar from 'react-avatar-edit';
import {MdModeEdit} from 'react-icons/md';
const Testing = (props) => {
    const [avatarSrc, setAvatarSrc] = useState('');
    const [previousImage, setPreviousImage] = useState(props?.previousImage ? props?.previousImage : '')
    const [cancelPrevousImage, setCancelPreviusImage] = useState(props?.previousImage ? props?.previousImage : '')
    return (
        <div className='container-fluid' style={{ padding: '15px' }}>
            <div className='container' style={{ border: 'solid #757575 3px', padding: '15px', borderRadius: '15px',display:'flex',justifyContent:'center',alignItems:'center',position:'relative'}}>
                {
                    previousImage ?
                        <div className='row justify-content-center' style={{ width: '100%', margin: '0px' }}>
                            <div className='col-md-12 col-12' style={{ border: 'solid #e9e9e9 1px', background: '#e9e9e9', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {
                                    previousImage ?
                                        <img src={previousImage} className='img-fluid' />
                                        : null
                                }
                            </div>
                        </div>
                        :
                        <div className='row justify-content-center' style={{ width: '100%', margin: '0px' }}>
                            <div className='col-md-6 col-12 reDesignImages' style={{ padding: '0px' }}>
                                <Avatar
                                    width={'100%'}
                                    className='avatarEditing'
                                    height={400}
                                    label='Select Image'
                                    exportQuality={1.0}
                                    style={{ border: 'solid black 1px' }}
                                    onCrop={(e) => setAvatarSrc(e)}
                                    onClose={(e) => setAvatarSrc('')}
                                />
                            </div>
                            <div className='col-md-6 col-12' style={{ border: 'solid #e9e9e9 1px', background: '#e9e9e9', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {
                                    avatarSrc ?
                                        <img src={avatarSrc} className='img-fluid' />
                                        : null
                                }
                            </div>
                            <div className='col-md-12 buuttonStyles' style={{padding:'15px',border:'solid red 1px',display:'flex',justifyContent:'space-evenly',alignItems:'center'}}>
                                 <button className='cancel' onClick={(e)=>{setPreviousImage(cancelPrevousImage)}}>Cancel</button>
                                 <button onClick={(e)=>{onSaveClick(avatarSrc)}}>Save</button>
                            </div>
                        </div>
                }
                {
                    previousImage?
                        <button style={{border:'solid #757575 1px',display:'flex',width:'45px',height:'45px',justifyContent:'center',alignItems:'center',borderRadius:'5px',position:'absolute'}}><MdModeEdit style={{width:'35px',height:'35px'}}/></button>
                    :null
                }
            </div>
        </div>
    )
}
export default Testing;
import Axios from 'axios';
import { getCookies } from './cookies';
const AxiosInstance = Axios.create({
    baseURL: process.env.REACT_APP_MAIN_API_URL,
    timeout: 1800000,
    headers: {
        Authorization: 'Bearer ' + getCookies('_inmp_admin_token')
    },
});

const webinarUploadMethod = async (endpoint, data) => {
    return new Promise(resolve => {
        var config = {
            method: 'post',
            url: endpoint,
            data: data
        };
        AxiosInstance(config).then(response => {
            resolve(response.data);
        }, error => {
            resolve(error.response.data);
        })
    });
}




export { webinarUploadMethod };

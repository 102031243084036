import React, { useState, useEffect } from 'react';
import Avatar from 'react-avatar-edit';
import { MdModeEdit } from 'react-icons/md';
const AvatarProfile = (props) => {
    const [avatarSrc, setAvatarSrc] = useState('');
    const [previousImage, setPreviousImage] = useState(props?.previousImage ? props?.previousImage : '')
    const [cancelPrevousImage, setCancelPreviusImage] = useState(props?.previousImage ? props?.previousImage : '')
    const [mimeType, setMimeType] = useState('image/jpeg');
    useEffect(() => {
        setPreviousImage(props?.previousImage);
        setCancelPreviusImage(props?.previousImage);
        if(props?.reset)
        {
            setPreviousImage('');
            setCancelPreviusImage('');
            setAvatarSrc('');
        }
        return () => {

        };
    }, [props]);
    return (
        <div className='container-fluid' style={{ padding: '15px' }}>
            <div className='container' style={{ border: 'solid #757575 3px', padding: '15px', borderRadius: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                {
                    previousImage ?
                        <div className='row justify-content-center' style={{ width: '100%', margin: '0px' }}>
                            <div className='col-md-12 col-12' style={{ border: 'solid #e9e9e9 1px', background: '#e9e9e9', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {
                                    previousImage ?
                                        <img src={previousImage} className='img-fluid' />
                                        : null
                                }
                            </div>
                        </div>
                        :
                        <div className='row justify-content-center' style={{ width: '100%', margin: '0px' }}>
                            <div className='col-md-6 col-12 reDesignImages' style={{ padding: '0px' }}>
                                <Avatar
                                    width={'100%'}
                                    className='avatarEditing'
                                    height={400}
                                    label='Select Image'
                                    mimeTypes='image/jpeg,image/png'
                                    onFileLoad={(e) => { setMimeType(e.type) }}
                                    exportQuality={1.0}
                                    exportMimeType={mimeType}
                                    backgroundColor='transparent'
                                    onCrop={(e) => setAvatarSrc(e)}
                                    onClose={(e) => setAvatarSrc('')}
                                />
                            </div>
                            <div className='col-md-6 col-12' style={{ border: 'solid #e9e9e9 1px', background: '#e9e9e9', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {
                                    avatarSrc ?
                                        <img src={avatarSrc} className='img-fluid' />
                                        : null
                                }
                            </div>
                            <div className='col-md-12 buuttonStyles' style={{ padding: '15px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                {
                                    props?.previousImage ?
                                        <button className='cancel' onClick={(e) => { setPreviousImage(cancelPrevousImage) }}>Cancel</button>
                                        : null
                                }

                                <button onClick={(e) => { props?.onProfileChange(avatarSrc, mimeType) }}>Save</button>
                            </div>
                        </div>
                }
                {
                    previousImage ?
                        <button onClick={(e) => setPreviousImage('')} style={{ border: 'solid #757575 1px', display: 'flex', width: '45px', height: '45px', justifyContent: 'center', alignItems: 'center', borderRadius: '5px', position: 'absolute', top: '15px', right: '15px' }}><MdModeEdit style={{ width: '35px', height: '35px' }} /></button>
                        : null
                }
            </div>
        </div>
    )
}
export default AvatarProfile;
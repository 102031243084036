import { postMethod, putMethod, deleteMethod, getMethod} from '../../Utils/Service';
const postWhitePaperAPI=async(data)=>{
      const returnData = await postMethod('api/corevalue/add', data);
      return returnData;
}
const putWhitePaperAPI=async(id, data)=>{
    const returnData = await putMethod('api/corevalue/'+id+'/edit', data);
    return returnData;
}
const deleteWhitePaperAPI=async(id)=>{
    const returnData = await deleteMethod('api/corevalue/'+id+'/delete');
    return returnData;
}
const getAllWhitePaperAPI=async(page, limit)=>{
    const returnData = await getMethod('api/corevalue/all?page='+page+'&limit='+limit);
    return returnData;
}
const getSingleWhitePaperAPI=async(id)=>{
    const returnData = await getMethod('api/corevalue/'+id+'/single');
    return returnData;
}
export {postWhitePaperAPI, putWhitePaperAPI, deleteWhitePaperAPI, getAllWhitePaperAPI, getSingleWhitePaperAPI};
import React from 'react';
import { postMethod, putMethod, deleteMethod, getMethod} from '../../Utils/Service';
const getCaseStudyListAPI=async(limit, page)=>{
    const returnData = await getMethod('api/insights/casestudy/all?page='+page+'&limit='+limit);
    return returnData;
}

const getSingleCaseStudyListAPI=async(id)=>{
    const returnData = await getMethod('api/insights/casestudy/'+id+'/single');
    return returnData;
}

const postCreateCaseStudyAPI=async(data)=>{
    const returnData = await postMethod('api/insights/casestudy/add', data)
    return returnData;
}
const putEditCaseStudyAPI=async(id, data)=>{
    const returnData = await putMethod('api/insights/casestudy/'+id+'/edit', data)
    return returnData;
}
const deleteCaseStudyAPI=async(id)=>{
    const returnData = await deleteMethod('api/insights/casestudy/'+id+'/delete');
    return returnData;
}

export {getCaseStudyListAPI, getSingleCaseStudyListAPI, postCreateCaseStudyAPI, putEditCaseStudyAPI, deleteCaseStudyAPI}
import React, { useEffect, useState, useRef } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useHistory } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { BASE_IMG_URL2 } from '../../Utils/Service';
import { imageUpload } from '../../Utils/upload';
import { postWhitePaperAPI, putWhitePaperAPI, getSingleWhitePaperAPI } from './Action';
import RenderLoader from '../../PanelComponent/RenderLoader';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const CreateClientAdmire = (props) => {
    const query = useQuery();
    const history = useHistory();
    const [editCase, setEditCase] = useState(false);
    const [title, setTitle] = useState('');
    const [shortDescription, setshortDescription] = useState('');
    const [btnUrl, setBtnUrl] = useState(' ');
    const [image, setImage] = useState('');
    const [image2, setImage2] = useState('');

    const [loader, setLoader] = useState(false)

    const imageRef1 = useRef();

    const imageRef2 = useRef();
    const verifyUrlIsImage = (url) => {
        return (url.match(/\.(jpeg|avif|jfif|pjpeg|pjp|apng|jpg|gif|webp|svg|bmp|ico|tif|tiff|cur|png)$/) != null);
    }
    const handleCreateWhitePaper = async (e) => {
        e.preventDefault();
        setLoader(true)
        if (title != '' && shortDescription != '' && image != '' && image2 != '') {
            const data = {
                "title": title,
                "desImage": image,
                "description": shortDescription,
                "btnUrl": btnUrl,
                "mobImage": image2
            }
            if (editCase) {
                await putWhitePaperAPI(query.get('id'), data).then(response => {
                    if (response.success) {
                        toast.success(response.message);
                        setEditCase(false)
                        setTitle('');
                        setshortDescription('');
                        setImage('');
                        setImage2('');
                        setBtnUrl(' ');
                        if (imageRef1.current) {
                            imageRef1.current.value = "";
                        }
                        if (imageRef2.current) {
                            imageRef2.current.value = "";
                        }

                        history.push('/client-admire');
                        setLoader(false)
                    } else {
                        toast.error(response.message)
                        setLoader(false)
                    }
                });
            } else {
                await postWhitePaperAPI(data).then(response => {
                    if (response.success) {
                        toast.success(response.message);
                        setTitle('');
                        setshortDescription('');
                        setImage('');
                        setBtnUrl(' ');
                        setImage2('')
                        imageRef2.current.value = '';
                        imageRef1.current.value = "";
                        setLoader(false)
                    } else {
                        toast.error(response.message)
                        setLoader(false)
                    }
                });
            }
        } else {
            toast.error('Please fill all required fields!')
            setLoader(false)
        }
    }
    useEffect(async () => {
        if (window.location.pathname === '/edit-client-admire' && query.get('id') != '') {
            setLoader(true)
            await getSingleWhitePaperAPI(query.get('id')).then(response => {
                if (response.success) {
                    const data = response.data;
                    if (data) {
                        setTitle(data?.title ? data?.title : '');
                        setshortDescription(data?.description ? data?.description : '');
                        setImage(data?.desImage ? data?.desImage : '');
                        setImage2(data?.mobImage ? data?.mobImage : '');
                        setBtnUrl(data?.btnUrl ? data?.btnUrl : '');
                        setEditCase(true);
                        setLoader(false)
                    } else {
                        history.push('/404')
                    }
                } else {
                    history.push('/404')
                }
            });
        }
    }, [])
    return (
        <>
            <div className="nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-lg">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">{editCase ? 'Edit Client Admire Section' : 'Create Client Admire Section'}</h4>
                                                </div>
                                            </div>
                                            {
                                                loader ?
                                                    <RenderLoader trans={true} />
                                                    : null
                                            }
                                            <div className="nk-block">
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Normal Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Title*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={title}
                                                                    config={{
                                                                        toolbar: ['bold', 'italic', 'blockQuote']
                                                                    }}
                                                                    onReady={editor => {
                                                                        // console.log('editor ready!')
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        setTitle(editor.getData())
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Description*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={shortDescription}
                                                                    config={{
                                                                        toolbar: ['bold', 'italic', 'blockQuote',]
                                                                    }}
                                                                    onReady={editor => {
                                                                        // console.log('editor ready!')
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        setshortDescription(editor.getData())
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Button Url*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={btnUrl} onChange={(e) => setBtnUrl(e.target.value)} name='mainTitle' placeholder='https://example.com' required className="form-control form-control-lg" style={{ width: '100%', textTransform: 'lowercase' }} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Upload</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Desktop Banner Video/Image*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        {
                                                                            verifyUrlIsImage(image) ?
                                                                                <img src={BASE_IMG_URL2 + image} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                                :
                                                                                <video controls className='img-fluid'>
                                                                                    <source src={BASE_IMG_URL2 + image} type="video/mp4" />Sorry, your browser doesn't support embedded videos.
                                                                                </video>
                                                                        }
                                                                        <input type="file" name='image' ref={imageRef1} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'client-admire');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" name='image' ref={imageRef1} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'client-admire');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Mobile Banner*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <img src={BASE_IMG_URL2 + image2} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                        <input type="file" name='image' ref={imageRef2} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'client-admire');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setImage2(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" name='image' ref={imageRef2} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'client-admire');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setImage2(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <div className="form-control-wrap" style={{ width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                                                                <button className="btn btn-lg btn-primary btn-block" onClick={(e) => handleCreateWhitePaper(e)}>{editCase ? 'Save' : 'Create'}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default CreateClientAdmire;
import React, { useEffect, useState, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useHistory } from 'react-router-dom';
import { getSingleAPI, postAPI, putAPI } from './Action';
import { getAllAPI } from '../DocumentCategory/Action';
import { imageUpload } from '../../Utils/upload';
import { BASE_IMG_URL2 } from '../../Utils/Service';
import { slugify } from '../../Utils/Logic';
import RenderLoader from '../../PanelComponent/RenderLoader';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const CreateDocument = (props) => {
    const query = useQuery();
    const history = useHistory();
    const [editCase, setEditCase] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [slug, setSlug] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [fileUrl, setFileUrl] = useState('');
    const [loader, setLoader] = useState(false);
    const imageRef1 = useRef();
    const [categoryList, setCategoryList] = useState([]);
    const handleCreateWebinar = async (e) => {
        e.preventDefault();
        setLoader(true)
        if (name != '' && description != '' && slug != '' && metaTitle != '' && metaDescription != '' && categoryId != '' && fileUrl != '') {
            const data = {
                "name": name,
                "description": description,
                "slug": slug,
                "metaTitle": metaTitle,
                "metaDescription": metaDescription,
                "catgeoryId": categoryId,
                "file": fileUrl,
            }
            if (editCase) {
                putAPI(query.get('id'), data).then(response => {
                    if (response.success) {
                        toast.success(response.message)
                        setEditCase(false)
                        setName('');
                        setDescription('');
                        setSlug('');
                        setMetaTitle('')
                        setMetaDescription('');
                        setCategoryId('');
                        setFileUrl('');
                        if(imageRef1.current)
                        {
                            imageRef1.current.value = "";
                        }
                        history.push('/documents')
                    } else {
                        toast.error(response.message)
                        setLoader(false)
                    }
                });
            } else {
                postAPI(data).then(response => {
                    if (response.success) {
                        toast.success(response.message)
                        setName('');
                        setDescription('');
                        setSlug('');
                        setMetaTitle('')
                        setMetaDescription('');
                        setCategoryId('');
                        setFileUrl('');
                        imageRef1.current.value = "";
                        setLoader(false)
                    } else {
                        toast.error(response.message)
                        setLoader(false)
                    }
                });
            }
        } else {
            toast.error('Please add details!');
            setLoader(false)
        }
    }
    useEffect(async () => {
        if (window.location.pathname === '/edit-document' && query.get('id') != '') {
            setLoader(true)
            await getSingleAPI(query.get('id')).then(response => {
                if (response.success) {
                    const data = response?.data;
                    if (data) {
                        setEditCase(true);
                        setName(data?.name?data?.name:'');
                        setDescription(data?.description?data?.description:'');
                        setSlug(data?.slug?data?.slug:'');
                        setMetaTitle(data?.metaTitle?data?.metaTitle:'')
                        setMetaDescription(data?.metaDescription?data?.metaDescription:'');
                        setCategoryId(data?.catgeoryId?data?.catgeoryId:'');
                        setFileUrl(data?.file?data?.file:'');
                        setLoader(false)
                    } else {
                        history.push('/404');
                    }
                } else {
                    history.push('/404');
                }
            });
        }
        getAllAPI(0, 10000000000).then(response=>{
            if(response.success)
            {
                setCategoryList(response.data)
            }
        })
    }, [])
    return (
        <>
            <div className="nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-lg">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">{editCase ? 'Edit Document' : 'Add Document'}</h4>
                                                </div>
                                            </div>
                                            {
                                                loader ?
                                                    <RenderLoader trans={true} />
                                                    : true
                                            }
                                            <div className="nk-block">
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Normal Detail</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Name*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={name} onChange={(e) => { setName(e.target.value); setSlug(slugify(e.target.value)) }} name='title' placeholder='Name*' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Description*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" name='description' value={description} onChange={(e) => setDescription(e.target.value)} placeholder='Description...' required className="form-control form-control-lg" style={{ width: '100%' }}>

                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Slug*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={slug} onChange={(e) => setSlug(e.target.value)} name='slug' placeholder='Slug' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Category*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <select className="form-control form-control-lg" value={categoryId} onChange={(e) => setCategoryId(e.target.value)} defaultValue='' name='featured' style={{ width: '100%' }}>
                                                                    <option value=''>Select</option>
                                                                    {
                                                                        categoryList?.map((items, index)=>
                                                                          <option value={items?._id} key={index}>{items?.name}</option>
                                                                        )
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Upload file*</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">File*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <a href={BASE_IMG_URL2 + fileUrl} target='_blank'>View Pdf</a>
                                                                        <input type="file" ref={imageRef1} name='bannerImage' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'documents');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setLoader(false)
                                                                                        setFileUrl(response?.aws?.key)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        setLoader(false)
                                                                                        toast.error(response.message)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" ref={imageRef1} name='bannerImage' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'documents');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setFileUrl(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Meta Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Meta Title*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" name='metaTitle' value={metaTitle} onChange={(e) => setMetaTitle(e.target.value)} placeholder='Meta Title' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Meta Description*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" name='metaDescription' value={metaDescription} onChange={(e) => setMetaDescription(e.target.value)} placeholder='Meta Description' required className="form-control form-control-lg" style={{ width: '100%' }}>
                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <div className="form-control-wrap" style={{ width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                                                                <button className="btn btn-lg btn-primary btn-block" onClick={(e) => handleCreateWebinar(e)}>{editCase ? 'Save' : 'Create'}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default CreateDocument;
import React, { useEffect, useState, useRef } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useHistory } from 'react-router-dom';
import { BASE_IMG_URL2, getMethod, postMethod, putMethod } from '../../Utils/Service';
import { imageUpload } from '../../Utils/upload';
import RenderLoader from '../../PanelComponent/RenderLoader';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const CreateGallery = (props) => {
    const query = useQuery();
    const history = useHistory();
    const [editCase, setEditCase] = useState(false);
    const [loader, setLoader] = useState(false)

    const [alt, setAlt] = useState('');
    const [type, setType] = useState('');
    const [extension, setExtension] = useState('');
    const [key, setKey] =useState('');
    const imageRef1 = useRef();

    const verifyUrlIsImage = (url) => {
        return (url.match(/\.(jpeg|avif|jfif|pjpeg|pjp|apng|jpg|gif|webp|svg|bmp|ico|tif|tiff|cur|png)$/) != null);
    }

    const handleCreateWhitePaper = async (e) => {
        e.preventDefault();
        setLoader(true)
        if (alt != '' && type != '' && extension != '' && key != '') {
            const data = {
                "alt": alt,
                "type": type?type:'gallery',
                "extension": extension?extension:'image',
                "key": key,
            }
            if (editCase) {
                  await putMethod('/api/inmorphian-gallery/'+query.get('id')+'/edit', data).then((response)=>{
                    if(response?.success)
                    {
                        setAlt('');
                        setType('');
                        setExtension('');
                        setKey('');
                        if (imageRef1.current) {
                            imageRef1.current.value = "";
                        }
                        toast.success(response.message);
                        history.push('/gallery-list')
                    }else{
                        toast.error(response.message)
                        setLoader(false)
                    }
                  })
            } else {
                await postMethod('/api/inmorphian-gallery/add', data).then(response => {
                    if (response.success) {
                        toast.success(response.message);
                        setAlt('');
                        setType('');
                        setExtension('');
                        setKey('');
                        if (imageRef1.current) {
                            imageRef1.current.value = "";
                        }
                        setLoader(false)
                    } else {
                        toast.error(response.message)
                        setLoader(false)
                    }
                });
            }
        } else {
            toast.error('Please fill all required fields!')
            setLoader(false)
        }
    }
    useEffect(async () => {
        if (window.location.pathname === '/edit-gallery' && query.get('id') != '') {
            setLoader(true)
            await getMethod('/api/inmorphian-gallery/'+query.get('id')+'/single').then(response => {
                
            if (response.success) {
                      const data = response.data;
                      if (data) {
                        setAlt(data?.alt?data?.alt:'')
                        setEditCase(true);
                        setType(data?.type?data?.type:'')
                        setExtension(data?.extension?data?.extension:'');
                        setKey(data?.key?data?.key:'')
                        setLoader(false)
             } else {
                         history.push('/404')
                     }
            } else {
                     history.push('/404')
                 }
            });
        }
    }, [])
    return (
        <>
            <div className="nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-lg">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">{editCase ? 'Edit Gallery' : 'Create Gallery'}</h4>
                                                </div>
                                            </div>
                                            {
                                                loader ?
                                                    <RenderLoader trans={true} />
                                                    : null
                                            }
                                            <div className="nk-block">
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Normal Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Alt Text*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                            <input type="text" value={alt} onChange={(e) => setAlt(e.target.value)} name='mainTitle' placeholder='Heading..' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Section Type*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <select className="form-control form-control-lg" value={type} onChange={(e)=>setType(e.target.value)}>
                                                                    <option value=''>Select</option>
                                                                    <option value='gallery'>Gallery</option> 
                                                                    <option value='marquee'>Marquee</option> 
                                                                </select>   
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Type*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <select className="form-control form-control-lg" value={extension} onChange={(e)=>setExtension(e.target.value)}>
                                                                    <option value=''>Select</option>
                                                                    {
                                                                        type === 'marquee'?
                                                                           null
                                                                        :
                                                                        <option value='video'>Video</option> 
                                                                    }
                                                                    
                                                                    <option value='image'>Image</option> 
                                                                </select>   
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Upload Video/Image*</span>
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>  
                                                                    {
                                                                        editCase && key || key?
                                                                            verifyUrlIsImage(BASE_IMG_URL2+key) ?
                                                                                <img src={BASE_IMG_URL2 + key} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                                :
                                                                                <video controls className='img-fluid'>
                                                                                    <source src={BASE_IMG_URL2 + key} type="video/mp4" />Sorry, your browser doesn't support embedded videos.
                                                                                </video>
                                                                        :null
                                                                    }
                                                                        <input type="file" name='image' ref={imageRef1} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'inmorphian');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setKey(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                        </div>
                                                    </div>
                                                    <div className="nk-data data-list">
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <div className="form-control-wrap" style={{ width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                                                                <button className="btn btn-lg btn-primary btn-block" onClick={(e) => handleCreateWhitePaper(e)}>{editCase ? 'Save' : 'Create'}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default CreateGallery;
import React, { useEffect, useState} from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useHistory } from 'react-router-dom';
import { postWhitePaperAPI, putWhitePaperAPI, getSingleWhitePaperAPI } from './Action';
import RenderLoader from '../../PanelComponent/RenderLoader';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const CreateHomeOther = (props) => {
    const query = useQuery();
    const history = useHistory();
    const [editCase, setEditCase] = useState(false);
    const [loader, setLoader] = useState(false)
    const [number, setNumber] = useState(0);
    const [symbol, setSymbol] = useState(' ');
    const [description, setDescription] = useState('');
    const handleCreateWhitePaper = async (e) => {
        e.preventDefault();
        setLoader(true)
        if (number && symbol && description && query.get('id')) {
            const data = {
                number: number,
                symbol: symbol,
                description: description,
                bannerId: query.get('id')
            }
            if (editCase) {
                await putWhitePaperAPI(query.get('subId'), data).then(response => {
                    if (response.success) {
                        toast.success(response.message);
                        setEditCase(false)
                        setNumber(0);
                        setSymbol(' ');
                        setDescription('');
                        history.push('/home-banner-other-description-list?id='+query.get('id'));
                        setLoader(false)
                    } else {
                        toast.error(response.message)
                        setLoader(false)
                    }
                });
            } else {
                await postWhitePaperAPI(data).then(response => {
                    if (response.success) {
                        toast.success(response.message);
                        setNumber(0);
                        setSymbol(' ');
                        setDescription('');
                        setLoader(false)
                    } else {
                        toast.error(response.message)
                        setLoader(false)
                    }
                });
            }
        } else {
            toast.error('Please fill all required fields!')
            setLoader(false)
        }
    }
    useEffect(async () => {
        if (window.location.pathname === '/edit-home-banner-other-description' && query.get('subId') != '' && query.get('id') != '') {
            setLoader(true)
            await getSingleWhitePaperAPI(query.get('subId')).then(response => {
                if (response.success) {
                    const data = response.data;
                    if (data) {
                        setNumber(data?.number ? data?.number : 0);
                        setSymbol(data?.symbol ? data?.symbol : ' ');
                        setDescription(data?.description ? data?.description : '');
                        setEditCase(true);
                        setLoader(false)
                    } else {
                        history.push('/404')
                    }
                } else {
                    history.push('/404')
                }
            });
        }
        else if (window.location.pathname === '/home-banner-other-description' && query.get('id') != '') {
            setLoader(false)
        } else {
            history.push('/home-banners');
        }

    }, [])
    return (
        <>
            <div className="nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-lg">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">{editCase ? 'Edit Home Banner Other Description' : 'Create Home Banner Other Description'}</h4>
                                                </div>
                                            </div>
                                            {
                                                loader ?
                                                    <RenderLoader trans={true} />
                                                    : null
                                            }
                                            <div className="nk-block">
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Normal Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Number</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="number" value={number} onChange={(e) => setNumber(e.target.value)} name='mainTitle' placeholder='0' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Symbol</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={symbol} onChange={(e) => setSymbol(e.target.value)} name='mainTitle' placeholder='+,K,M, etc.' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Description</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} name='mainTitle' placeholder='Description..' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <div className="form-control-wrap" style={{ width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                                                                <button className="btn btn-lg btn-primary btn-block" onClick={(e) => handleCreateWhitePaper(e)}>{editCase ? 'Save' : 'Create'}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default CreateHomeOther;
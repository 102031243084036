import React, { useEffect, useState, useRef } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useHistory } from 'react-router-dom';
import { BASE_IMG_URL2, getMethod, postMethod, putMethod } from '../../Utils/Service';
import { imageUpload } from '../../Utils/upload';
import RenderLoader from '../../PanelComponent/RenderLoader';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {slugify} from '../../Utils/Logic';
function useQuery() {

    return new URLSearchParams(useLocation().search);
}
const CreateInmorphian = (props) => {
    const query = useQuery();
    const history = useHistory();
    const [editCase, setEditCase] = useState(false);
    const [loader, setLoader] = useState(false)

    const [name, setName] = useState('');
    const [designation, setDesignation] = useState('');
    const [type, setType] = useState('');
    const [description, setDescription] = useState('');
    const [slug, setSlug] =useState('');
    const [url, setUrl] = useState('');
    const [featuredPreview, setFeaturedPreview] = useState('');
    const [profilePic, setProfilePic] = useState('');

    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');

  



    const imageRef1 = useRef();
    const imageRef2 = useRef();

    const verifyUrlIsImage = (url) => {
        return (url.match(/\.(jpeg|avif|jfif|pjpeg|pjp|apng|jpg|gif|webp|svg|bmp|ico|tif|tiff|cur|png)$/) != null);
    }

    const handleCreateWhitePaper = async (e) => {
        e.preventDefault();
        setLoader(true)
        if (name != '' && type != '' && slug != '') {
            const data = {
                name:name,
                designation:designation,
                type:type?type:'stories',
                description:description,
                slug:slug,
                url:url,
                featuredPreview:featuredPreview,
                profilePic:profilePic,
                metaTitle:metaTitle,
                metaDescription:metaDescription
            }
            if (editCase) {
                  await putMethod('/api/inmorphian/'+query.get('id')+'/edit', data).then((response)=>{
                    if(response?.success)
                    {
                        setName('');
                        setDesignation('');
                        setDescription('');
                        setType('');
                        setSlug('');
                        setUrl('');
                        setFeaturedPreview('');
                        setProfilePic('');
                        setMetaTitle('');
                        setMetaDescription('');
                        if (imageRef1.current) {
                            imageRef1.current.value = "";
                        }
                        if (imageRef2.current) {
                            imageRef2.current.value = "";
                        }
                        toast.success(response.message);
                        history.push('/inmorphian')
                    }else{
                        toast.error(response.message)
                        setLoader(false)
                    }
                  })
            } else {
                await postMethod('/api/inmorphian/add', data).then(response => {
                    if (response.success) {
                        toast.success(response.message);
                        setName('');
                        setDesignation('');
                        setDescription('');
                        setType('');
                        setSlug('');
                        setUrl('');
                        setFeaturedPreview('');
                        setProfilePic('');
                        setMetaTitle('');
                        setMetaDescription('');
                        if (imageRef1.current) {
                            imageRef1.current.value = "";
                        }
                        if (imageRef2.current) {
                            imageRef2.current.value = "";
                        }
                        setLoader(false)
                    } else {
                        toast.error(response.message)
                        setLoader(false)
                    }
                });
            }
        } else {
            toast.error('Please fill all required fields!')
            setLoader(false)
        }
    }
    useEffect(async () => {
        if (window.location.pathname === '/edit-inmorphian' && query.get('id') != '') {
            setLoader(true)
            await getMethod('/api/inmorphian/'+query.get('id')+'/single').then(response => {
                
            if (response.success) {
                      const data = response.data;
                      if (data) {
                       setName(data?.name?data?.name:'');
                       setDesignation(data?.designation?data?.designation:'');
                       setType(data?.type?data?.type:'');
                       setDescription(data?.description?data?.description:'');
                       setSlug(data?.slug?data?.slug:'');
                       setUrl(data?.url?data?.url:'');
                       setFeaturedPreview(data?.featuredPreview?data?.featuredPreview:'');
                       setProfilePic(data?.profilePic?data?.profilePic:'');
                       setMetaTitle(data?.metaTitle?data?.metaTitle:'');
                       setMetaDescription(data?.metaDescription?data?.metaDescription:'');
                        setEditCase(true);
                    
                        setLoader(false)
             } else {
                         history.push('/404')
                     }
            } else {
                     history.push('/404')
                 }
            });
        }
    }, [])
    return (
        <>
            <div className="nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-lg">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">{editCase ? 'Edit Inmorphian' : 'Create Inmorphian'}</h4>
                                                </div>
                                            </div>
                                            {
                                                loader ?
                                                    <RenderLoader trans={true} />
                                                    : null
                                            }
                                            <div className="nk-block">
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Normal Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Name*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                            <input type="text" value={name} onChange={(e) => {setName(e.target.value);setSlug(slugify(e.target.value))}} name='name' placeholder='Name' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Slug*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                            <input type="text" value={slug} onChange={(e) => setSlug(e.target.value)} name='slug' placeholder='Slug' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Designation</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                            <input type="text" value={designation} onChange={(e) => setDesignation(e.target.value)} name='designation' placeholder='Designation' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Employe Type*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <select className="form-control form-control-lg" value={type} onChange={(e)=>setType(e.target.value)}>
                                                                    <option value=''>Select</option>
                                                                    <option value='testimonies'>Testimonies</option> 
                                                                    <option value='stories'>Stories</option> 
                                                                </select>   
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Bio</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={description}
                                                                    config={{
                                                                        toolbar: ['bold', 'italic', 'blockQuote', 'link', 'bulletedList']
                                                                    }}
                                                                    onReady={editor => {
                                                                        // console.log('editor ready!')
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        setDescription(editor.getData())
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Social Url</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                            <input type="text" value={url} onChange={(e) => setUrl(e.target.value)} name='url' placeholder='Url' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Upload Profile Image</span>
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>  
                                                                    {
                                                                        editCase && profilePic || profilePic?
                                                                            verifyUrlIsImage(BASE_IMG_URL2+profilePic) ?
                                                                                <img src={BASE_IMG_URL2 + profilePic} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                                :
                                                                                <video controls className='img-fluid'>
                                                                                    <source src={BASE_IMG_URL2 + profilePic} type="video/mp4" />Sorry, your browser doesn't support embedded videos.
                                                                                </video>
                                                                        :null
                                                                    }
                                                                        <input type="file" name='image' ref={imageRef2} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'inmorphian');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setProfilePic(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Upload Intodction(Video/Image)*</span>
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>  
                                                                    {
                                                                        editCase && featuredPreview || featuredPreview?
                                                                            verifyUrlIsImage(BASE_IMG_URL2+featuredPreview) ?
                                                                                <img src={BASE_IMG_URL2 + featuredPreview} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                                :
                                                                                <video controls className='img-fluid'>
                                                                                    <source src={BASE_IMG_URL2 + featuredPreview} type="video/mp4" />Sorry, your browser doesn't support embedded videos.
                                                                                </video>
                                                                        :null
                                                                    }
                                                                        <input type="file" name='image' ref={imageRef1} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'inmorphian-assets');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setFeaturedPreview(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Meta Title</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={metaTitle} onChange={(e) => setMetaTitle(e.target.value)} name='metatitle' placeholder='Meta Title' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Meta Description</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" name='metaDescription' value={metaDescription} onChange={(e) => setMetaDescription(e.target.value)} placeholder='Meta Description' required className="form-control form-control-lg" style={{ width: '100%' }}>

                                                                </textarea> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="nk-data data-list">
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <div className="form-control-wrap" style={{ width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                                                                <button className="btn btn-lg btn-primary btn-block" onClick={(e) => handleCreateWhitePaper(e)}>{editCase ? 'Save' : 'Create'}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default CreateInmorphian;
import React, { useEffect } from 'react';
import { BASE_IMG_URL } from '../Utils/Service';
import { useHistory } from 'react-router';
const NotFound = () => {
    const history = useHistory();
    useEffect(()=>{
        history.push('/404');
        window.scroll(0, 0);
    },[])
    return (
        <>
            <div className='container' style={{ width: '100%' }}>
                <div className='row justify-content-center'>
                    <div className='col-lg-12 col-12'>
                        <img src={BASE_IMG_URL + 'website/assets/images/errorweb.png'} className='img-fluid' style={{ width: '100%', height: '100%' }} />
                    </div>
                    <div className='col-lg-12 col-12 text-center' style={{ backgroundColor: 'transparent' }}>
                        <h1 style={{fontSize:'31px',color:'red',fontWeight:'600'}}>
                              404 Not Found
                        </h1>
                    </div>
                </div>
            </div>
        </>
    )
}
export default NotFound;
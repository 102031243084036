import React, { lazy, Suspense } from "react";
import RenderLoader from "../PanelComponent/RenderLoader";
const PrivacySection = lazy(() => import('../PanelComponent/PrivacyPolicy'));
const renderLoader = () => <RenderLoader trans={false}/>;
const PrivacyPage=()=>{
    return (
        <Suspense fallback={renderLoader()}>
            <PrivacySection />
        </Suspense>
    )
}
export default PrivacyPage;
import React, { lazy, Suspense, useEffect, useState } from "react";
import { useHistory } from "react-router";
import RenderLoader from "../../../PanelComponent/RenderLoader";
import { getCookies } from "../../../Utils/cookies";
const LoginSection = lazy(() => import('../Components/LoginSection'));
const renderLoader = () => <RenderLoader trans={false} />;
const Login = (props) => {
    const history = useHistory();
    return (
        <>
            {getCookies('_inmp_admin_token') ?
                history.push('/dashboard')
                :
                <Suspense fallback={renderLoader()}>
                    <LoginSection />
                </Suspense>
            }
        </>
    )
}
export default Login;
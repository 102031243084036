import React, { useEffect, useState } from 'react';
import { getAllAPI } from '../Pages/Services-Consulting/Action';
import MultiSelect from  'react-multiple-select-dropdown-lite'
import  'react-multiple-select-dropdown-lite/dist/index.css'


const ServicesSlugList = (props) => {
    const { value, onChange, label } = props;
    const [servicesList, setServicesList] = useState([]);
    const fetchAllServices = async () => {
        await getAllAPI(0, 200).then((response) => {
            if (response?.success) {
                let fileterData = (response?.data)?.map((n) => ({ label: n?.name ? n?.name : '', value: n?.slug ? n?.slug : '' }))
                setServicesList(fileterData)
            } else {
                setServicesList([]);
            }
        })
    }

    useEffect(() => {
        fetchAllServices()
    }, [])
    return (
        <>
            <span className="data-label">{label}</span>
            <div className="form-control-wrap" style={{ width: '100%' }}>
                {
                     servicesList?.length?
                       <MultiSelect options={servicesList} defaultValue={value} placeholder="Select Services" largeData={true} customeValue={false} clearable={false} downArrow={true} singleSelect={false} onChange={(e)=>onChange(e)} name='services' style={{ width: '100%' }}/>
                     :null
                }
                {/* <select className="form-control form-control-lg" value={value} onChange={(e) => onChange(e.target.value)} defaultValue='false' name='services' style={{ width: '100%' }}>
                    <option value="">Select Service</option>
                    {
                        servicesList?.length ?
                            servicesList?.map((n) => <option value={n?.value}>{n?.label}</option>)
                            : null
                    }
                </select> */}
            </div>
        </>
    )
}

export default ServicesSlugList;
import { postMethod, putMethod, deleteMethod, getMethod} from '../../Utils/Service';
const postAPI=async(data)=>{
      const returnData = await postMethod('api/innovation/add', data);
      return returnData;
}
const putAPI=async(id, data)=>{
    const returnData = await putMethod('api/innovation/'+id+'/edit', data);
    return returnData;
}
const deleteAPI=async(id)=>{
        const returnData = await deleteMethod('api/innovation/'+id+'/delete');
        return returnData;
}
const getAllAPI=async(page, limit)=>{
        const returnData = await getMethod('api/innovation/all?page='+page+'&limit='+limit);
        return returnData;
}
const getSingleAPI=async(id)=>{
        const returnData = await getMethod('api/innovation/'+id+'/single');
        return returnData;
}
// const getUserRegWebinarAPI=async(page, limit, id)=>{
//     const returnData = await getMethod('api/insights/webinar/'+id+'/get');
//     return returnData;
// }
export {postAPI, putAPI, deleteAPI, getAllAPI, getSingleAPI};
import React, { useEffect, useState, useRef} from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useHistory } from 'react-router-dom';
import { BASE_IMG_URL2 } from '../../Utils/Service';
import { imageUpload } from '../../Utils/upload';
import { postIndustriesAPI, putIndustriesAPI, getSingleInduriesAPI} from './Action';
import {getAllAPI} from '../Innovation/Action';
import RenderLoader from '../../PanelComponent/RenderLoader';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const CreateIndustries = (props) => {
    const query = useQuery();
    const history = useHistory();
    const [editCase, setEditCase] = useState(false);
    const [name, setName] = useState('');
    const [image, setImage] = useState('');
    const [appInnid, setAppInoId] = useState('');
    const [appList, setAppList] = useState([]);
    const [loader, setLoader] = useState(false)
    const imageRef = useRef();
    const handleCreateWhitePaper = async (e) => {
        e.preventDefault();
        setLoader(true)
        if (name != '' && image != '' && appInnid != '') {
            const data = {
                "name": name,
                "image": image,
                "appid":appInnid
            }
            if (editCase) {
                await putIndustriesAPI(query.get('appid'), query.get('id'), data).then(response => {
                    if (response.success) {
                        toast.success(response.message);
                        setEditCase(false)
                        setName('');
                        if(imageRef.current)
                        {
                            imageRef.current.value = "";
                        }
                        setImage('');
                        history.push('/industries?id='+query.get('appid'));
                        setLoader(false)
                    } else {
                        toast.error(response.message)
                        setLoader(false)
                    }
                });
            } else {
                await postIndustriesAPI(data?.appid, data).then(response => {
                    if (response.success) {
                        toast.success(response.message);
                        setName('');
                        setImage('');
                        imageRef.current.value = "";
                        setAppInoId('');
                        setLoader(false)
                    } else {
                        toast.error(response.message)
                        setLoader(false)
                    }
                });
            }
        } else {
            toast.error('Please fill all required fields!')
            setLoader(false)
        }
    }
    useEffect(async () => {
        if (window.location.pathname === '/edit-industries' && query.get('id') != '' && query.get('appid')) {
            setLoader(true)
            await getSingleInduriesAPI(query.get('id'), query.get('appid')).then(response => {
                if (response.success) {
                    const data = response.data;
                    if (data) {
                        setName(data?.name?data?.name:'');
                        setImage(data?.image?data?.image:'');
                        setAppInoId(data?.appid?data?.appid:'');
                        setEditCase(true);
                        setLoader(false)
                    } else {
                        history.push('/404')
                    }
                } else {
                    history.push('/404')
                }
            });
        }

        const params = {
            page:0,
            limit: 1000000000
        }
        getAllAPI(params?.page, params?.limit).then(response=>{
            if(response?.success)
            {
                setAppList(response?.data)
            }
        })
    }, [])
    return (
        <>
            <div className="nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-lg">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">{editCase ? 'Edit Industries' : 'Create Industries'}</h4>
                                                </div>
                                            </div>
                                            {
                                                loader?
                                                  <RenderLoader trans={true}/>
                                                :null
                                            }
                                            <div className="nk-block">
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Normal Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Name*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={name} onChange={(e) => setName(e.target.value)} name='name' placeholder='Name' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Innovation App*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <select className="form-control form-control-lg" value={appInnid} onChange={(e)=>setAppInoId(e.target.value)}>
                                                                       <option value=''>Select</option>
                                                                       {
                                                                           appList?.map((items, index)=>
                                                                            <option value={items?._id} key={index+1}>{items?.title}</option>  
                                                                         )
                                                                       }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Upload</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Image*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <img src={BASE_IMG_URL2 + image} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                        <input type="file" ref={imageRef} name='image' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'industries');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" ref={imageRef} name='image' className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'industries');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <div className="form-control-wrap" style={{ width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                                                                <button className="btn btn-lg btn-primary btn-block" onClick={(e) => handleCreateWhitePaper(e)}>{editCase ? 'Save' : 'Create'}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default CreateIndustries;
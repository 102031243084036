import React, { useEffect, useState, useRef } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useHistory } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { BASE_IMG_URL2 } from '../../Utils/Service';
import { imageUpload } from '../../Utils/upload';
import { postWhitePaperAPI, putWhitePaperAPI, getSingleWhitePaperAPI } from './Action';
import RenderLoader from '../../PanelComponent/RenderLoader';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const CreateHomeBanner = (props) => {
    const query = useQuery();
    const history = useHistory();
    const [editCase, setEditCase] = useState(false);
    const [loader, setLoader] = useState(false)

    const [heading, setHeading] = useState('');
    const [subHeading, setSubHeading] = useState('');
    const [url, setUrl] =useState('');

    const [desktopVideo, setDesktopVideo] = useState(' ');
    const [mobileVideo, setMobileVideo] = useState(' ');
    const [desktopImage, setDesktopImage] = useState(' ');
    const [mobileImage, setMobileImage] = useState(' ');
    const imageRef1 = useRef();
    const imageRef2 = useRef();
    const imageRef3 = useRef();
    const imageRef4 = useRef();

    const verifyUrlIsImage = (url) => {
        return (url.match(/\.(jpeg|avif|jfif|pjpeg|pjp|apng|jpg|gif|webp|svg|bmp|ico|tif|tiff|cur|png)$/) != null);
    }

    const handleCreateWhitePaper = async (e) => {
        e.preventDefault();
        setLoader(true)
        if (heading != '') {
            const data = {
                "heading": heading,
                "subHeading": subHeading,
                "url": url,
                "desktopVideo": desktopVideo,
                "mobileVideo": mobileVideo,
                "desktopImage":desktopImage,
                "mobileImage":mobileImage
            }
            if (editCase) {
                await putWhitePaperAPI(query.get('id'), data).then(response => {
                    if (response.success) {
                        toast.success(response.message);
                        setEditCase(false)
                        setHeading('');
                        setSubHeading('');
                        setUrl('');
                        setDesktopVideo('');
                        setMobileVideo('');
                        setDesktopImage('');
                        setMobileImage('')
                        if (imageRef1.current) {
                            imageRef1.current.value = "";
                        }
                        if (imageRef2.current) {
                            imageRef2.current.value = "";
                        }
                        if (imageRef3.current) {
                            imageRef3.current.value = "";
                        }
                        if (imageRef4.current) {
                            imageRef4.current.value = "";
                        }
                        history.push('/home-banners');
                        setLoader(false)
                    } else {
                        toast.error(response.message)
                        setLoader(false)
                    }
                });
            } else {
                await postWhitePaperAPI(data).then(response => {
                    if (response.success) {
                        toast.success(response.message);
                        setHeading('');
                        setSubHeading('');
                        setUrl('');
                        setDesktopVideo('');
                        setMobileVideo('');
                        setDesktopImage('');
                        setMobileImage('')
                        if (imageRef1.current) {
                            imageRef1.current.value = "";
                        }
                        if (imageRef2.current) {
                            imageRef2.current.value = "";
                        }
                        if (imageRef3.current) {
                            imageRef3.current.value = "";
                        }
                        if (imageRef4.current) {
                            imageRef4.current.value = "";
                        }
                        setLoader(false)
                    } else {
                        toast.error(response.message)
                        setLoader(false)
                    }
                });
            }
        } else {
            toast.error('Please fill all required fields!')
            setLoader(false)
        }
    }
    useEffect(async () => {
        if (window.location.pathname === '/edit-home-banner' && query.get('id') != '') {
            setLoader(true)
            await getSingleWhitePaperAPI(query.get('id')).then(response => {
                if (response.success) {
                    const data = response.data;
                    if (data) {
                        setHeading(data?.heading ? data?.heading : '');
                        setSubHeading(data?.subHeading ? data?.subHeading : '');
                        setUrl(data?.url ? data?.url : '');
                        setDesktopVideo(data?.desktopVideo ? data?.desktopVideo: '');
                        setMobileVideo(data?.mobileVideo?data?.mobileVideo :'');
                        setDesktopImage(data?.desktopImage?data?.desktopImage:'');
                        setMobileImage(data?.mobileImage?data?.mobileImage:'');
                        setEditCase(true);
                        setLoader(false)
                    } else {
                        history.push('/404')
                    }
                } else {
                    history.push('/404')
                }
            });
        }
    }, [])
    return (
        <>
            <div className="nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-lg">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">{editCase ? 'Edit Home Banner' : 'Create Home Banner'}</h4>
                                                </div>
                                            </div>
                                            {
                                                loader ?
                                                    <RenderLoader trans={true} />
                                                    : null
                                            }
                                            <div className="nk-block">
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Normal Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Heading</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                            <input type="text" value={heading} onChange={(e) => setHeading(e.target.value)} name='mainTitle' placeholder='Heading..' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Sub-Heading</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={subHeading}
                                                                    config={{
                                                                        toolbar: ['bold', 'italic', 'blockQuote',]
                                                                    }}
                                                                    onReady={editor => {
                                                                        // console.log('editor ready!')
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        setSubHeading(editor.getData())
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Url</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={url} onChange={(e) => setUrl(e.target.value)} name='mainTitle' placeholder='https://' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Upload</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Desktop Video</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                         {
                                                                            !desktopVideo ?
                                                                                null
                                                                                :
                                                                                <video controls className='img-fluid'>
                                                                                    <source src={BASE_IMG_URL2 + desktopVideo} type="video/mp4" />Sorry, your browser doesn't support embedded videos.
                                                                                </video>
                                                                        }
                                                                        <input type="file" name='image' ref={imageRef1} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'home-banner');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setDesktopVideo(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" name='image' ref={imageRef1} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'home-banner');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setDesktopVideo(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Mobile Video</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                         {
                                                                            !mobileVideo?
                                                                               null
                                                                                :
                                                                                <video controls className='img-fluid'>
                                                                                    <source src={BASE_IMG_URL2 + mobileVideo} type="video/mp4" />Sorry, your browser doesn't support embedded videos.
                                                                                </video>
                                                                        }
                                                                        <input type="file" name='image' ref={imageRef2} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'home-banner');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setMobileVideo(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" name='image' ref={imageRef2} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'home-banner');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setMobileVideo(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>


                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Desktop Image</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>  
                                                                    {
                                                                        desktopImage?
                                                                        <img src={BASE_IMG_URL2 + desktopImage} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                        :null
                                                                    }
                                                                        <input type="file" name='image' ref={imageRef3} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'home-banner');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setDesktopImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" name='image' ref={imageRef3} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'home-banner');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setDesktopImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Mobile Image</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                         {
                                                                            mobileImage?
                                                                                <img src={BASE_IMG_URL2 + mobileImage} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                                :
                                                                                null
                                                                        }
                                                                        <input type="file" name='image' ref={imageRef4} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'home-banner');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setMobileImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" name='image' ref={imageRef4} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'home-banner');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setMobileImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <div className="form-control-wrap" style={{ width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                                                                <button className="btn btn-lg btn-primary btn-block" onClick={(e) => handleCreateWhitePaper(e)}>{editCase ? 'Save' : 'Create'}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default CreateHomeBanner;
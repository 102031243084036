import React, { useEffect, useState } from 'react';
import { dateFormatConverter } from '../../Utils/Logic';
import { useLocation, useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactPaginate from 'react-paginate';
import toast, { Toaster } from 'react-hot-toast';
import RenderLoader from '../../PanelComponent/RenderLoader';
import { BASE_IMG_URL2, deleteMethod, getMethod } from '../../Utils/Service';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const GalleryList = (props) => {
    const query = useQuery();
    const history = useHistory();
    const [totalWhitePaper, setTotalWhitePaper] = useState(0);
    const [whitePaperList, setWhitePaperList] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [loader, setLoader] = useState(true);
    const [upperLimit, setUpperLimit]= useState(10);
    const getListAPI = async (page, limit) => {
        const params = { page: page, limit: limit };
        await getMethod('/api/inmorphian-gallery/all', params).then((response) => {
            if (response?.success) {
                setLoader(false);
                setTotalWhitePaper(response?.total);
                setWhitePaperList(response?.data);
            } else {
                setLoader(false)
            }
        })
    };
    const confirmAction = (id, index) => {
        confirmAlert({
            title: 'Confirm Action',
            message: 'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        await deleteMethod('/api/inmorphian-gallery/'+id+'/delete').then(response => {
                            if (response?.success === true || response?.status === true) {
                                getListAPI(page, limit)
                                toast.success(response.message);
                            } else {
                                toast.error(response.message)
                            }
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }
    useEffect(() => {
        getListAPI(page, limit);
    }, []);
    return (
        <>
            <div className="sideBarUL nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-lg">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">Gallery</h4>
                                                    <div className="nk-block-des text-soft">
                                                        {
                                                            totalWhitePaper ?
                                                                <p>You have total {totalWhitePaper === 1 ? totalWhitePaper + '' : totalWhitePaper + ''}</p>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                loader ?
                                                    <RenderLoader trans={true} />
                                                    : null
                                            }
                                            <div style={{ width: '100%' }}>
                                                <div className="nk-block">
                                                    <div className="card card-bordered card-stretch">
                                                        <div className="card-inner-group">
                                                            <div className="card-inner p-0">
                                                                <div className="nk-tb-list nk-tb-ulist">
                                                                    <div className="nk-tb-item nk-tb-head">
                                                                        <div className="nk-tb-col nk-tb-col-check">
                                                                            <span className="sub-text text-center">S.no</span>
                                                                        </div>
                                                                        <div className="nk-tb-col text-center"><span className="sub-text">Alt Text</span></div>
                                                                        <div className="nk-tb-col text-center"><span className="sub-text">Section Type</span></div>
                                                                        <div className="nk-tb-col text-center"><span className="sub-text">Type</span></div>
                                                                        <div className="nk-tb-col text-center"><span className="sub-text">Preview</span></div>
                                                                        <div className="nk-tb-col tb-col-md"><span className="sub-text text-center">Created At</span></div>
                                                                        <div className="nk-tb-col nk-tb-col-tools text-right">
                                                                            <span className="sub-text">More</span>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        whitePaperList?.length ?
                                                                            whitePaperList?.map((data, index) =>
                                                                                <div className="nk-tb-item" key={index}>
                                                                                    <div className="nk-tb-col nk-tb-col-check">
                                                                                        <span className="sub-text text-center">{index + 1}</span>
                                                                                    </div>
                                                                                    <div className="nk-tb-col">
                                                                                        <span className="sub-text text-center">{data?.alt}</span>
                                                                                    </div>
                                                                                    <div className="nk-tb-col">
                                                                                        <span className="sub-text text-center" style={{ textTransform: 'capitalize' }}>{data?.type}</span>
                                                                                    </div>
                                                                                    <div className="nk-tb-col">
                                                                                        <span className="sub-text text-center" style={{ textTransform: 'capitalize' }}>{data?.extension}</span>
                                                                                    </div>
                                                                                    <div className="nk-tb-col">
                                                                                        <span className="sub-text text-center" style={{ textTransform: 'capitalize' }}>{
                                                                                            data?.extension === 'video' ?
                                                                                                <video src={BASE_IMG_URL2 + data?.key} style={{ width: '50px', height: '50px' }}>

                                                                                                </video>
                                                                                                :
                                                                                                <img src={BASE_IMG_URL2 + data?.key} className='img-fluid' style={{ width: '50px', height: '50px' }} />
                                                                                        }</span>
                                                                                    </div>

                                                                                    <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="sub-text text-center">{dateFormatConverter(data?.createdAt)}</span>
                                                                                    </div>
                                                                                    <div className="nk-tb-col nk-tb-col-tools">
                                                                                        <ul className="nk-tb-actions gx-1">
                                                                                            <li>
                                                                                                <div className="drodown">
                                                                                                    <a href="#" className="dropdown-toggle btn btn-icon btn-trigger" data-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
                                                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                                                        <ul className="link-list-opt no-bdr">
                                                                                                            <li><a onClick={(e) => { history.push('/edit-gallery?id=' + data?._id) }}><em className="icon ni ni-pen"></em><span>Edit</span></a></li>
                                                                                                            <li><a onClick={(e) => confirmAction(data?._id, index)}><em className="icon ni ni-trash"></em><span>Delete</span></a></li>
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                            : null
                                                                    }
                                                                </div>
                                                                {
                                                                    whitePaperList?.length ?
                                                                        null
                                                                        :
                                                                        <div className='row justify-content-center fullDiv' style={{ marginLeft: '0px' }}>
                                                                            <p className='tb-status text-success sub-text text-center' style={{ fontSize: '16px', fontWeight: '600', lineHeight: '36px' }}>
                                                                                Not Found!
                                                                            </p>
                                                                        </div>
                                                                }
                                                            </div>
                                                            <div className='' style={{ padding: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                {
                                                                    ((totalWhitePaper - upperLimit) > 0 ? Math.round(totalWhitePaper / upperLimit) + 1 : Math.round(totalWhitePaper / upperLimit)) > 1 ?
                                                                        <ReactPaginate
                                                                            previousLabel={"Prev"}
                                                                            nextLabel={"Next"}
                                                                            breakLabel={"..."}
                                                                            breakClassName={"break-me"}
                                                                            pageCount={(totalWhitePaper - upperLimit) > 0 ? Math.round(totalWhitePaper / upperLimit) + 1 : Math.round(totalWhitePaper / upperLimit)}
                                                                            marginPagesDisplayed={2}
                                                                            pageRangeDisplayed={3}
                                                                            onPageChange={async (e) => { setLoader(true); setPage(e.selected+1); getListAPI(e.selected+1, limit) }}
                                                                            containerClassName={"pagination"}
                                                                            subContainerClassName={"pages pagination"}
                                                                            activeClassName={"active"} />
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default GalleryList;

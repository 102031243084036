import React, { lazy, Suspense, useEffect, useState } from 'react';
import { getCookies } from '../../Utils/cookies';
import {setAuthorizationToken} from '../../Utils/Service';
import { Toaster } from 'react-hot-toast';
import RenderLoader from "../../PanelComponent/RenderLoader";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
const PresonalInfo = lazy(() => import('./component/PresonalInfo'));
const CreateNewAdmin = lazy(() => import('./component/CreateNewAdmin'));
const GetAdminList = lazy(() => import('./component/GetAdminLIst'));
const renderLoader = () => <RenderLoader trans={false} />;
const AdminProfile = () => {
    const [profileDetails, setProfileDetails] = useState({ firstName: 'I', lastName: 'A', email: '' });
    const [tabIndex, setTabIndex] = useState(1);
    const [edit, setEdit] = useState(false);
    const [editProfileData, setEditProfileData] = useState('');
    useEffect(() => {
        setProfileDetails(JSON.parse(getCookies('_imp_admin_details')));
        setAuthorizationToken(getCookies('_inmp_admin_token'));
    }, [])
    return (
        <>
            <Suspense fallback={renderLoader()}>
                <div className="nk-content ">
                    <div className="container-fluid">
                        <div className="nk-content-inner">
                            <div className="nk-content-body">
                                <div className="nk-block">
                                    <div className="card card-bordered">
                                        <div className="card-aside-wrap">
                                            {
                                                tabIndex === 1 ?
                                                    <PresonalInfo />
                                                    : tabIndex === 2 ?
                                                        <GetAdminList setTabIndex={setTabIndex} onOpen={setEdit} setEditProfileData={setEditProfileData}/>
                                                        : tabIndex === 3 ?
                                                            <CreateNewAdmin setTabIndex={setTabIndex} edit={edit} editProfileData={editProfileData} onOpen={setEdit} setEditProfileData={setEditProfileData}/>
                                                            : null
                                            }
                                            <div className="sideBarUL card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg" data-content="userAside" data-toggle-screen="lg" data-toggle-overlay="true">
                                                <div className="card-inner-group" data-simplebar>
                                                    <div className="card-inner">
                                                        <div className="user-card">
                                                            <div className="user-avatar bg-primary">
                                                                <span style={{ textTransform: 'uppercase' }}>{profileDetails?.firstName[0] + profileDetails?.lastName[0]}</span>
                                                            </div>
                                                            <div className="user-info">
                                                                <span className="lead-text">{profileDetails?.firstName + ' ' + profileDetails?.lastName}</span>
                                                                <span className="sub-text">{profileDetails?.email}</span>
                                                            </div>
                                                            <div className="user-action">
                                                                <div className="dropdown">
                                                                    <a className="btn btn-icon btn-trigger mr-n2" data-toggle="dropdown" href="#"><em className="icon ni ni-more-v"></em></a>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        <ul className="link-list-opt no-bdr">
                                                                            <li><a href="#"><em className="icon ni ni-camera-fill"></em><span>Change Photo</span></a></li>
                                                                            {/* <li><a href="#"><em className="icon ni ni-edit-fill"></em><span>Update Profile</span></a></li> */}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-inner p-0">
                                                        <ul className="link-list-menu">
                                                            <li><a className={tabIndex === 1 ? "active" : ""} onClick={(e) => { setTabIndex(1) }}><em className="icon ni ni-user-fill-c"></em><span>Personal Infomation</span></a></li>
                                                            {
                                                                profileDetails?.role === 'admin' ?
                                                                    <>
                                                                        <li><a className={tabIndex === 2 ? "active" : ""} onClick={(e) => { setTabIndex(2); }}><em className="icon ni ni-list"></em><span>Sub Admin List</span></a></li>
                                                                        <li><a className={tabIndex === 3 ? "active" : ""} onClick={(e) => { setTabIndex(3) }}><em className="icon ni ni-plus"></em><span>{edit?'Edit Admin Details':'Create New Admin'}</span></a></li>
                                                                    </>
                                                                    : null
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Toaster
                    position="top-center"
                    reverseOrder={false}
                    duration={3000}
                />
            </Suspense>
        </>
    )
}
export default AdminProfile;
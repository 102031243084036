import React, { useEffect, useState } from "react";
import { Route, Switch, Router, useHistory, Redirect } from 'react-router-dom';
import Footer from "../Components/Footer/Footer";
import Sidebar from "../Components/Sidebar/Sidebar";
import Topbar from "../Components/Topbar/Topbar";
import Login from "../Pages/Auth/Login/Login";
import Dashboard from "../Pages/Dashboard";
import PrivacyPage from "../PanelPages/PrivacyPage";
import NotFound from "../PanelComponent/NotFound";
import { getCookies } from '../Utils/cookies';
import { setAuthorizationToken } from "../Utils/Service";
import AdminProfile from "../Pages/AccountSetting/AdminProfile";
import CreateCaseStudy from "../Pages/CaseStudy/CreateCaseStudy";
import CaseStudyList from "../Pages/CaseStudy/CaseStudyList";
import CreateWebinar from "../Pages/Webinar/CreateWebinar";
import WebinarList from "../Pages/Webinar/WebinarList";
import Whitepaper from "../Pages/WhitePaper/WhitePaper";
import CreateWhitePaper from "../Pages/WhitePaper/CreateWhitePaper";
import RegisterUserWebinar from "../Pages/Webinar/RegisterUserWebinar";
import CreateServicePage from "../Pages/Services-Consulting/CreateServicePage";
import Services from "../Pages/Services-Consulting/Services";
import CreateBlogs from "../Pages/Blogs/CreateBlogs";
import Blog from "../Pages/Blogs/Blog";
import Innovation from '../Pages/Innovation/Innovation';
import InnovationDetails from '../Pages/Innovation/InnovationDetails'
import HappyCustomer from "../Pages/HappyCustomer/HappyCustomer";
import CreateHappyCustomer from "../Pages/HappyCustomer/CreateHappyCustomer";
import CustomerReview from "../Pages/CustomerReview/CustomerReview";
import CreateCustomerReview from "../Pages/CustomerReview/CreateCustomerReview";
import Leader from '../Pages/Leader/Leader';
import CreateLeader from '../Pages/Leader/CreateLeader';
import AboutColor from '../Pages/AboutColor/AboutColor';
import AddAboutColor from '../Pages/AboutColor/AddAboutColor';
import Industries from '../Pages/Industries/Industries'
import CreateIndustries from "../Pages/Industries/CreateIndustries";
import AddAboutAwards from "../Pages/AwardsNewsRoom/AddAboutAwards";
import AboutAwards from '../Pages/AwardsNewsRoom/AboutAwards';
import CreateInterview from "../Pages/Interviewer/CreateInterview";
import Interview from "../Pages/Interviewer/Interview";
import CreateDocumentCategory from "../Pages/DocumentCategory/CreateDocumentCategory";
import DocumentCategory from "../Pages/DocumentCategory/DocumentCategory";
import CreateDocument from "../Pages/Document/CreateDocument";
import Document from "../Pages/Document/Document";
import CreateCSR from "../Pages/CSR/CreateCSR";
import CSR from '../Pages/CSR/CSR';
import WhoWeAre from '../Pages/Abouts/WhoWeAre/WhoWeAre';
import CoreValue from "../Pages/CoreValue/CoreValue";
import CreateCoreValue from '../Pages/CoreValue/CreateCoreValue';
import ClientAdmire from '../Pages/ClientAdmire/ClientAdmire';
import CreateClientAdmire from '../Pages/ClientAdmire/CreateClientAdmire';
import CreateServiceTabs from "../Pages/ServiceCategoryTab/CreateServiceTabs";
import HomeBanner from '../Pages/HomeBaneer/HomeBanner';
import CreateHomeBanner from '../Pages/HomeBaneer/CreateHomeBanner';
import CreateHomeOther from "../Pages/HomeBannerRates/CreateHomeOther";
import HomeOther from '../Pages/HomeBannerRates/HomeOther';
import ServiceTabs from "../Pages/ServiceCategoryTab/ServiceTabs";
import CreateTabsSubCategory from "../Pages/TabsSubCategory/CreateTabsSubCategory";
import TabsSubCategory from '../Pages/TabsSubCategory/TabsSubCategory';
import SubscribersList from '../Pages/Subscribers/SubscribersList';
import Testing from "../Pages/Testing/Testing";
import GalleryList from "../Pages/InmorphianGallery/GalleryList";
import CreateGallery from "../Pages/InmorphianGallery/CreateGallery";
import InmorphianList from "../Pages/Inmorphian/InmorphianList";
import CreateInmorphian from "../Pages/Inmorphian/CreateInmorphian";
import InmorphianContentList from "../Pages/InmorphianContent/InmorphianContentList";
import CreateInmorphianContent from "../Pages/InmorphianContent/CreateInmorphianContent";
import CreateInsightNewsletter from "../Pages/InsightsNewsletter/CreateInsightNewsletter";
import InsightNewsletterList from "../Pages/InsightsNewsletter/InsightNewsletterList";

const Routes = () => {
    const history = useHistory();
    const [token, setToken] = useState('');


    const RouteVerify = ({ children, component, ...rest }) => {
        let Component = component;
        let isAuth = getCookies('_inmp_admin_token') ? getCookies('_inmp_admin_token') : false;
        return (
            <Route
                {...rest}
                render={(props) =>
                    isAuth ? <Redirect to="/dashboard" /> : <Component {...props} />
                }
            />
        );
    }

    const RouteAuth = ({ children, component, ...rest }) => {
        let Component = component;
        let isAuth = getCookies('_inmp_admin_token') ? getCookies('_inmp_admin_token') : false;
        if (isAuth) {
            return (
                <Route
                    {...rest}
                    render={(props) =>
                        <Component {...props} />
                    }
                />
            );
        } else {
            return (
                <Route
                    {...rest}
                    render={(props) =>
                        <Redirect to="/" />
                    }
                />
            );
        }
    }
    useEffect(() => {
        if (getCookies('_inmp_admin_token')) {
            setToken(getCookies('_inmp_admin_token'));
            window.scroll(0, 0);
        }
    }, []);
    return (
        <Switch>
            <RouteVerify path='/' exact component={Login} />
            <Route path='/privacy-policy' exact component={PrivacyPage} />
            <Route path='/testing' exact component={Testing} />
            <div className="nk-body bg-lighter npc-general has-sidebar ">
                <div className="nk-app-root">
                    <div className="nk-main">
                        <Sidebar />
                        {
                            setAuthorizationToken(token)
                        }
                        <div className="nk-wrap">
                            <Topbar />
                            <RouteAuth path='/dashboard' exact component={Dashboard} />
                            <RouteAuth path='/home-banners' exact component={HomeBanner} />
                            <RouteAuth path='/create-home-banner' exact component={CreateHomeBanner} />
                            <RouteAuth path='/edit-home-banner' exact component={CreateHomeBanner} />
                            <RouteAuth path='/home-banner-other-description-list' exact component={HomeOther} />
                            <RouteAuth path='/home-banner-other-description' exact component={CreateHomeOther} />
                            <RouteAuth path='/edit-home-banner-other-description' exact component={CreateHomeOther} />
                            <RouteAuth path='/leader' exact component={Leader} />
                            <RouteAuth path='/add-leader' exact component={CreateLeader} />
                            <RouteAuth path='/edit-leader' exact component={CreateLeader} />
                            <RouteAuth path='/about-colors' exact component={AboutColor} />
                            <RouteAuth path='/add-inmorphis-color' exact component={AddAboutColor} />
                            <RouteAuth path='/edit-inmorphis-color' exact component={AddAboutColor} />
                            <RouteAuth path='/awards' exact component={AboutAwards} />
                            <RouteAuth path='/add-awards' exact component={AddAboutAwards} />
                            <RouteAuth path='/edit-awards' exact component={AddAboutAwards} />
                            <RouteAuth path='/interview' exact component={Interview} />
                            <RouteAuth path='/create-interview' exact component={CreateInterview} />
                            <RouteAuth path='/edit-interview' exact component={CreateInterview} />
                            <RouteAuth path='/document-category' exact component={DocumentCategory} />
                            <RouteAuth path='/create-document-category' exact component={CreateDocumentCategory} />
                            <RouteAuth path='/edit-document-category' exact component={CreateDocumentCategory} />
                            <RouteAuth path='/documents' exact component={Document} />
                            <RouteAuth path='/create-document' exact component={CreateDocument} />
                            <RouteAuth path='/edit-document' exact component={CreateDocument} />
                            <RouteAuth path='/happy-customer' exact component={HappyCustomer} />
                            <RouteAuth path='/create-happy-customer' exact component={CreateHappyCustomer} />
                            <RouteAuth path='/edit-happy-customer' exact component={CreateHappyCustomer} />
                            <RouteAuth path='/customer-review' exact component={CustomerReview} />
                            <RouteAuth path='/create-customer-review' exact component={CreateCustomerReview} />
                            <RouteAuth path='/edit-customer-review' exact component={CreateCustomerReview} />
                            <RouteAuth path='/innovation' exact component={Innovation} />
                            <RouteAuth path='/create-innovation' exact component={InnovationDetails} />
                            <RouteAuth path='/edit-innovation' exact component={InnovationDetails} />
                            <RouteAuth path='/industries' exact component={Industries} />
                            <RouteAuth path='/create-industries' exact component={CreateIndustries} />
                            <RouteAuth path='/edit-industries' exact component={CreateIndustries} />
                            <RouteAuth path='/blogs' exact component={Blog} />
                            <RouteAuth path='/create-blogs' exact component={CreateBlogs} />
                            <RouteAuth path='/edit-blogs' exact component={CreateBlogs} />
                            <RouteAuth path='/services' exact component={Services} />
                            <RouteAuth path='/create-services' exact component={CreateServicePage} />
                            <RouteAuth path='/edit-services' exact component={CreateServicePage} />
                            <RouteAuth path='/case-study' exact component={CaseStudyList} />
                            <RouteAuth path='/edit-case-study' exact component={CreateCaseStudy} />
                            <RouteAuth path='/create-case-study' exact component={CreateCaseStudy} />
                            <RouteAuth path='/webinar' exact component={WebinarList} />
                            <RouteAuth path='/edit-webinar' exact component={CreateWebinar} />
                            <RouteAuth path='/create-webinar' exact component={CreateWebinar} />
                            <RouteAuth path='/register-user' exact component={RegisterUserWebinar} />
                            <RouteAuth path='/whitepaper' exact component={Whitepaper} />
                            <RouteAuth path='/edit-whitepaper' exact component={CreateWhitePaper} />
                            <RouteAuth path='/create-whitepaper' exact component={CreateWhitePaper} />
                            <RouteAuth path='/account-setting' exact component={AdminProfile} />
                            <RouteAuth path='/who-we-are' exact component={WhoWeAre} />
                            <RouteAuth path='/csr' exact component={CSR} />
                            <RouteAuth path='/create-csr' exact component={CreateCSR} />
                            <RouteAuth path='/edit-csr' exact component={CreateCSR} />
                            <RouteAuth path='/core-value' exact component={CoreValue} />
                            <RouteAuth path='/create-core-value' exact component={CreateCoreValue} />
                            <RouteAuth path='/edit-core-value' exact component={CreateCoreValue} />
                            <RouteAuth path='/client-admire' exact component={ClientAdmire} />
                            <RouteAuth path='/create-client-admire' exact component={CreateClientAdmire} />
                            <RouteAuth path='/edit-client-admire' exact component={CreateClientAdmire} />
                            <RouteAuth path='/service-tabs' exact component={ServiceTabs} />
                            <RouteAuth path='/edit-service-tabs' exact component={CreateServiceTabs} />
                            <RouteAuth path='/create-service-tabs' exact component={CreateServiceTabs} />
                            <RouteAuth path='/edit-service-tabs-subcategory' exact component={CreateTabsSubCategory} />
                            <RouteAuth path='/create-service-tabs-subcategory' exact component={CreateTabsSubCategory} />
                            <RouteAuth path='/service-tabs-subcategory-list' exact component={TabsSubCategory} />
                            <RouteAuth path='/subscribers' exact component={SubscribersList} />
                            <RouteAuth path='/gallery-list' exact component={GalleryList} />
                            <RouteAuth path='/create-gallery' exact component={CreateGallery} />
                            <RouteAuth path='/edit-gallery' exact component={CreateGallery} />

                            <RouteAuth path='/inmorphian' exact component={InmorphianList} />
                            <RouteAuth path='/create-inmorphian' exact component={CreateInmorphian} />
                            <RouteAuth path='/edit-inmorphian' exact component={CreateInmorphian} />

                            <RouteAuth path='/inmorphian-content' exact component={InmorphianContentList} />
                            <RouteAuth path='/create-inmorphian-content' exact component={CreateInmorphianContent} />
                            <RouteAuth path='/edit-inmorphian-content' exact component={CreateInmorphianContent} />

                            <RouteAuth path='/create-insight-newsletter' exact component={CreateInsightNewsletter} />
                            <RouteAuth path='/edit-insight-newsletter' exact component={CreateInsightNewsletter} />
                            <RouteAuth path='/insight-newsletter' exact component={InsightNewsletterList} />
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
            <Route component={NotFound} />
        </Switch>

    );
}
export default Routes;
import React, { useEffect, useState, useRef } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useHistory } from 'react-router-dom';
import { getSingleAPI, postAPI, putAPI } from './Action';
import { convertDateFomrat, slugify } from '../../Utils/Logic';
import { BASE_IMG_URL2 } from '../../Utils/Service';
import { imageUpload } from '../../Utils/upload';
import { TiDelete } from 'react-icons/ti';
import RenderLoader from '../../PanelComponent/RenderLoader';
import { MdOutlineAdd } from 'react-icons/md';
import ServicesSlugList from '../../ExternalComponent/ServicesSlugList';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const CreateInsightNewsletter = (props) => {
    const query = useQuery();
    const history = useHistory();
    const [loader, setLoader] = useState(false)
    const initArrayData = ({
        heading: "",
        isLeft: true,
        image: "",
        contents: ""
    })

    const [title, setTitle] = useState('')
    const [shortDescription, setShortDescription] = useState("");
    const [slug, setSlug] = useState("");
    const [date, setDate] = useState('');
    const [service, setService] = useState("");
    const [downloadLink, setDownloadLink] = useState("");

    const [featuredImage, setFeaturedImage] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [editID, setEditID] = useState('');
    const [editCase, setEditCase] = useState(false);

    const [dataContents, setDataContents] = useState([])

    const imageRef1 = useRef();
    const downloadLinkRef = useRef();



    const handleCreateWebinar = async (e) => {
        e.preventDefault();
        // setLoader(true)
        if (title != '' && slug != '' && date != '' && featuredImage != '' && downloadLink != '') {
            const data = {
                title,
                shortDescription,
                slug,
                date,
                service,
                downloadLink,
                featuredImage,
                metaTitle,
                metaDescription,
                rightSideContent: dataContents?.filter((n) => [false, 'false']?.includes(n?.isLeft)),
                leftSideContent: dataContents?.filter((n) => [true, 'true']?.includes(n?.isLeft))
            }
            if (editCase) {
                await putAPI(editID, data).then(response => {
                    if (response.success) {
                        setTitle('');
                        setShortDescription('');
                        setSlug('');
                        setDate('');
                        setService('');
                        setDownloadLink('');
                        setFeaturedImage('');
                        setMetaTitle('');
                        setMetaDescription('');
                        setEditID('');
                        setEditCase(false);
                        if (imageRef1?.current?.value) {
                            imageRef1.current.value = "";
                        }
                        if (downloadLinkRef?.current?.value) {
                            downloadLinkRef.current.value = "";
                        }
                        toast.success(response.message);
                        setLoader(false)
                        history.push('/insight-newsletter');
                        setLoader(false)
                    } else {
                        toast.error(response.message)
                        setLoader(false)
                    }
                });
            } else {
                await postAPI(data).then(response => {
                    if (response.success) {
                        setTitle('');
                        setShortDescription('');
                        setSlug('');
                        setDate('');
                        setService('');
                        setDownloadLink('');
                        setFeaturedImage('');
                        setMetaTitle('');
                        setMetaDescription('');
                        setEditID('');
                        setEditCase(false);
                        setDataContents([{
                            heading: "",
                            isLeft: true,
                            image: "",
                            contents: ""
                        }])
                        history.push('/insight-newsletter');
                        if (imageRef1?.current?.value) {
                            imageRef1.current.value = "";
                        }
                        if (downloadLinkRef?.current?.value) {
                            downloadLinkRef.current.value = "";
                        }
                        toast.success(response.message);
                        setLoader(false)
                    } else {
                        toast.error(response.message)
                        setLoader(false)
                    }
                });
            }
        } else {
            toast.error('Please fill all required fields!')
            setLoader(false)
        }
    }
    useEffect(async () => {
        if (window.location.pathname === '/edit-insight-newsletter' && query.get('id') != '') {
            setLoader(true)
            setDataContents([]);
            await getSingleAPI(query.get('id')).then(response => {
                if (response.success) {
                    const data = response?.data;
                    if (data) {
                        setEditCase(true)
                        setEditID(data?._id ? data?._id : '')
                        setTitle(data?.title ? data?.title : "")
                        setShortDescription(data?.shortDescription ? data?.shortDescription : "")
                        setSlug(data?.slug ? data?.slug : "")
                        setDate(data?.date ? data?.date : "")
                        setService(data?.service ? data?.service : "")
                        setDownloadLink(data?.downloadLink ? data?.downloadLink : "")
                        setFeaturedImage(data?.featuredImage ? data?.featuredImage : "")
                        setMetaTitle(data?.metaTitle ? data?.metaTitle : "")
                        setMetaDescription(data?.metaDescription ? data?.metaDescription : "")
                        const rightSideContent = data?.rightSideContent?.length ? data?.rightSideContent : [];
                        const leftSideContent = data?.leftSideContent?.length ? data?.leftSideContent : [];
                        setDataContents(rightSideContent?.concat(leftSideContent))
                        setLoader(false)
                    } else {
                        history.push('/404');
                    }
                } else {
                    history.push('/404');
                }
            });
        }
    }, [])
    return (
        <>
            <div className="nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-lg">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">{editCase ? 'Edit Newsletter' : 'Create Newsletter'}</h4>
                                                </div>
                                            </div>
                                            {
                                                loader ?
                                                    <RenderLoader trans={true} />
                                                    : null
                                            }
                                            <div className="nk-block">
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Normal Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Title*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={title} onChange={(e) => { setTitle(e.target.value); setSlug(slugify(e.target.value)) }} name='title' placeholder='Title' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Short Description</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={shortDescription} onChange={(e) => setShortDescription(e.target.value)} name='shortDescription' placeholder='Short Description' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Slug*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" name='slug' value={slug} onChange={(e) => setSlug(e.target.value)} placeholder='Slug' className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Date*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="date" name='date' value={date ? convertDateFomrat(new Date(date)) : ''} onChange={(e) => { setDate(new Date(e.target.value).toISOString()) }} placeholder='Date' className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <ServicesSlugList label="Select Service" value={service} onChange={(e) => setService(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Upload Newsletter PDF*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>


                                                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    {
                                                                        downloadLink ?
                                                                            <a href={BASE_IMG_URL2 + downloadLink} className='img-fluid' style={{ margin: '15x', padding: '15px' }} target='_blank' >View PDF</a>
                                                                            : null
                                                                    }
                                                                    {
                                                                        downloadLink ?
                                                                            <button onClick={(e) => setDownloadLink('')} className='btn btn-primary' >Delete</button>
                                                                            : null
                                                                    }
                                                                </div>

                                                                <br />
                                                                <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                    <input type="file" name='downloadLink' ref={downloadLinkRef} accept=".pdf" className="form-control form-control-lg" style={{ width: '100%' }}
                                                                        onChange={async (event) => {
                                                                            var file = event.target.files[0];
                                                                            var formData = new FormData();
                                                                            formData.append('path', 'insight-newsletter');
                                                                            formData.append('file', file, file.name);
                                                                            setLoader(true)
                                                                            await imageUpload(formData).then(response => {
                                                                                if (response?.success) {
                                                                                    setDownloadLink(response?.aws?.key)
                                                                                    setLoader(false)
                                                                                    //toast.success(response.message)
                                                                                } else {
                                                                                    toast.error(response.message)
                                                                                    setLoader(false)
                                                                                }
                                                                            })
                                                                        }}
                                                                    />
                                                                </div>

                                                            </div>




                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Feature Image*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        {
                                                                            featuredImage ?
                                                                                <img src={BASE_IMG_URL2 + featuredImage} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                                : null
                                                                        }

                                                                        <input type="file" name='featuredImage' ref={imageRef1} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'insight-newsletter');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setFeaturedImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        {
                                                                            featuredImage ?
                                                                                <img src={BASE_IMG_URL2 + featuredImage} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                                : null
                                                                        }

                                                                        <input type="file" name='featuredImage' ref={imageRef1} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'insight-newsletter');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setFeaturedImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Meta Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Meta Title</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" name='metaTitle' value={metaTitle} onChange={(e) => setMetaTitle(e.target.value)} placeholder='Meta Title' className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Meta Description</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" name='metaDescription' value={metaDescription} onChange={(e) => setMetaDescription(e.target.value)} placeholder='Meta Description' className="form-control form-control-lg" style={{ width: '100%' }}>

                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="nk-data data-list">
                                                    <div className="data-head" style={{ padding: '15px', background: '#ececec', border: 'solid #ececec 1px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <h6 className="overline-title" style={{ margin: '0px' }}>Newsletter Content</h6>

                                                    </div>
                                                    {
                                                        dataContents?.length ?
                                                            dataContents?.map((n, index) =>

                                                                <div className="nk-data data-list" style={{ padding: '0px 0px', border: 'solid #f6f6f6 1px', background: '#f6f6f6' }} key={index}>
                                                                    <div className="data-item" style={{ padding: '5px 15px', background: '#f9f9f9', border: 'solid #f9f9f9 1px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                        <span className="data-label" style={{ fontSize: '16px', width: 'auto', fontWeight: '550', color: '#6576ff' }}>{index + 1}: Pointer</span>
                                                                        <span>
                                                                            <button onClick={() => {
                                                                                setDataContents(dataContents?.filter((n, ind) => ind != index))
                                                                            }} className='btn btn-lg btn-primary' style={{ width: '35px', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0px' }}>
                                                                                <TiDelete style={{ color: '#fff', width: '35px', height: '35px' }} />
                                                                            </button>
                                                                        </span>
                                                                    </div>
                                                                    <div className="data-item">
                                                                        <div className="data-col">
                                                                            <span className="data-label">Heading</span>
                                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                                <input type="text" name='heading' value={n?.heading ? n?.heading : ""} onChange={(e) =>
                                                                                    setDataContents(dataContents?.map((n, ind) => ind === index ? { ...n, heading: (e.target.value) } : n))
                                                                                } placeholder='Heading' className="form-control form-control-lg" style={{ width: '100%' }} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="data-item">
                                                                        <div className="data-col">
                                                                            <span className="data-label">Is Left View</span>
                                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                                <select value={n?.isLeft} onChange={(e) =>
                                                                                    setDataContents(dataContents?.map((n, ind) => ind === index ? { ...n, isLeft: (e.target.value) } : n))
                                                                                } name='isleft' placeholder='Is left View' required className="form-control form-control-lg" style={{ width: '100%' }}>
                                                                                    <option value={true}>Yes</option>
                                                                                    <option value={false}>No</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="data-item">
                                                                        <div className="data-col">
                                                                            <span className="data-label">Image</span>
                                                                            <div className="form-control-wrap" style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                                                {n?.image ? <img src={BASE_IMG_URL2 + n?.image} className='img-fluid' style={{ margin: '15x', padding: '15px' }} /> : null}
                                                                                {
                                                                                    n?.image ?
                                                                                        <button onClick={() => {
                                                                                            setDataContents(dataContents?.map((n, ind) => ind === index ? { ...n, image: '' } : n))
                                                                                        }} className='btn btn-lg btn-primary' style={{ width: 'auto', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0px 15px' }}>
                                                                                            Remove
                                                                                        </button>

                                                                                        : null
                                                                                }
                                                                                <br />
                                                                                <input type="file" name='image' ref={imageRef1} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                                    onChange={async (event) => {
                                                                                        var file = event.target.files[0];
                                                                                        var formData = new FormData();
                                                                                        formData.append('path', `insight-newsletter/${slug ? slug : `insight-newsletter${index}`}`);
                                                                                        formData.append('file', file, file.name);
                                                                                        setLoader(true)
                                                                                        await imageUpload(formData).then(response => {
                                                                                            if (response?.success) {
                                                                                                setDataContents(dataContents?.map((n, ind) => ind === index ? { ...n, image: (response?.aws?.key) } : n))
                                                                                                setLoader(false)
                                                                                            } else {
                                                                                                toast.error(response.message)
                                                                                                setLoader(false)
                                                                                            }
                                                                                        })
                                                                                    }}
                                                                                />



                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="data-item">
                                                                        <div className="data-col">
                                                                            <span className="data-label">Content</span>
                                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                                <CKEditor
                                                                                    editor={ClassicEditor}
                                                                                    data={n?.contents ? n?.contents : ''}
                                                                                    config={{
                                                                                        toolbar: ['bold', 'italic', 'blockQuote', 'link', 'bulletedList']
                                                                                    }}
                                                                                    onReady={editor => {
                                                                                        // console.log('editor ready!')
                                                                                    }}
                                                                                    onChange={(event, editor) => {
                                                                                        setDataContents(dataContents?.map((n, ind) => ind === index ? { ...n, contents: (editor.getData()) } : n))
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            )
                                                            : null
                                                    }
                                                    <div className="data-head" style={{ padding: '15px', background: '#ececec', border: 'solid #ececec 1px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px 0px' }}>
                                                        <span>
                                                            <button onClick={() => {
                                                                setDataContents([...dataContents, initArrayData]);
                                                            }} className='btn btn-lg btn-primary' style={{ width: 'auto', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0px 15px' }}>
                                                                <MdOutlineAdd style={{ color: '#fff', width: '35px', height: '35px' }} /> Create New Pointer
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <div className="form-control-wrap" style={{ width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                                                                <button className="btn btn-lg btn-primary btn-block" onClick={(e) => handleCreateWebinar(e)}>{editCase ? 'Save' : 'Create'}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default CreateInsightNewsletter;
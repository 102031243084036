import React, { useEffect, useState , useRef} from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useHistory } from 'react-router-dom';
import { BASE_IMG_URL2 } from '../../Utils/Service';
import { imageUpload } from '../../Utils/upload';
import { postWhitePaperAPI, putWhitePaperAPI, getSingleWhitePaperAPI } from './Action';
import RenderLoader from '../../PanelComponent/RenderLoader';
import ServicesSlugList from '../../ExternalComponent/ServicesSlugList';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const CreateWhitePaper = (props) => {
    const query = useQuery();
    const history = useHistory();
    const [editCase, setEditCase] = useState(false);
    const [title, setTitle] = useState('');
    const [shortDescription, setshortDescription] = useState('');
    const [image, setImage] = useState('');
    const [fileUpload, setFileUpload] = useState('');
    const [service, setService] = useState("");
    const [loader, setLoader] = useState(false)

    const imageRef1 = useRef();
    const imageRef2 = useRef();


    const handleCreateWhitePaper = async (e) => {
        e.preventDefault();
        setLoader(true)
        if (title != '' && shortDescription != '' && image != '' && fileUpload != '') {
            const data = {
                "title": title,
                "image": image,
                "fileUrl": fileUpload,
                "shortDescription": shortDescription,
                "service":service
            }
            if (editCase) {
                await putWhitePaperAPI(query.get('id'), data).then(response => {
                    if (response.success) {
                        toast.success(response.message);
                        setEditCase(false)
                        setTitle('');
                        setService("")
                        setshortDescription('');
                        setImage('');
                        setFileUpload('');
                        if(imageRef1.current)
                        {
                            imageRef1.current.value = "";
                        }
                        if(imageRef2.current)
                        {
                            imageRef2.current.value = "";
                        }

                        history.push('/whitepaper');
                        setLoader(false)
                    } else {
                        toast.error(response.message)
                        setLoader(false)
                    }
                });
            } else {
                await postWhitePaperAPI(data).then(response => {
                    if (response.success) {
                        toast.success(response.message);
                        setTitle('');
                        setshortDescription('');
                        setImage('');
                        setFileUpload('');
                        setService("")
                        imageRef1.current.value = "";
                        imageRef2.current.value = "";
                        setLoader(false)
                    } else {
                        toast.error(response.message)
                        setLoader(false)
                    }
                });
            }
        } else {
            toast.error('Please fill all required fields!')
            setLoader(false)
        }
    }
    useEffect(async () => {
        if (window.location.pathname === '/edit-whitepaper' && query.get('id') != '') {
            setLoader(true)
            await getSingleWhitePaperAPI(query.get('id')).then(response => {
                if (response.success) {
                    const data = response.data;
                    if (data) {
                        setTitle(data?.title ? data?.title : '');
                        setshortDescription(data?.shortDescription ? data?.shortDescription : '');
                        setImage(data?.image ? data?.image : '');
                        setFileUpload(data?.fileUrl ? data?.fileUrl : '');
                        setService(data?.service?data?.service:"")
                        setEditCase(true);
                        setLoader(false)
                    } else {
                        history.push('/404')
                    }
                } else {
                 history.push('/404')
                }
            });
        }
    }, [])
    return (
        <>
            <div className="nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-lg">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">{editCase ? 'Edit White Paper' : 'Create White Paper'}</h4>
                                                </div>
                                            </div>
                                            {
                                                loader?
                                                   <RenderLoader trans={true}/>
                                                :null
                                            }
                                            <div className="nk-block">
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Normal Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Title*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} name='mainTitle' placeholder='Title' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Short Description*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" name='shortDescription' value={shortDescription} onChange={(e) => setshortDescription(e.target.value)} placeholder='Short Description' required className="form-control form-control-lg" style={{ width: '100%' }}>

                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <ServicesSlugList label="Select Service" value={service} onChange={(e)=>setService(e)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Upload</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Image*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <img src={BASE_IMG_URL2 + image} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                        <input type="file" name='image' ref={imageRef1} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'whitePaper');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" name='image' ref={imageRef1} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'whitePaper');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">PDF/Doc Upload*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <a href={BASE_IMG_URL2+fileUpload} target='_blank' >View File</a>
                                                                        {/* <img src={BASE_IMG_URL2+ fileUpload} className='img-fluid' style={{ margin: '15x', padding: '15px' }} /> */}
                                                                        <input type="file" name='fileUpload' ref={imageRef2} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'whitePaper');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setFileUpload(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" name='fileUpload' ref={imageRef2} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'whitePaper');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setFileUpload(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <div className="form-control-wrap" style={{ width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                                                                <button className="btn btn-lg btn-primary btn-block" onClick={(e) => handleCreateWhitePaper(e)}>{editCase ? 'Save' : 'Create'}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default CreateWhitePaper;
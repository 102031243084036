import React, { useEffect, useState, useRef } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useHistory } from 'react-router-dom';
import { getSingleAPI, postAPI, putAPI } from './Action';
import { Editor } from '@tinymce/tinymce-react';
import { imageUpload } from '../../Utils/upload';
import { BASE_IMG_URL2 } from '../../Utils/Service';
import { slugify } from '../../Utils/Logic';
import RenderLoader from '../../PanelComponent/RenderLoader';
import ServicesSlugList from '../../ExternalComponent/ServicesSlugList';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const CreateBlogs = (props) => {
    const query = useQuery();
    const editorRef = useRef(null);
    const history = useHistory();
    const [editCase, setEditCase] = useState(false);
    const [publishDate, setPublishDate] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [slug, setSlug] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [bannerImage, setBannerImage] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [blogDetails, setBlogsDetails] = useState('');
    const [loader, setLoader] = useState(false)
    const [status, setStatus] = useState('pending');
    const [ownerBlog, setOwnerBlog] = useState(false);
    const [service, setService] = useState("");
    const imageRef1 = useRef();



 
    const setContentInsideOfEditor = (setValue) => {
        if (editorRef.current != null) {
            editorRef.current.setContent(setValue);
        }
    }
    const handleCreateWebinar = async (e) => {
        e.preventDefault();
        setLoader(true)
        if (publishDate != '' && title != '' && description != '' && slug != '' && metaTitle != '' && metaDescription != '' && bannerImage != '' && blogDetails != '') {
            var data = {
                "title": title,
                "metaTitle": metaTitle,
                "shortDescription": description,
                "bannerImage": bannerImage,
                "metaDescription": metaDescription,
                "slug": slug,
                "status": status,
                "blogDetails": blogDetails,
                "date": publishDate,
                "service":service
            }
            
            if (subTitle) {
                data = Object.assign(data, { "subTitle": subTitle })
            }
            if (editCase) {
                data = Object.assign(data, { "subTitle": subTitle })
                putAPI(query.get('id'), data).then(response => {
                    if (response.success) {
                        toast.success(response.message)
                        setEditCase(false);
                        setPublishDate('');
                        setTitle('');
                        setDescription('');
                        setSlug('');
                        setSubTitle('')
                        setMetaTitle('');
                        setMetaDescription('');
                        setBannerImage('');
                        setStatus('pending')
                        setBlogsDetails('');
                        setContentInsideOfEditor('');
                        setService("")
                        if (imageRef1.current) {
                            imageRef1.current.value = "";
                        }
                        history.push('/blogs')
                        setLoader(false)
                    } else {
                        toast.error(response.message)
                        setLoader(false)
                    }
                });
            } else {
                postAPI(data).then(response => {
                    if (response.success) {
                        toast.success(response.message);
                        setEditCase(false);
                        setPublishDate('');
                        setTitle('');
                        setSubTitle('');
                        setDescription('');
                        setSlug('');
                        setMetaTitle('');
                        setMetaDescription('');
                        setBannerImage('');
                        setStatus('pending')
                        setBlogsDetails('');
                        setService("")
                        setContentInsideOfEditor('');
                        imageRef1.current.value = "";
                        setLoader(false)
                    } else {
                        toast.error(response.message)
                        setLoader(false)
                    }
                });
            }
        } else {
            toast.error('Please fill all required field!')
            setLoader(false)
        }
    }
    useEffect(async () => {
        if (window.location.pathname === '/edit-blogs' && query.get('id') != '') {
            setLoader(true)
            await getSingleAPI(query.get('id')).then(response => {
                if (response.success) {
                    const data = response?.data;
                    setOwnerBlog(response?.ownerBlog ? response?.ownerBlog : false)
                    if (data) {
                        setEditCase(true);
                        setPublishDate(data?.date ? data?.date : '');
                        setTitle(data?.title ? data?.title : '');
                        setSubTitle(data?.subTitle ? data?.subTitle : '')
                        setDescription(data?.shortDescription ? data?.shortDescription : '');
                        setSlug(data?.slug ? data?.slug : '');
                        setMetaTitle(data?.metaTitle ? data?.metaTitle : '');
                        setMetaDescription(data?.metaDescription ? data?.metaDescription : '');
                        setBannerImage(data?.bannerImage ? data?.bannerImage : '');
                        setStatus(data?.status ? data?.status : '')
                        setBlogsDetails(data?.blogDetails ? data?.blogDetails : '');
                        setContentInsideOfEditor(data?.blogDetails ? data?.blogDetails : '');
                        setService(data?.service?data?.service:"")
                        setLoader(false)
                    } else {
                        history.push('/404');
                    }
                } else {
                    history.push('/404');
                }
            });
        }
    }, [])
    return (
        <>
            <div className="nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-lg">
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">{editCase ? 'Edit Blog' : 'Create Blog'}</h4>
                                                </div>
                                            </div>
                                            {
                                                loader ?
                                                    <RenderLoader trans={true} />
                                                    : null
                                            }
                                            <div className="nk-block">
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Normal Details <span style={{ float: 'right', color: '#6576ff' }}>{ownerBlog ? 'Posted by ' + ownerBlog?.firstName + ' ' + (ownerBlog?.lastName ? ownerBlog?.lastName : '') : 'Posted by Admin'}</span></h6>
                                                    </div>

                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Publish Date*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={publishDate} onChange={(e) => setPublishDate(e.target.value)} name='publishDate' placeholder='Publish Date' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Title*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={title} onChange={(e) => { setTitle(e.target.value); setSlug(slugify(e.target.value)) }} name='title' placeholder='Tilte' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Sub Title</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={subTitle} onChange={(e) => { setSubTitle(e.target.value); }} name='subtitle' placeholder='Sub Tilte' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Description*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" name='description' value={description} onChange={(e) => setDescription(e.target.value)} placeholder='Description' required className="form-control form-control-lg" style={{ width: '100%' }}>

                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Slug*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" value={slug} onChange={(e) => setSlug(e.target.value)} name='slug' placeholder='Slug' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Status</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <select type="text" value={status} onChange={(e) => setStatus(e.target.value)} name='status' placeholder='Status' required className="form-control form-control-lg" style={{ width: '100%' }}>
                                                                    <option value=''>Select</option>
                                                                    <option value='pending'>Pending</option>
                                                                    <option value='rejected'>Rejected</option>
                                                                    <option value='submitted'>Submitted</option>
                                                                    <option value='approved'>Approved</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <ServicesSlugList label="Select Service" value={service} onChange={(e)=>setService(e)}/>
                                                        </div>
                                                    </div>
                                                   
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Meta Details</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Meta Title*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <input type="text" name='metaTitle' value={metaTitle} onChange={(e) => setMetaTitle(e.target.value)} placeholder='Meta Title' required className="form-control form-control-lg" style={{ width: '100%' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Meta Description*</span>
                                                            <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                <textarea type="text" name='metaDescription' value={metaDescription} onChange={(e) => setMetaDescription(e.target.value)} placeholder='Meta Description' required className="form-control form-control-lg" style={{ width: '100%' }}>

                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">Upload Image/Videos</h6>
                                                    </div>
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <span className="data-label">Banner Image*</span>
                                                            {
                                                                editCase ?
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <img src={BASE_IMG_URL2 + bannerImage} className='img-fluid' style={{ margin: '15x', padding: '15px' }} />
                                                                        <input type="file" name='bannerImage' ref={imageRef1} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'blogs');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setBannerImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="form-control-wrap" style={{ width: '100%' }}>
                                                                        <input type="file" name='bannerImage' ref={imageRef1} className="form-control form-control-lg" style={{ width: '100%' }}
                                                                            onChange={async (event) => {
                                                                                var file = event.target.files[0];
                                                                                var formData = new FormData();
                                                                                formData.append('path', 'blogs');
                                                                                formData.append('file', file, file.name);
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response?.success) {
                                                                                        setBannerImage(response?.aws?.key)
                                                                                        setLoader(false)
                                                                                        //toast.success(response.message)
                                                                                    } else {
                                                                                        toast.error(response.message)
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-head">
                                                        <h6 className="overline-title">More Details</h6>
                                                    </div>
                                                    <div className="data-item" style={{ width: '100%' }}>
                                                        <div className="data-col" style={{ display: 'block', width: '100%' }}>
                                                            <span className="data-label" style={{ width: '100%' }}>Blogs Details</span>
                                                            <div className="form-control-wrap" style={{ width: '100%', height: 'auto', minHeight: '300px', marginTop: '15px', border: 'solid #cecece 1px', minWidth: '100%' }}>



                                                      





                                                                <Editor
                                                                    className='editorStyle'
                                                                    outputFormat='text'
                                                                    onInit={(evt, editor) => editorRef.current = editor}
                                                                    onEditorChange={(evt, editor) => {
                                                                        setBlogsDetails(editorRef.current.getContent());
                                                                    }}
                                                                    value={blogDetails}
                                                                    apiKey={process.env.REACT_APP_TINMCY_KEY}
                                                                    init={
                                                                        {
                                                                            menubar: false,
                                                                            branding: false,
                                                                            draggable_modal: false,
                                                                            statusbar: false,
                                                                            toolbar_sticky: true,
                                                                            paste_as_text: false,
                                                                            statusbar: true,
                                                                            image_title: true,
                                                                            insertdatetime_element: true,
                                                                            nonbreaking_force_tab: false,
                                                                            automatic_uploads: true,
                                                                            visualchars_default_state: true,
                                                                            width: '100%',
                                                                            height: '500px',
                                                                            skin: "naked",
                                                                            icons: "thin",
                                                                            selector: "textarea",
                                                                            resize: 'both',
                                                                            theme: 'silver',
                                                                            toolbar_mode: 'wrap',
                                                                            toolbar_location: 'top',
                                                                            placeholder: 'Write Blog...',
                                                                            file_browser_callback_types: 'image media',
                                                                            content_css: ["https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"],
                                                                            content_style: "*{font-family:Poppins}",
                                                                            plugins: [
                                                                                'visualchars searchreplace insertdatetime nonbreaking table code advlist anchor autolink image imagetools lists link media preview searchreplace wordcount emoticons hr charmap preview'
                                                                            ],
                                                                            toolbar: 'undo redo searchreplace | formatselect fontselect fontsizeselect | bold italic underline strikethrough | superscript subscript removeformat | lineheight | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent link image | code emoticons charmap hr blockquote insertdatetime nonbreaking wordcount preview visualchars table',
                                                                            fontsize_formats: '8px 10px 12px 14px 16px 18px 24px 36px 48px',
                                                                            lineheight_formats: '0.5 0.7 0.8 0.9 1 1.1 1.2 1.3 1.4 1.5 2',
                                                                            block_formats: 'Paragraph=p;Header 1=h1;Header 2=h2;Header 3=h3; Header 4=h4',
                                                                            font_formats: 'Arial Black=arial black,avant garde;Poppins=Poppins;',
                                                                       
                                                                            
                                                                            file_picker_callback: function (cb, value, meta) {
                                                                                var input = document.createElement('input');
                                                                                input.setAttribute('type', 'file');
                                                                                input.setAttribute('accept', 'image/*');
                                                                                input.onchange = function () {
                                                                                  var file = this.files[0];
                                                                                  var reader = new FileReader();
                                                                                  reader.onload = async function () {
                                                                                    var id = 'blobid' + (new Date()).getTime();
                                                                                    var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
                                                                                    var base64 = reader.result.split(',')[1];
                                                                                    var blobInfo = blobCache.create(id, file, base64);
                                                                                    blobCache.add(blobInfo);
                                                                                    const formData = new FormData();
                                                                                    formData.append('path', 'blogs');
                                                                                    formData.append('file', file, file.name);
                                                                                    setLoader(true)
                                                                                    await imageUpload(formData).then(response => {
                                                                                    if (response.success) {
                                                                                        cb(response?.aws?.location, { title: response?.aws?.key.split('/')[(response?.aws?.key.split('/').length-1)]});
                                                                                        setLoader(false)
                                                                                        //success(response?.aws?.location)
                                                                                    }else{
                                                                                        setLoader(false)
                                                                                    }
                                                                                    })
                                                                                  };
                                                                                  reader.readAsDataURL(file);
                                                                                };
                                                                                input.click();
                                                                              },
                                                                            images_upload_handler: async function (blobInfo, success, failure) {
                                                                                const formData = new FormData();
                                                                                formData.append('path', 'blogs');
                                                                                formData.append('file', blobInfo.blob(), blobInfo.filename());
                                                                                setLoader(true)
                                                                                await imageUpload(formData).then(response => {
                                                                                    if (response.success) {
                                                                                        success(response?.aws?.location)
                                                                                        setLoader(false)
                                                                                    }else{
                                                                                        setLoader(false)
                                                                                    }
                                                                                })
                                                                            }
                                                                        }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="nk-data data-list">
                                                    <div className="data-item">
                                                        <div className="data-col">
                                                            <div className="form-control-wrap" style={{ width: '100%', paddingLeft: '15px', paddingRight: '15px' }}>
                                                                <button className="btn btn-lg btn-primary btn-block" onClick={(e) => handleCreateWebinar(e)}>{editCase ? 'Save' : 'Create'}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default CreateBlogs;
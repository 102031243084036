import React, { useEffect, useState } from 'react';

const Dashboard = (props) => {


    return (
        <>
            <div className="sideBarUL nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block">
                                <div className="row g-gs">
                                    <div className="col-xxl-5">
                                        <div className="row g-gs">
                                            <div className="col-lg-12 col-xxl-12">
                                                <div className="card card-bordered" style={{ background: 'transparent', border: 'none' }}>
                                                    <div className="card-inner">
                                                        <div className="card-title-group align-start mb-2">
                                                            <img src='https://inmorphis.sgp1.cdn.digitaloceanspaces.com/website/assets/images/headerlogo.svg' className='img-fluid' style={{ width: '100%', height: '100%', padding: '10%' }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Dashboard;
